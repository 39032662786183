import React,{useState,useEffect} from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "react-slick";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavBar from '../../components/NavBar/index'
import {Typography,Button,Modal,Breadcrumbs} from '@material-ui/core'
import {
    FacebookShareCount,
    PinterestShareCount,
    VKShareCount,
    OKShareCount,
    RedditShareCount,
    TumblrShareCount,
    HatenaShareCount,
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    LinkedinShareButton,
    TwitterShareButton,
    PinterestShareButton,
    VKShareButton,
    OKShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,
    EmailShareButton,
    TumblrShareButton,
    LivejournalShareButton,
    MailruShareButton,
    ViberShareButton,
    WorkplaceShareButton,
    LineShareButton,
    WeiboShareButton,
    PocketShareButton,
    InstapaperShareButton,
    HatenaShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    PinterestIcon,
    VKIcon,
    OKIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon,
    TumblrIcon,
    MailruIcon,
    EmailIcon,
    LivejournalIcon,
    ViberIcon,
    WorkplaceIcon,
    LineIcon,
    PocketIcon,
    InstapaperIcon,
    WeiboIcon,
    HatenaIcon,
  } from "react-share";
import NumberFormat from 'react-number-format';
import {Link,useHistory} from 'react-router-dom'
import Loan from '../../components/Loan/index'
import getRealty from '../../utils/sample'

const useStyles = makeStyles({
    '@keyframes rotating' :{
        from:{
         
          transform: 'rotate(0deg)',
        },
        to:{
         
          transform: 'rotate(360deg)',
        }
      },
    bar:{
        margin:'15px'
    },
    logo:{
        width:'80px'
    },
    logo1:{
        width:'180px'
    },
    img:{
        width:'100%',
        height:'500px',
        objectFit:'cover',
        paddingTop:'10px'
    },
    img1:{
        width:'100%',
        height:'auto'
    },
    flex:{
        height:'auto',
        width:'100%',
        alignItems: 'center',
        justifyContent: 'center',
        // border: '3px solid black',

    },
    carWrap:{
        alignItems: 'center',
        justifyContent: 'center',
        display:'flex'
    },
    title:{
        marginTop:'10px'
    },
    container:{
        backgroundColor:'#FFFFFF',
        // padding:'10px',
        // margin:'20px',
        paddingTop:'20px',
        borderRadius:'10px',
        boxShadow:'0px 0px 6px 0px #000000',
        width:'100%',
        
    },
    modal:{
        position:'absolute',
        width:'100%',
    },
    floor:{
        marginLeft:'20px'
    },
    floorPlan:{
        backgroundColor:'#ede3ff',
        marginTop:'30px'
    },
    floorImg:{
        width:'100%',
        height:'auto'
    },
    wrapSample:{
        alignItems: 'center',
        justifyContent: 'center',
        display:'flex',
        flexDirection:'column',
        background:'white'
    },
    divider:{
        display:'flex',
        justifyContent:'space-between',
        alignItems: 'center',
        width:'95%',
    },
    money:{
        color:'#F23D34',
        fontWeight:'bold',
    },
    title:{
        fontWeight:'bold',

    },
    weChat:{
        width:'50px'
    },
    grid:{
        display: 'grid',
        gridTemplateColumns:'2fr 1fr',
        justifyContent: 'center',
        alignItems: 'center',
       
    },
    typ:{
        color:'black',
        margin:'0px',
        padding:'0px',
        height:'20px'
    },
    agent:{
        display:'flex',
        flexDirection:'row',
        alignItems: 'center',
    },
    divider1:{
        display:'flex',
        justifyContent:'space-between',
        alignItems: 'center',
        width:'95%',
        height:'60px'
    },
    agentContent:{
        height:'60px',
        marginLeft:'30px'
    },
    section:{
        display:'grid',
        width:'100%',
        gridTemplateColumns:'repeat(4,1fr)',
        alignItems: 'center',
        textAlign: 'center',
       
    },
    aTag:{
        textAlign:'center',
        backgroundColor:'#1189f0',
        width:'97%',
        color:'white',
        fontWeight:'bold',
        padding:'5px',
        fontSize:'18px',
    },
    basic:{
        display:'flex',
        justifyContent:'flex-start',
        alignItems: 'center',
        flexDirection:'column',
        width:'100%',
        position:'relative',
    },
    basicItem:{
        display:'grid',
        width:'100%',
        gridTemplateColumns:'repeat(2,1fr)',
        backgroundColor:'#1189f0',
        marginTop:'30px'
    },
    appoint:{
        width:'30%',
        height:'30px',
        position:'absolute',
        right:'0px',
        top:'60px'
    },
    basicItem1:{
        display:'grid',
        width:'90%',
        gridTemplateColumns:'repeat(2,1fr)',
        
    },
    hr:{
        width:'100%',
    },
    des:{
        color:'black'
    },
    section2:{
        display:'grid',
        width:'100%',
        gridTemplateColumns:'repeat(2,1fr)',
        alignItems: 'center',
        textAlign: 'center',
        position:'fixed',
        bottom:'0px'
    },
    aTag2:{
        textAlign:'center',
        backgroundColor:'#1189f0',
        width:'100%',
        color:'black',
        fontWeight:'bold',
        border:'1px solid black',
        fontSize:'20px',
        padding:'15px',
       
    },
    pana:{
        width:'50px',
        position:'absolute',
        zIndex:'100',
        top:'290px',
        right:'20px',
        borderRadius:'50%',
        backgroundColor:'rgba(0, 0, 0, 0.2)',
        animation: '$rotating 10s linear infinite',
        boxShadow: '1px 1px 5px 3px #000000',

    },
    h3:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        backgroundColor: '#1189f0',
        width:'100%',

    },
    h5:{
        color:'white',
        fontWeight:'bold',
        fontSize:'20px',

    },
    h2:{
        color:'white',
        fontWeight:'bold',
    },
    h4:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        width:'60%',

    },
})



const house = getRealty()



export default function SamplePage({handleChangeLanguage,language}) {
    const classes = useStyles()
    const history = useHistory()

    const [isMap,setIsMap] = useState(true)
    const [isLoan,setIsLoan] = useState(false)
    const [isDetail,setDetail] = useState(false)
    const [isNearBy,setNearBy] = useState(false)

    const handleMap = ()=>{
        setIsMap(true)
        setIsLoan(false)
        setDetail(false)
        setNearBy(false)
    }

    const handleLoan = ()=>{
        setIsMap(false)
        setIsLoan(true)
        setDetail(false)
        setNearBy(false)
    }

    const handleDetail = ()=>{
        setIsMap(false)
        setIsLoan(false)
        setDetail(true)
        setNearBy(false)
    }

    const handleNearBy = ()=>{
        setIsMap(false)
        setIsLoan(false)
        setDetail(false)
        setNearBy(true)
    }
    const BTN= [
        {name:'地图信息',fun:handleMap},
        {name:'抵押贷款',click:handleLoan},
        {name:'详细信息',click:handleDetail},
        {name:'附近房源',click:handleNearBy}
    ]

    const index= history.location.pathname.split('/')[2]-1;
    const item = house[index];
    const page = index+1;
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
      
      };

    const handleFloor = (i)=>{
        setFloor(i)
    }

    const handleOpen = (i)=>{
        setOpen(true)
    }

    const handleClose = ()=>{
        setOpen(false)
    }

    
    const shareUrl = window.location.href;
    const title = '线上地产';

    return (
        <div className={classes.wrapSample}>
            <div className={classes.h4}>
            <img className={classes.logo} src="https://res.cloudinary.com/dlapk94rx/image/upload/v1640126180/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/logo%E6%AD%A3%E6%96%B9%E5%BD%A2_chdnba.png" alt="" />
            <img className={classes.logo1} src="https://res.cloudinary.com/dlapk94rx/image/upload/v1640129231/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/title2_igp4ev.gif" alt="" />
            </div>
               

                <div className={classes.h3}>
                    <h5 className={classes.h5}>投资众筹/</h5>
                    <h5 className={classes.h5}>商业贷款/</h5>
                    <h5 className={classes.h5}>商业租让/</h5>
                    <h5 className={classes.h5}>物业管理</h5>
                </div>


                    <Carousel className={classes.flex} {...settings}>
                        {
                            item.images.map((v,i)=>(
                                i!==0&&<img className={classes.img1} key={i} src={v} alt="" />
                            ))
                        }
                    </Carousel>

                    <div>
                    <FacebookShareButton
                        url={shareUrl}
                        quote={title}
                    >
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>  

                    <TwitterShareButton
                        url={shareUrl}
                        title={title}
                    >
                        <TwitterIcon size={32} round />
                    </TwitterShareButton>  

                    <TelegramShareButton
                        url={shareUrl}
                        title={title}
                    >
                        <TelegramIcon size={32} round />
                    </TelegramShareButton>

                    <WhatsappShareButton
                    url={shareUrl}
                    title={title}
                    separator=":: "
                >
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>

                <LinkedinShareButton url={shareUrl} >
                <LinkedinIcon size={32} round />
                </LinkedinShareButton>     

                <PinterestShareButton
            url={String(window.location)}
            media={`${String(window.location)}/`}
            
          >
            <PinterestIcon size={32} round />
          </PinterestShareButton>      

          <RedditShareButton
            url={shareUrl}
            title={title}
            windowWidth={660}
            windowHeight={460}
            
          >
            <RedditIcon size={32} round />
          </RedditShareButton>

          <EmailShareButton
            url={shareUrl}
            subject={title}
            body="body"
            
          >
            <EmailIcon size={32} round />
          </EmailShareButton>

          <LineShareButton
            url={shareUrl}
            title={title}
          >
            <LineIcon size={32} round />
          </LineShareButton>

          <WeiboShareButton
            url={shareUrl}
            title={title}
            image={`${String(window.location)}`}
            
          >
            <WeiboIcon size={32} round />
          </WeiboShareButton>
                       
                    
          
               </div>

                    <Link to={`/panorama/${page}`}>
                    <img className={classes.pana} src="https://res.cloudinary.com/dlapk94rx/image/upload/v1639864059/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/%E7%99%BD_maycjg.png" alt="" />
                    </Link>

            <div className={classes.divider}>
            <Typography className={classes.title}>
                                { item.address}
                            </Typography>

                            <Typography className={classes.money} variant={'subtitle1'}>
                               
                                $<NumberFormat
                                        value={item.price}
                                        displayType='text'
                                        thousandSeparator={true} 
                                        />
                            </Typography>

            </div>

            <div className={classes.divider}>
            <Typography className={classes.title}>
                                { item.bedroom} 卧| {item.bathroom} 卫 | {item.type}
                            </Typography>


                            <Typography className={classes.title} variant={'body1'}>
                               
                                {item.city}
                            </Typography>

            </div>

            <div className={classes.divider}>
            <Typography className={classes.title}>
                                MLS#:{ item.MLS}
                            </Typography>


                           <div>

                           </div>

            </div>

            <div className={classes.divider1}>
                    <div className={classes.agent}>
                        <img className={classes.weChat} src={item.agentImg} alt="" />

                        <div className={classes.agentContent}>
                            <p className={classes.typ}>{item.agentName}</p>
                            <p className={classes.typ}>电话：{item.agentPhone}</p>
                            <p className={classes.typ}>Email：{item.agentEmail}</p>
                        </div>
                    </div>

                    <img className={classes.weChat} src={item.weChat} alt="" />

            </div>
            
          

            <div className={classes.basic}>

                <div className={classes.basicItem}>
                    <h2 className={classes.h2}>基本信息</h2>
                    <Link to={`/appoint/${page}`}>
                    <Button className={classes.appoint} variant='contained' color='secondary'>预约看房</Button>
                    </Link>
                    
                </div>

                <div className={classes.basicItem1}>
                    <h5>房屋类型:</h5>
                    <h5>{item.type}</h5>
                </div>
                <hr className={classes.hr}/>

                <div className={classes.basicItem1}>
                    <h5>房屋风格:</h5>
                    <h5>{item.style || '--'}</h5>
                </div>
                <hr className={classes.hr}/>

                <div className={classes.basicItem1}>
                    <h5>卧室:</h5>
                    <h5>{item.bedroom || '--'}</h5>
                </div>
                <hr className={classes.hr}/>

                <div className={classes.basicItem1}>
                    <h5>厕所:</h5>
                    <h5>{item.bathroom || '--'}</h5>
                </div>
                <hr className={classes.hr}/>

                <div className={classes.basicItem1}>
                    <h5>挂牌日期:</h5>
                    <h5>{item.date || '--'}</h5>
                </div>
                <hr className={classes.hr}/>

                <div className={classes.basicItem1}>
                    <h5>挂盘价格:</h5>
                    <h5>$<NumberFormat
                                        value={item.price || '--'}
                                        displayType='text'
                                        thousandSeparator={true} 
                                        /></h5>
                </div>
                <hr className={classes.hr}/>

                <div className={classes.basicItem1}>
                    <h5>物业:</h5>
                    <h5>{item.date || '--'}</h5>
                </div>
                <hr className={classes.hr}/>

                <div className={classes.basicItem1}>
                    <h5>室内面积:</h5>
                    <h5>{item.area || '--'} sqft</h5>
                </div>
                <hr className={classes.hr}/>

                <div className={classes.basicItem1}>
                    <h5>单价(sqft):</h5>
                    <h5>$ {(item.price/item.area).toFixed(0)} </h5>
                </div>
                <hr className={classes.hr}/>

                <div className={classes.basicItem1}>
                    <h5>土地:</h5>
                    <h5>{item.land || '--'}</h5>
                </div>
                <hr className={classes.hr}/>

                <div className={classes.basicItem1}>
                    <h5>地下室:</h5>
                    <h5>{item.basement || '--'}</h5>
                </div>
                <hr className={classes.hr}/>

                <div className={classes.basicItem1}>
                    <h5>建造年代:</h5>
                    <h5>{item.buildYear || '--'}</h5>
                </div>
                <hr className={classes.hr}/>

                <div className={classes.basicItem1}>
                    <h5>地税额度:</h5>
                    <h5>$ {(item.price*item.tax).toFixed(0)}</h5>
                </div>
                <hr className={classes.hr}/>

                <div className={classes.basicItem1}>
                    <h5>产权信息:</h5>
                    <h5>{item.buildYear || '--'}</h5>
                </div>
                <hr className={classes.hr}/>

                <div className={classes.basicItem}>
                    <h4 className={classes.h2}>详细描述</h4>
                    
                </div>

                <div>
                    <p className={classes.des}>
                        {item.description}
                    </p>
                </div>

                <div className={classes.section}>
           
                    <button onClick={()=>handleMap()}  className={classes.aTag} >
                            地图信息
                    </button>

                    <button onClick={()=>handleLoan()}  className={classes.aTag} >
                            抵押贷款
                    </button>

                    <button onClick={()=>handleDetail()}  className={classes.aTag} >
                            详细信息
                    </button>

                    <button onClick={()=>handleNearBy()}  className={classes.aTag} >
                            附近房源
                    </button>
              
            </div>
                <div className={classes.hr}>
                {isMap&&<iframe src={item.map} width="100%" height="400"   loading="lazy"></iframe>}

                {
                    isLoan&&                <Loan item={item}/>  

                }
                {
                    isDetail&&<div className={classes.hr}>
                    <div className={classes.basicItem}>
                    <h2>详细信息</h2>
                </div>
                <h5>位置和一般信息</h5>


                        <div className={classes.basicItem1}>
                    <h5>建筑样式:</h5>
                    <h5>{item.basement || '--'}</h5>
                </div>
                <hr className={classes.hr}/>

                <div className={classes.basicItem1}>
                    <h5>街道名称:</h5>
                    <h5>{item.address || '--'}</h5>
                </div>
                <hr className={classes.hr}/>

                <div className={classes.basicItem1}>
                    <h5>郡或教区:</h5>
                    <h5>{item.date || '--'}</h5>
                </div>
                <hr className={classes.hr}/>

                <div className={classes.basicItem1}>
                    <h5>城市:</h5>
                    <h5>{item.city || '--'}</h5>
                </div>
                <hr className={classes.hr}/>

                <div className={classes.basicItem1}>
                    <h5>州或省:</h5>
                    <h5>{item.state || '--'}</h5>
                </div>
                <hr className={classes.hr}/>

                
                <div className={classes.basicItem1}>
                    <h5>邮政编码:</h5>
                    <h5>{item.zipCode || '--'}</h5>
                </div>
                <hr className={classes.hr}/>


                <div className={classes.basicItem1}>
                    <h5>地址:</h5>
                    <h5>{`${item.address},${item.city} ${item.state} ${item.zipCode}` || '--'}</h5>
                </div>
                <hr className={classes.hr}/> 

                <div className={classes.basicItem1}>
                    <h5>初中:</h5>
                    <h5>{item.middleSchool || '--'}</h5>
                </div>
                <hr className={classes.hr}/>   

                <div className={classes.basicItem1}>
                    <h5>高中:</h5>
                    <h5>{item.highSchool || '--'}</h5>
                </div>
                <hr className={classes.hr}/> 

                <div className={classes.basicItem1}>
                    <h5>高中学区:</h5>
                    <h5>{item.highSchool || '--'}</h5>
                </div>
                <hr className={classes.hr}/> 

                <div className={classes.basicItem1}>
                    <h5>有效建筑年份:</h5>
                    <h5>{item.highSchool || '2015'}</h5>
                </div>
                <hr className={classes.hr}/> 

                <div className={classes.basicItem1}>
                    <h5>房屋种类:</h5>
                    <h5>{item.highSchool || 'Residential'}</h5>
                </div>
                <hr className={classes.hr}/> 

                <div className={classes.basicItem1}>
                    <h5>房屋子类:</h5>
                    <h5>{item.type || '--'}</h5>
                </div>
                <hr className={classes.hr}/> 

                <div className={classes.basicItem1}>
                    <h5>年税额:</h5>
                    <h5>$ {(item.price*item.tax).toFixed(0) || '--'}</h5>
                </div>
                <hr className={classes.hr}/> 

                <div className={classes.basicItem1}>
                    <h5>车位:</h5>
                    <h5>{item.garage || '--'}</h5>
                </div>
                <hr className={classes.hr}/> 


                    </div>
                }

{
                    isNearBy&&<div className={classes.hr}>

                    <div className={classes.basicItem}>
                    <h2>附近房源信息</h2>
                </div>

                <h3>
                暂无附近房源信息
                </h3>

                    </div>
                }
                    
                </div>

            </div>

            <h5>Compilation of translated data © All Real Estate Boards across Canada and US. NOTE: This representation is based in whole or in part on data generated by the Real Estate Boards which assumes no responsibility for its accuracy. </h5>

            {/* <div className={classes.section2}>
                <a className={classes.aTag2} href="mailto:lily@360realty.online">Email联系</a>

                <a className={classes.aTag2} href="tel:+13013269795">{item.phone}</a>
            </div> */}
        </div>
    )
}
