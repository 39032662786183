const data = [
    {
        address:'1335 63rd St',
        phone:'3478371813',
        bedroom:'5',
        bathroom:'3',
        area:'',
        price:'1990000',
        garage:'6',
        images:[
          'https://res.cloudinary.com/dlapk94rx/image/upload/v1644354998/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/457448/ed1c07d52a88877bc42d8c4fb7d104d2-cc_ft_768_vlgo4o.webp',
          'https://res.cloudinary.com/dlapk94rx/image/upload/v1644354998/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/457448/7b45815a4122acc7d2af12c769bc9840-cc_ft_768_pf2ifa.webp',
          'https://res.cloudinary.com/dlapk94rx/image/upload/v1644354998/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/457448/53cb80852dad6d5b78e7f403b586b341-cc_ft_768_qkoqv9.webp',
          'https://res.cloudinary.com/dlapk94rx/image/upload/v1644354998/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/457448/223cfaafad86af1248332e00cc863304-cc_ft_768_xmfqmz.webp',
          'https://res.cloudinary.com/dlapk94rx/image/upload/v1644354998/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/457448/bb7560183ae1d03689cfea20877f9420-cc_ft_1536_ns69oi.webp'
        ],
        tourUrl:'https://my.matterport.com/show/?m=q3DuXkP7UVS&play=1',
        location:`Brooklyn`,
        description:`1335-1337 63rd Street - Block 05733, Lot 0063 & 0064. Prime Bensonhurst / Boro Park location for this rare 60 x 100 lot.  Detached 2 family house with finished basement. Home is Immaculate move in condition. Private driveway with huge multi detached 6 car garage.  Great income producing property.  R5B zoning,  with FAR 1.35 for development site of 8,100 buildable sq ft.`,
        floorPlan:[
         
       ],
       lng:  -73.99926998709304,
       lat:40.62690992145601, 
       
       tax:1078,
       insurance:697,
       hoa:0,
       year:'1930',
       zipCode:11219,
       mls:'457448',
       type:'Multi Family'
      },

      {
        address:'1431 E 7th St',
        phone:'3478371813',
        bedroom:'7',
        bathroom:'4',
        area:'2220',
        price:'1999000',
        garage:'',
        images:[
          'https://res.cloudinary.com/dlapk94rx/image/upload/v1644357373/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/458209/764689ba47700a963c9b3930c6d4e28d-cc_ft_768_axrlg3.webp',
          'https://res.cloudinary.com/dlapk94rx/image/upload/v1644357373/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/458209/3573455a66cbb9888137941fe2e72303-cc_ft_1536_kljzvm.webp',
          'https://res.cloudinary.com/dlapk94rx/image/upload/v1644357373/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/458209/502ea6bc57c5a050f9505a1ec2f4de2d-cc_ft_768_clmlsn.webp',
          'https://res.cloudinary.com/dlapk94rx/image/upload/v1644357373/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/458209/1ab2fc83d66165582ab2779899e5ecda-cc_ft_768_bos83v.webp',
          'https://res.cloudinary.com/dlapk94rx/image/upload/v1644357373/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/458209/a8164bcc83a07d2d8dd812a6519b22fc-cc_ft_768_rvyizr.webp',
        ],
        tourUrl:'https://app.cloudpano.com/tours/EF-qkzcFP5',
        location:`Brooklyn`,
        description:`Detached 6 bedroom 1 family beauty on extra long lot for sale!     This home boasts a large living room and dining room. You'll enjoy the spacious kosher kitchen and sep dinette area. Sliding glass doors lead onto rear deck.  Great backyard! Property is 120.5' long!      Must see this master bedroom suite with master bath and great closets. Nursery/sitting area/office adjacent to master bedroom.      Basement is finished with bedroom and full bath.  An opportunity like this doesn't come along too often! Move in condition!`,
        floorPlan:[
         
       ],
       lng:  -73.96731770429093,
       lat:40.61623458251514, 
       
       tax:1083,
       insurance:700,
       hoa:0,
       year:'1920',
       zipCode:11230,
       mls:'458209',
       type:'Single Family'
      },

      {
        address:'240 E 92nd St',
        phone:'3478371813',
        bedroom:'7',
        bathroom:'4',
        area:'4080',
        price:'950000',
        garage:'4',
        images:[
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644358227/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/3371878/ba0def4d0c3724420e28be5fc38258e7-cc_ft_1536_j2yfbf.webp',
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644358226/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/3371878/ced9ca183c0419995fb2ca37fe79c2b2-cc_ft_768_zu8l56.webp',
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644358226/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/3371878/982d429aa4b1ca0f689b773d95281353-cc_ft_768_h1hcnn.webp',
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644358226/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/3371878/a6b030f3170c4b1f94dea569a14fe023-cc_ft_768_ck7ufx.webp',
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644358226/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/3371878/7935617c6343b961165c4e2bbf1cb811-cc_ft_768_tuxpky.webp',
            
        ],
        tourUrl:'https://my.matterport.com/show/?title=0&mls=2&brand=0&m=qjh66yayLr3',
        location:`Brooklyn`,
        description:`Amazing oversized well maintained solid brick 3 family with garage parking. Unit 1A - 2BRs, spacious eat-in kitchen, lrg DR, LR & office/sitting rm (could easily be converted to a 3rd BR.) Unit 2F - 2BRs, LR & eat in kitchen. Well appointed unit complete w upgraded kitchen w ss appliances, subway tiled backsplash, new counters & flooring. Modern bthr features rainfall shower w glass door, elegant bowl vanity w modern faucet, dual flush toilet & contemporary gray tile. Unit 2B - 1BR, spacious LR & eat-in kitchen. Unit completely renovated w newer kitchen offering ss appliances, modern counters w subway tile backsplash. Updated bthrm w modern vanity, new tile & flooring. BR w new carpet, paint & stylish wallpaper. Finished bsmt w front entrance & back exit, full bthrm, kitchenette, laundry, utility area & addtl rms.  Perfect for owner occupant or investor. Welcoming painted front door, vestibule & planters, renovated common areas & roof top access, this property checks all the boxes.`,
        floorPlan:[
         
       ],
       lng:  -73.92514694485081,
       lat:40.65968931118439, 
    
       tax:515,
       insurance:333,
       hoa:0,
       year:'1930',
       zipCode:11212,
       mls:'3371878',
       type:'Multi Family'
      },

      {
        address:'166-168 Hylan Blvd',
        phone:'3478371813',
        bedroom:'12',
        bathroom:'4',
        area:'3654',
        price:'1498888',
        garage:'',
        images:[
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644359795/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/1148582/de25e60c3e15bd4af2c544f4357c05cd-cc_ft_1536_v4qnvu.webp',
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644359795/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/1148582/eb96a6ca09aa539dd1627901540de443-cc_ft_768_znfcxg.webp',
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644359795/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/1148582/8da36cfd74a74a7e559a23a177c77cb5-cc_ft_768_pneovv.webp',
        ],
        tourUrl:'https://my.matterport.com/show/?m=wbDrC54jtvi&play=1&brand=0',
        location:`Staten Island`,
        description:`Better than new construction 4-Family in Rosebank! This property has been gutted down to the beams. Proximity to Brooklyn and Staten Island Expressway. Each unit is a 3-bedroom 1-bath with separate meter and individual Navien tankless combination heating system. Property comes with a Quonset hut in the yard.  Inquire for details about off street parking. Most Cryptocurrencies accepted.`,
        floorPlan:[
         
       ],
       lng:  -74.06787218894655,
       lat:40.61307915855167, 
       
       tax:1087,
       insurance:525,
       hoa:0,
       year:'1945',
       zipCode:10305,
       mls:'1148582',
       type:'Multi Family'
      },

      {
        address:'2143 Bleecker St',
        phone:'3478371813',
        bedroom:'4',
        bathroom:'2',
        area:'',
        price:'950000',
        garage:'',
        images:[
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644360643/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/3370843/ea20e4bcda4fe556ea912c3b76ad241f-cc_ft_768_qezpcw.webp',
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644360643/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/3370843/710b716927dc5822eacd4e4bbababc90-cc_ft_768_vhxlxn.webp',
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644360643/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/3370843/5671eafdbefd45b0da56587e04ba0aa4-cc_ft_768_dvbtop.webp'
        ],
        tourUrl:'https://my.matterport.com/show/?m=wbDrC54jtvi&play=1&brand=0',
        location:`Flushing`,
        description:`Welcome to 2 family brick house located in the heart of Ridgewood, This property features full basement and two spacious apartments. Each apartment consists of 2 bedrooms, living/dining room, kitchen with dishwasher and bath. Conveniently located near shopping, restaurants and close to M train to Manhattan. Great for owner residence or Investment. Do Not Miss this Opportunity!`,
        floorPlan:[
         
       ],
       lng:  -73.90610551592613,
       lat: 40.71058069470909, 
      
       tax:665,
       insurance:333,
       hoa:0,
       year:'1901',
       zipCode:11385,
       mls:'3370843',
       type:'Multi Family'
      },

      {
        address:'19-42 77th St',
        phone:'3478371813',
        bedroom:'5',
        bathroom:'3',
        area:'',
        price:'1999999',
        garage:'2',
        images:[
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644361075/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/3355620/3181944326b4a3ec9e9677f0fd0cd7c5-cc_ft_1536_ftswxc.webp',
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644361075/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/3355620/03657f67d3f9432446b58c2f8dce74d9-cc_ft_768_nk5be8.webp',
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644361075/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/3355620/ad81cb5901a49deef4725c9e46214b8f-cc_ft_768_vecxan.webp'
        ],
        tourUrl:'https://my.matterport.com/show/?m=aY6rUBTD9JB&play=1',
        location:`Queens`,
        description:`This building has been completely gut renovated from top to bottom, down to the studs.  New Everything!  New Plumbing, electric, walls, floors, ceilings, roof, windows.  3 Separate gas meters and boilers.  Prime Astoria Heights/Upper Ditmars Area.  Close to transportation, schools, restaurants, shops.`,
        floorPlan:[
         
       ],
       lng:  -73.89344297359608,
       lat: 40.77240320552161,
        
       tax:1400,
       insurance:700,
       hoa:0,
       year:'1960',
       zipCode:11370,
       mls:'3355620',
       type:'Multi Family'
      },


      {
        address:'1713 Putnam Ave',
        phone:'3478371813',
        bedroom:'12',
        bathroom:'11',
        area:'7480',
        price:'3100000',
        garage:'',
        images:[
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644361737/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/3365397/cc3421e5a7e327d796e639e27abed25f-cc_ft_1536_kq8x7h.webp',
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644361737/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/3365397/265bb91b53fc90d2d5724555b6eeb3ca-cc_ft_768_kagloj.webp',
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644361737/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/3365397/f5d8cad278a04b7b4522232fbde81d4f-cc_ft_768_suylnc.webp'

        ],
        tourUrl:'https://my.matterport.com/show/?m=Ef3fXxz7AeD&play=1&brand=0',
        location:`Ridgewood`,
        description:`This NON-STABILIZED, legal eight-family house is conveniently located, very close to Myrtle-Wyckoff L-train, many stores, restaurants, and cafes. High income, low expenses, four vacant units!`,
        floorPlan:[
         
       ],
       lng: -73.90632891592648,
       lat: 40.70110932226657,
       tax:2170,
       insurance:1085,
       hoa:0,
       year:'1960',
       zipCode:11385,
       mls:'3365397',
       type:'Multi Family'
      },

      {
        address:'141-22 72nd Dr',
        phone:'3478371813',
        bedroom:'5',
        bathroom:'4',
        area:'2175',
        price:'1088888',
        garage:'',
        images:[
           'https://res.cloudinary.com/dlapk94rx/image/upload/v1644362490/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/11032660/276a373ea16f2304a0f7f56f74a8ecb8-cc_ft_1536_zjf6je.webp',
           'https://res.cloudinary.com/dlapk94rx/image/upload/v1644362490/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/11032660/b6fe6e53430256cc18e7c56966f14e0d-cc_ft_768_nlpcx2.webp',
           'https://res.cloudinary.com/dlapk94rx/image/upload/v1644362490/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/11032660/96247e8d90b4bb0b9470aedd2176f8ab-cc_ft_768_vpinsa.webp',
           'https://res.cloudinary.com/dlapk94rx/image/upload/v1644362490/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/group/11032660/577a83da1b4408556cc3454088ff9ea2-cc_ft_768_ypp3ql.webp'

        ],
        tourUrl:'https://my.matterport.com/show/?m=fuBnH6KmTdq&play=1',
        location:`Flushing`,
        description:`This 2175 square foot single family home has 5 bedrooms and 4.0 bathrooms. This home is located at 141-22 72nd Dr, Flushing`,
        floorPlan:[
         
       ],
       lng: -73.82242846010632,
       lat: 40.72491508917403,
       tax:762,
       insurance:381,
       hoa:0,
       year:'1950',
       zipCode:11367,
       mls:'11032660',
       type:'Multi Family'
      },


]


const getGroupData = ()=>{
    return data
}

export default getGroupData