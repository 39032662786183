import React,{useState,useEffect} from 'react'
import {Typography,Button,TextField,Slider,Switch} from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { makeStyles} from "@material-ui/core/styles";
import NumberFormat from 'react-number-format';
import Chart from "react-apexcharts";


const useStyles = makeStyles({
    title:{
        fontWeight:'600'
    },
    even:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
    },
    blue:{
        width:'15px',
        height:'15px',
        backgroundColor:'#0f8ffb',
        borderRadius:'2px',
        marginRight:'5px'
    },
    green:{
        width:'15px',
        height:'15px',
        backgroundColor:'#47ebb4',
        borderRadius:'2px',
        marginRight:'5px'

    },
    yellow:{
        width:'15px',
        height:'15px',
        backgroundColor:'#fec659',
        borderRadius:'2px',
        marginRight:'5px'

    },
    red:{
        width:'15px',
        height:'15px',
        backgroundColor:'#fd798c',
        borderRadius:'2px',
    },
    flex:{
        display:'flex',
        justifyContent:'flex-start',
        alignItems:'center',
        marginRight:'5px'


    },
    pie:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        // marginLeft:'80px'
    },
    loan:{
        fontSize:'15px',
        fontWeight:'bold',
        marginRight:'25px',
    },
    gridContainer:{
      width:'100%',
      overflowX:'scroll',
      width:'100%',
    }
})


export default function AptLoan({item}) {
    const classes = useStyles()
    const [total,setTotal] = useState(item.price) 
    const max = item.price*1.2;
    const min = item.price*0.7;
    const minDown = item.price*0.2;
    const maxDown = item.price
    const minLoan = 2
    const maxLoan = 5
    const [payMonth,setPayMonth] = useState(Number)
    const [tax,setTax] = useState(Math.round((item.tax))
    )
    const [loanPercent,setLoanPercent] = useState(4)
    const [year,setYear] = useState(30)
    const [downPay,setDownPay] = useState(total*0.2)
    const [insurance,setInsurance] = useState(Math.round(item.insurance)
    |0)
    const [hoa,setHoa] = useState(item.hoa|0)
    const [optionsBar,setOptionsBar] = useState(
    )
    const [phone,setPhone] = useState(false);
    const [mp,setMp] = useState(Number)
    const [data,setData] = useState()
    const [width,setWidth] = useState(Number)
    const [checked,setChecked] = useState(true)
    useEffect(()=>{
        const width = window.innerWidth
        setWidth(width)
        if(width>500){
            setPhone(false)
        }
        else{
          setPhone(true) 
            }
    },[])

    useEffect(() => {
        const month = year * 12;
        const monthLi = loanPercent /12/100;
        const loanTotal = total -downPay;

        const yueGong =Math.round((loanTotal * monthLi * Math.pow((1+monthLi),month)) / (Math.pow((1+monthLi),month)-1)) ;
        setPayMonth(yueGong)
      
        const temp = payMonth + tax + hoa + insurance
        setMp(temp)

            
    },[payMonth,tax,hoa,insurance,downPay,total,year,loanPercent])

    useEffect(() => {
  
        const temp = {
            options: {
                labels: ['Principal and Interest', 'Tax', 'Hoa', 'Insurance'],
                dataLabels: {
                    enabled: true,
                    
                    dropShadow: {
                      enabled:true
                    }
                  },
                  plotOptions: {
                    pie: {
                      donut: {
                        labels: {
                          show: true,
                          name: {
                            show: true,
                          },
                          value: {
                            show: true,
                          },
                          total: {
                            show: true,
                            label: 'Total $',
                            formatter: function (w) {
                              return w.globals.seriesTotals.reduce((a, b) => {
                                return a+b
                              }, 0)
                            }
                          }
                        }
                      }
                    }
                  }
              },
            series: [payMonth, tax, hoa, insurance],
           
        }
        setData(temp)
        
        const chart ={
            optionsBar: {
                chart: {
                  stacked: true,
                  stackType: "100%",
                  toolbar: {
                    show: true
                  }
                },
                plotOptions: {
                  bar: {
                    horizontal: true
                  }
                },
                dataLabels: {
                  dropShadow: {
                    enabled: true
                  }
                },
                stroke: {
                  width: 0
                },
                xaxis: {
                  categories: phone ? [""]: ["Month Payment Percentage"],
                  labels: {
                    show: false
                  },
                  axisBorder: {
                    show: false
                  },
                  axisTicks: {
                    show: false
                  }
                },
                fill: {
                  opacity: 1,
                  type: "gradient",
                  gradient: {
                    shade: "dark",
                    type: "vertical",
                    shadeIntensity: 0.35,
                    gradientToColors: undefined,
                    inverseColors: false,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [90, 0, 100]
                  }
                },
        
                legend: {
                  position: "bottom",
                  horizontalAlign: "center"
                }
              },
              seriesBar: [
                {
                  name: "Principal and Interest",
                  data: [payMonth]
                },
                {
                  name: "tax",
                  data: [tax]
                },
                {
                  name: "hoa",
                  data: [hoa]
                },
                {
                  name: "insurance",
                  data: [insurance]
                }
              ]
            } 
            setOptionsBar(chart)
    },[payMonth,tax,insurance,hoa])


    
    const handleSlidePrice = (event,newValue)=>{
        // console.log(value);
        setTotal(newValue)
    }
    const handleSlideDown = (event,newValue)=>{
        setDownPay(newValue)
    }


    const handleChange = (event) => {
        
        setChecked(event.target.checked);
        checked ? setYear(15) : setYear(30)
    };
    const handleSlideLoan = (event,newValue) => {
        setLoanPercent(newValue)
    }
 

    return (
        <div>
            <h3 className={classes.title}>
                Payment Calculator
            </h3>

           
            <GridContainer className={classes.gridContainer}>

                <GridItem  xs={12} sm={12}>
                    <Typography>
                        Home Price : $<NumberFormat 
                    value={total}
                    displayType='text' 
                    thousandSeparator={true}
                    /> 
                    </Typography>
                   
                <Slider value={total} 
                min={Number(min)}
                max={Number(max)}
                onChange={(e,newValue)=>handleSlidePrice(e,newValue)}
                step={10000}
                marks={true}
                 />
                </GridItem>

                <GridItem xs={12} sm={12}>
                    <Typography>
                        Down Payment : $<NumberFormat 
                    value={downPay}
                    displayType='text' 
                    thousandSeparator={true}
                    /> 
                    ({Math.round(downPay/total*100)}%)
                    </Typography>
                   
                <Slider value={downPay} 
                
                onChange={(e,newValue)=>handleSlideDown(e,newValue)}
                step={10000}
                marks={true}
                min={minDown} 
                max={maxDown}
                color='secondary'
                 />
                </GridItem>

                <GridItem  xs={12} sm={12}>
                    <Typography>
                    Interest Rate : <NumberFormat 
                    value={loanPercent}
                    displayType='text' 
                    thousandSeparator={true}
                    /> %
                    </Typography>
                   
                <Slider value={loanPercent} 
                min={minLoan}
                max={maxLoan}
                onChange={(e,newValue)=>handleSlideLoan(e,newValue)}
                step={0.25}
                marks={true}
                 />
                </GridItem>

                <GridItem className={classes.pie} xs={12} sm={12}>
                <Typography className={classes.loan}>Loan Years</Typography>

                <Typography>15 Year</Typography>
                <Switch
                    checked={checked}
                    onChange={handleChange}
                />
                <Typography>30 Year</Typography>

                </GridItem>

                <GridItem xs={12} sm={12}>
                <div className={classes.even}>
                <h4 className={classes.title}>
                    $
                    <NumberFormat 
                    value={mp}
                    displayType='text' 
                    thousandSeparator={true}
                    /> 
                      per month
                </h4>

                <Button variant='contained' color='primary'>
                    Find a Lender
                </Button>
            </div>

                </GridItem>

                    {optionsBar&&<GridItem className={classes.pie} xs={12} sm={12}>
                    <Chart
              options={optionsBar.optionsBar}
              height={140}
              series={optionsBar.seriesBar}
              type="bar"
              width={phone ? 200 : width*0.4}
            />
                    </GridItem>}

            <GridItem xs={12} sm={6}>
                <h4 className={classes.flex}>
                    <div className={classes.blue}></div>
                    Principal and Interest: $<NumberFormat 
                    value={payMonth}
                    displayType='text' 
                    thousandSeparator={true}
                    /> 
                </h4>
            </GridItem>

            <GridItem xs={12} sm={6}>
            <h4 className={classes.flex}>
                    <div className={classes.green}></div>
                    Tax: $<NumberFormat 
                    value={tax}
                    displayType='text' 
                    thousandSeparator={true}
                    /> 
                </h4>
            </GridItem>

            <GridItem xs={12} sm={6}>
            <h4 className={classes.flex}>
                    <div className={classes.yellow}></div>
                    Hoa: $<NumberFormat 
                    value={hoa}
                    displayType='text' 
                    thousandSeparator={true}
                    /> 
                </h4>
            </GridItem>

            <GridItem xs={12} sm={6}>
            <h4 className={classes.flex}>
                    <div className={classes.red}></div>
                    Insurance: $<NumberFormat 
                    value={insurance}
                    displayType='text' 
                    thousandSeparator={true}
                    /> 
                </h4>
            </GridItem >

           { data&&<GridItem className={classes.pie} xs={12} sm={12}>

                <Chart 
                options={data.options}
                series={data.series} 
                type="donut"
                 width={phone ? 360:width*0.4}
                />

            </GridItem>}


            </GridContainer>

        </div>
    )
}

