const DATA =[
    {
     title:'法拉盛Condo',
     name:'Sanford',
     No:'apt-1',
     address:'132-20 Sanford Ave',
     phone:'3478371813',
     bedroom:'2',
     bathroom:'1',
     area:'600',
     price:'478000',
     garage:'',
     images:[
       'https://res.cloudinary.com/dlapk94rx/image/upload/v1637703614/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/132-20%20Sanford/WechatIMG4000_hfubxs.png',
       'https://res.cloudinary.com/dlapk94rx/image/upload/v1637703614/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/132-20%20Sanford/WechatIMG3998_bvdl3p.png',
       'https://res.cloudinary.com/dlapk94rx/image/upload/v1637703614/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/132-20%20Sanford/WechatIMG3997_d7ztpg.png',
       'https://res.cloudinary.com/dlapk94rx/image/upload/v1637703614/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/132-20%20Sanford/WechatIMG3999_qq6aja.png',

     ],
     tourUrl:'https://www.zillow.com/view-3d-home/db875e54-bf0c-4888-963b-b6139d0126cc',
     location:'Flushing',
     description:'近华人超市，生活便利',
     floorPlan:[
       ''
    ],
    lng:-73.83226362896633,
    lat:40.754790141851615,
    tax:3960,
    insurance:1200,
    hoa:200,
    year:'',
    zipCode:11355,
    mls:'',
},
{
    title:'法拉盛Condo',
    name:'Sanford',
    No:'apt-2',
    address:'144-11 Sanford Ave',
    phone:'3478371813',
    bedroom:'1',
    bathroom:'1',
    area:'600',
    price:'298000',
    garage:'',
    images:[
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1637774365/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/144-11%20Sanford%20Ave/3326355_7_250x250_yzltbi.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1637774365/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/144-11%20Sanford%20Ave/3326355_9_250x250_bjymwq.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1637774365/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/144-11%20Sanford%20Ave/3326355_12_250x250_ippbut.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1637774365/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/144-11%20Sanford%20Ave/3326355_11_250x250_mbrioz.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1637774365/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/144-11%20Sanford%20Ave/3326355_8_250x250_eyxelx.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1637774365/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/144-11%20Sanford%20Ave/3326355_6_250x250_eaxgrt.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1637774365/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/144-11%20Sanford%20Ave/3326355_10_250x250_ktke8c.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1637774364/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/144-11%20Sanford%20Ave/3326355_3_250x250_bqngzo.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1637774365/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/144-11%20Sanford%20Ave/3326355_13_250x250_lem3kr.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1637774364/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/144-11%20Sanford%20Ave/3326355_5_250x250_k8he2z.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1637774364/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/144-11%20Sanford%20Ave/3326355_1_250x250_ann6w6.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1637774364/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/144-11%20Sanford%20Ave/3326355_2_250x250_u06rcu.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1637774364/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/144-11%20Sanford%20Ave/3326355_4_250x250_qoikur.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1637774364/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/144-11%20Sanford%20Ave/3326355_14_250x250_hyksz0.jpg',
    ],
    tourUrl:'',
    location:'Flushing',
    description:'1 Bedroom 1 Bath also have balcony . Each Room With Windows, Sublet Allowed After 2 Years. Bus Q12/Q26 at Building Corner, Lirr Murray Hills Station. 4 Blocks To Supermarket.',
    floorPlan:[
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820593/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/f14508f8f1f03a00bc8f2a8273f45a50-cc_ft_768_zapypk.webp'
   ],
   lng: -73.8204114153895,
   lat:40.75961877694984,
   tax:0,
   insurance:1200,
   hoa:578,
   year:'1951',
   zipCode:11355,
   mls:''
},
{
  // title:'超豪华顶层公寓',
  // name:'Floral',
  // No:'apt-3',
  address:'27110 Grand Central Pkwy PENTHOUSE D',
  phone:'3478371813',
  bedroom:'4',
  bathroom:'6',
  area:'2700',
  price:'1995000',
  garage:'3',
  images:[
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820674/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/eac50c8a65136932dbccd2b415850f86-cc_ft_768_kpee8c.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820674/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/aee23758096714d8aa337e55ccdeafd4-cc_ft_768_kxm4y0.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820674/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/c77eb1f7a88a84e4fdb5c4a57dea2968-cc_ft_1536_i4tjo6.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820674/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/b351eb1fe63b87c0b113c4937a876531-cc_ft_768_eaftld.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820674/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/f17888c5f4579edc69564a9c87d0cd5e-cc_ft_768_cd9gtl.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820673/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/9721edada80adfac89ee200d4b6385b2-cc_ft_768_y6eup7.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820673/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/525ff16797b354751c90a68f7e26c364-cc_ft_768_wvquh6.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820673/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/457425fe91d3fcbc9c79b758e892bbf5-cc_ft_768_d39dlb.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820673/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/ac4784c807eee8932b57906212a15042-cc_ft_768_r0ok9x.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820673/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/3525dfb49d326df08771c34ef5139e92-cc_ft_768_awgjed.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820673/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/64f5b01bdd3c3f7ade10221440c5ae1d-cc_ft_768_bcvhct.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820673/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/ab1aece233da28a68bf0d079d295d4b1-cc_ft_768_febhzd.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820673/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/dfc39b6a8460b1116295ad21b208a527-cc_ft_768_ejpktm.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820673/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/316193f52fb8205b7b6adbcb46f36245-cc_ft_768_agcuc0.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820673/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/403cb26bdd99049d29f102940cda256d-cc_ft_768_knwbpx.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820673/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/9e1dc0c8915d7c49ea526b0288b4e26a-cc_ft_768_ailjjd.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820673/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/61bcdb5afdb8b6b940df8cd09d0ddec1-cc_ft_768_gn9urc.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820673/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/07016bfdef28dad291c033de89c5854f-cc_ft_768_rscieq.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820672/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/190de4a17cf3c4a3d06f2ba68b02a14c-cc_ft_768_lsv6sg.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820672/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/3ee6d90a7ae383e1773ff9003ead29b3-cc_ft_768_acnosf.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820672/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/6f613bedcee5bacd9275f2e2a8785c8f-cc_ft_768_kmyg0r.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820672/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/2df654856327680fc5728f94d9d4706c-cc_ft_768_lfnhzv.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820672/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/6ed370074d58e293e4c5ae18ad7412aa-cc_ft_768_pyvula.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643820672/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3250893/0dd419ca84e49aba5ffbf7dd0215560e-cc_ft_768_zgcw4l.webp',

  ],
  tourUrl:'https://my.matterport.com/show/?m=qKpFw55GDgr&play=1&brand=0',
  location:'Floral Park',
  description:`Enjoy over 2,700 square feet of loft-like living space on the top floor. The largest penthouse available featuring 2 separate entrances. This magnificent renovated corner apartment boasts beautiful tiled floors throughout, banquet size formal dining room, huge double living room with floor-to-ceiling windows, gourmet eat in kitchen with a separate laundry room and maid's quarters. An oversized Master Bedroom suite with his and her dressing areas with professional fitted closets, a sunny and spacious Master Bathroom with jumbo glass stall shower and entrance to the terrace. Three additional palatial bedrooms each with a lavish bath plus a guest powder room. All this with south  Western exposures from your 1,200 square foot wrap-around terrace with panoramic views of open skyline and Long Island's luscious landscape. Best location in Building 1! Call for a private viewing.`,
  floorPlan:[
    ''
 ],
 lng: -73.71376030238102,
 lat:40.757593836130226,
 tax:0,
 insurance:698,
 hoa:6348,
 year:'1975',
 zipCode:11005,
 mls:'3250893'
},
{
  title:'Rego park ',
  name:'Rego park ',
  No:'apt-4',
  address:'10326 68th Ave APT 2E',
  phone:'3478371813',
  bedroom:'2',
  bathroom:'1',
  area:'1050',
  price:'439000',
  garage:'',
  images:[
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643822398/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3373423/db4989e68bd5f34bcb2c00665301eb2c-cc_ft_768_jhx6at.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643822397/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3373423/41cc053327f8bee7b7dc25a04046ecb0-cc_ft_768_wbqz3e.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643822398/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3373423/6991ad5bddd5a65bae6f99e586c8ea78-cc_ft_1536_dcntew.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643822398/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3373423/f63ebcf398ad5e7e391650321fc56626-cc_ft_768_fndmhz.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643822398/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3373423/f6af3f603c4da25c99b3bf4cfd02f7f3-cc_ft_768_rq6arh.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643822397/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3373423/a2a8376c9716b7a1def70a7e1d3f1016-cc_ft_768_yctnx1.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643822397/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3373423/d8790f2c78794375ff9f89d58fbdfa48-cc_ft_768_o6zknf.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643822397/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3373423/19db4c67a9a8a452069f8113e7827a0a-cc_ft_768_o0czh2.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643822397/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3373423/7651f2ab499d460ab3710169c1e5d501-cc_ft_768_wuolme.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643822397/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3373423/55f2ce8fe317dd88b3cdf97011a20997-cc_ft_768_jujzc8.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643822397/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3373423/2cd75beb764ece1f7834b58b03a533c5-cc_ft_768_bftqdq.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643822397/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3373423/3d523b34cf1b8d565ec7c1a48d61f503-cc_ft_768_u4m4vd.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643822397/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3373423/9df9a77fbe470653dba62451dc353e6e-cc_ft_768_nb4p6g.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643822397/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3373423/b2b4fa436352810d0ddbbf3033924f97-cc_ft_768_ffrreo.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643822397/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3373423/8db968bae18e8b339d874cbe990651ce-cc_ft_768_g9yttr.webp',
    
  ],
  tourUrl:'https://my.matterport.com/show/?brand=0&m=LQLkHaBECkf&mls=1',
  location:'Rego Park',
  description:`Convenience found in her name! The Boulevard Apartments of Forest Hills is centrally located within minutes to Queens Boulevard and trains to Manhattan. Open and airy floor plan as you enter this true 2 bedroom corner unit. The eco-friendly and timeless bamboo flooring offers an amazing flow throughout and is placed above a soundproof cork underlayment. You're immediately drawn to the open kitchen, stainless steel appliances, renovated with rich and warm cherry cabinets, granite countertops and the seamlessly extended granite backsplash. The large living room and designated formal dining area is perfect for entertaining. Plenty of custom closet throughout, fitted for optimal storage. An updated windowed full bath is lined with matte porcelain tiles. The sizable bedrooms are a perfect fit and offers amazing closet space.`,
  floorPlan:[
    ''
 ],
 lng: -73.84957660226473,
 lat:40.72598562477357,
 tax:0,
 insurance:154,
 hoa:1045,
 year:'1950',
 zipCode:11375,
 mls:'3373423'
},
{
  address:'8610 34th Ave',
  phone:'3478371813',
  bedroom:'2',
  bathroom:'1',
  area:'',
  price:'545000',
  garage:'',
  images:[
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643918438/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/H6150555/2c279702a9a9bdf6940097cb9c3b4a01-cc_ft_1536_sdmuof.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643918438/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/H6150555/356607bd8965e773cfcd04b6dc9aa60a-cc_ft_768_yv6bl3.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643918438/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/H6150555/d9d4a57c1fc0115f6a757293e4671f0d-cc_ft_768_wzrvov.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643918437/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/H6150555/7f230d1195243e492999ea0ebd5fe996-cc_ft_768_kfbmep.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643918438/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/H6150555/71b7b388ae73eb33e815a9f7b401e2ea-cc_ft_768_ikes7u.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643918438/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/H6150555/fe84a4a3eef73fdfba9650ea024f976c-cc_ft_768_i7rlwu.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643918438/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/H6150555/66016a4bfeed9163aa3f6f4ab20fd1b7-cc_ft_768_rfwoqq.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643918438/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/H6150555/ce3750e60153b0783d29fa9775bec153-cc_ft_768_g1uj20.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643918438/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/H6150555/e0d322fc2a068f64cadac6c546bb27d6-cc_ft_768_kx2aqd.webp',
  ],
  tourUrl:'https://my.matterport.com/show/?m=gRwcmMgzSyA&play=1',
  location:'Queens',
  description:`This recently renovated apartment at The Griswold is the perfect fit for someone with an eye for quality. The gut renovated 2 bedroom 1 bath has all the bells and whistles boasting new plumbing and electric throughout the apartment. The gorgeous kitchen is appointed with black stainless steel appliances and Forevermark cabinets accented with Brass hardware. Marazzi floor tiles and quartzite countertops show the quality and love gone into the renovations. The stunning bathroom with separate shower and bathtub has new black slate and matte white tiles throughout. The vanity top & tub are surrounded with Brazilian Black Slate. Duravit toilet, Symmons faucets and additional shelving.Master bedroom and second bedroom have been built with Quietrock soundproofing on all walls and ceilings throughout that almost assure a good night's sleep. Both bedrooms have custom closets.An abundance of windows throughout brightens the space naturally.Original refinished oak flooring throughout. Must see!!`,
  floorPlan:[
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1643918429/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/H6150555/262974992e870d85ca00a644d3245e8f-cc_ft_768_ohqaep.webp'
 ],
 lng: -73.88076157329779,
 lat:40.754050298116844,
 tax:0,
 insurance:191,
 hoa:670,
 year:'1937',
 zipCode:11372,
 mls:'H6150555'
},

{
  address:'133-27 39th Ave #11R',
  phone:'3478371813',
  bedroom:'3',
  bathroom:'3',
  area:'1614',
  price:'1888000',
  garage:'',
  images:[
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643921530/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3366455/e91efd63dc50147221f83ce00c3423ea-cc_ft_768_pnnqdm.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643921530/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3366455/926f1f746025a889b404c9282a67a3f4-cc_ft_1536_piuwji.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643921530/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3366455/ef2d01197f172d65b9b0d469cbe83c9a-cc_ft_768_bht9ry.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643921530/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3366455/d7fd349741a842167439fe092acc93de-cc_ft_768_ebrxjh.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643921502/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3366455/653ad1b1d9b17201cb92f3359e2c8731-cc_ft_768_gi3vhn.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643921502/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3366455/ee2395f31861c74abef81441b8fe7ec6-cc_ft_768_g7gl7z.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643921502/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3366455/c57e7e2a9005c8ee0d3fa4821e49eccb-cc_ft_768_uvmonp.webp',
     
  ],
  tourUrl:'https://my.matterport.com/show/?m=SE6kjCs2D7Z&play=1',
  location:'Flushing',
  description:`Brand new high floor unit at the most luxurious condo complex in the heart of Flushing. 3 Bedrooms 3 Full Bath, Walk in closets, Large balconies with city views, 9ft Celings, Toto Washlet Toilet, Insinkerator, Wine Storage. Super sunny and bright. Open Kitchen with Island, Washer/dryer in unit. All top of the line material, Italian tiles and cabinets, Germany appliances, Oak floors, LED lighting, central AC. 24 hr doorman. Indoor saltwater swimming pool/fitness center/garden/lounge/hot tub/salt room/BBQ/tennis court/playground/1.5 Acre Garden, Library, Business Center/ Clubhouse Kitchen, Lounge. Development also includes Movie Theater, Shopping Mall, Food Court and Hotel.  Underground parking garage with direct access into the building.`,
  floorPlan:[
    
 ],
 lng: -73.83343467329762,
 lat:40.759783638027706,
 tax:1322,
 insurance:661,
 hoa:1835,
 year:'2020',
 zipCode:11354,
 mls:'3366455'
},
{
  address:'61-09 39th Ave APT M6, Woodside, NY 11377',
  phone:'3478371813',
  bedroom:'2',
  bathroom:'1',
  area:'985',
  price:'758800',
  garage:'',
  images:[
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643922362/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3365338/b556dbceae385ee8cfbf517ee78ebc81-cc_ft_768_xafqpq.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643922362/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3365338/e2b5f237aa64e99f9d502117252215bb-cc_ft_768_nsnejr.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643922362/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3365338/0acdaaeb1f467d5da453d0fc3b4ee417-cc_ft_768_cqu9wa.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643922362/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3365338/5ea64f94d63ac0111d8a90293c997c5b-cc_ft_768_fw7zpx.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643922362/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3365338/1054cb2a147c63abbb2f5955382816a3-cc_ft_768_ocohca.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643922362/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3365338/75bd4fd7e2c3cfb515c2fe90f8ad31d6-cc_ft_768_p0fwkc.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643922362/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3365338/3edfc0fe00e8617ed356aba5b8ac08fa-cc_ft_768_hydvlr.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643922362/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3365338/3ba9559126efed6aee2f95da6e3b3b24-cc_ft_768_xcpmyd.webp',
    
     
  ],
  tourUrl:'https://my.matterport.com/show/?m=eR1r87nSwwY&play=1',
  location:'Queens',
  description:`Modern and Sophisticated 2 Bedrooms Condo In Prime Location of Woodside. Everything Has Been Renovated From Top To Bottom. Hardwood floors, High ceiling, Stainless Appliances. Spacious,Natural Sun-filled Living-room and Good sized Bedrooms. One block to Express 7 Train, Lirr. Surrounded by Cafe, Restaurants and Shops. Good for Manhattan commuters. See 3D Virtual tour !`,
  floorPlan:[
    
 ],
 lng: -73.9021004739141,
 lat:40.747399941694695,
 tax:531,
 insurance:266,
 hoa:406,
 year:'',
 zipCode:11377,
 mls:'3365338'
},

{
  address:'6750 Thornton Pl APT 6M',
  phone:'3478371813',
  bedroom:'2',
  bathroom:'2',
  area:'990',
  price:'485000',
  garage:'',
  images:[
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643923411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/forest%20hill/b2c8c510b34d9b46873b1b3e63b9d445-cc_ft_768_yrfqsx.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643923411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/forest%20hill/77539cf0ecff36f6aee13f21874bf628-cc_ft_1536_k7lpa7.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643923411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/forest%20hill/7f130aa070a81b7b8a89fa3248882f47-cc_ft_768_opdsq9.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643923411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/forest%20hill/45d20ec3216763b29ee587ca70f3c65d-cc_ft_768_czpx3u.webp',
    
  ],
  tourUrl:'https://my.matterport.com/show/?m=Q94X7A1PEvy&play=1&brand=0',
  location:'Flushing',
  description:`Welcome to Forest Hills.Our newest listing is this gorgeous two-bedroom, 1.5 bath corner apartment on the top floor is spacious and bright from its North and East exposures. Among its many features are amazing light and open views, a very spacious living room, two large bedrooms, recently renovated bathrooms and kitchen, a very large entry foyer / dining alcove, lots of closets, and an East-facing balcony perfect for your morning cup of coffee and the newspaper.    *** Best of all, this apartment is one of only 12 apartments (out of the 112) in the building allowed to have a dishwasher or a washer/dryer in the kitchen. It currently has a dishwasher, which may be replaced with a w/d by the new owners, if they so choose. ***    Thornton Place Owner's Inc. is a well-run, well-maintained coop in the heart of Forest Hills. Maintenance includes heat, hot water and cooking gas.    Thornton Place Owner's Inc. has a live-in superintendent, indoor parking (currently a wait list), a renovated entrance & lobby, central laundry room, storage / bike rooms (on the lobby level), and a gorgeous, fenced-in courtyard for its residents. Guarantors, co-purchasing and pied-a-terre allowed. The coop is heated by natural gas.    Conveniently located just about equidistant between the 67th Ave (E,M.R trains) and 71st St / Continental Ave (E,F,M.R trains) subway stops, Forest Hills is less than 30 minutes to Manhattan. Nearby you will find ample shopping, being surrounded by Austin St and Yellowstone, Woodhaven and Queens Blvds, and a lovely, quiet neighborhood with mostly one and two family homes and a few other six-story buildings like this one.    ***Notes***  The sale of this apartment requires board approval. 80% financing is allowed. Monthly Maintenance is $1,270.43. There is a debt to income ratio requirement that must be met.    Sorry, no pets.`,
  floorPlan:[
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1643923411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/forest%20hill/1fd7c992a85d7e9437b6196f4fb2af6b-cc_ft_768_nuew8u.webp'
 ],
 lng: -73.85711746042077,
 lat:40.72039285057063,
 tax:'',
 insurance:170,
 hoa:1499,
 year:'1963',
 zipCode:11375,
 mls:'forest hill'
},

{
  address:'82-01 Britton Ave #5-N',
  phone:'3478371813',
  bedroom:'3',
  bathroom:'2',
  area:'1095',
  price:'705000',
  garage:'',
  images:[
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643924285/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3351056/a5d1362ca4e6f2fca31a093c78543e77-cc_ft_1536_k9z95h.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643924285/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3351056/8292f40e8ba0432b7f72caafcf4f9aae-cc_ft_768_hxxepp.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643924285/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3351056/1f89b5b5a09d18f50c7abb2a427b198d-cc_ft_768_vvvxdw.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643924285/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3351056/a3cfc689219d0890d03bd2e5395dd3e2-cc_ft_768_goxfu5.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643924285/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3351056/06a488b0291ed95c21e9a708a353d4a8-cc_ft_768_sb757n.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643924285/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3351056/fed9cf3a5f7c6912ed943b35006db6ad-cc_ft_768_loya0h.webp'
    
  ],
  tourUrl:'https://my.matterport.com/show/?m=icma8xCtqDe&play=1',
  location:'Elmhurst',
  description:``,
  floorPlan:[
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1643923411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/forest%20hill/1fd7c992a85d7e9437b6196f4fb2af6b-cc_ft_768_nuew8u.webp'
 ],
 lng: -73.88273063158475,
 lat:40.74327502275253,
 tax:'494',
 insurance:247,
 hoa:550,
 year:'1949',
 zipCode:11373,
 mls:'3351056'
},


{
  address:'2881 Nostrand Ave #C1',
  phone:'3478371813',
  bedroom:'2',
  bathroom:'2',
  area:'1037',
  price:'575000',
  garage:'',
  images:[
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643924850/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/2881%20Nostrand%20Ave/c77b5a7e095d49cc45a1be157187a6e6-cc_ft_768_nonrft.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643924850/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/2881%20Nostrand%20Ave/714c85cdd8fb95555a6db90f156757dc-cc_ft_768_vvc6tv.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643924850/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/2881%20Nostrand%20Ave/32d16c7058f4351a81e2fabf61a194aa-cc_ft_768_ubvcaw.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643924850/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/2881%20Nostrand%20Ave/1e3c4df95b3d935682fa70b677a233b1-cc_ft_768_bq5x06.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643924850/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/2881%20Nostrand%20Ave/a4fce4eac7237dd74f0a703904bb16c1-cc_ft_1536_qbug6s.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643924850/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/2881%20Nostrand%20Ave/84ecf4ec2d2e0310ee506f7333898885-cc_ft_768_tpuyla.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643924850/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/2881%20Nostrand%20Ave/6808a8b454f830869c9aca6eeccaad62-cc_ft_768_ooagrf.webp',
    
    
  ],
  tourUrl:'https://www.zillow.com/b/2881-nostrand-ave-brooklyn-ny-97dD3s/#pano-amenity-0',
  location:'Brooklyn',
  description:`Experience the finest luxury living with a 15 YEAR 421A TAX ABATEMENT! Situated in a modern new constructed condominium in Marine Park featuring modern and spacious 1 and 2 units with unique layout designed to feature a large open living/dining area, spacious bedrooms, generous closet space and large bathrooms with modern fixtures. The large kitchens featuring separate island and premium stainless steel appliances are equipped with two-tone custom kitchen cabinets with Cesar stone counter tops. Unique features of this beautiful home include wide plank hardwood floors, high ceilings with recessed lighting, washer/dryer hookups, spacious living rooms and bedrooms with walk-in and/or double closets. The buildings features include a furnished lobby with reception area, roof-top outdoor with city sky-line views and 421a 15 tax abatement, available storage units and secured parking ($50,000). The buildings steel, brick and block structure acts as natural sound proofing. Transportation, Kings Highway shopping corridor and Marine park is a short distance away. Contact us to find out more details about this amazing condominium. Parking is available to purchase.`,
  floorPlan:[
   
 ],
 lng: -73.9441320604244,
 lat:40.61402780087057,
 tax:311,
 insurance:201,
 hoa:300,
 year:'2019',
 zipCode:11229,
 mls:''
},



{
  address:'1502 W 1st St #3C',
  phone:'3478371813',
  bedroom:'2',
  bathroom:'2',
  area:'958',
  price:'738000',
  garage:'',
  images:[
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1643925395/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/11024829/fe6873849228f34eca57420071944c37-cc_ft_1536_uemdla.webp',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1643925394/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/11024829/b6d2db9f076e0bef5a99e8d80a7d282c-cc_ft_768_ntk0br.webp',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1643925394/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/11024829/5406af05e611579ce8ce8bd2cca69387-cc_ft_768_jtpt9f.webp',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1643925394/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/11024829/5e6e908c07ae6ad3630b1bce5e5264bd-cc_ft_768_dszcae.webp',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1643925394/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/11024829/2021ef78369b7a3ae9915f3ec861ed32-cc_ft_768_pv8i1k.webp',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1643925394/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/11024829/4700adff152a09230125eb6e76639a8a-cc_ft_768_c6icvr.webp',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1643925394/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/11024829/3098d9e6638ea2e6206ab41f6eae414e-cc_ft_768_l41mxk.webp',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1643925394/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/11024829/4d1523051f89822c85602c68f2ca879e-cc_ft_768_dbmvtl.webp',
  ],
  tourUrl:'https://www.zillow.com/homedetails/1502-W-1st-St-3C-Brooklyn-NY-11204/320255377_zpid/?mmlb=t,0',
  location:'Brooklyn',
  description:`Live in a beautiful new brick and glass condominium building and enjoy sleek finishes, soaring 9-foot tall ceilings, and expensive living spaces in this east-facing, split two-bedroom, two-bath condominium with a private balcony. The open floor plan is intelligently designed with split bedrooms, an oversized living room, and a dining area, which leads out to a private balcony - perfect for enjoying a morning coffee or afternoon cocktails. The kitchen, which is adorned in custom white lacquer cabinets, Caesar stone countertops, and backsplash, is open to the living room and is outfitted with a Bosch appliance package which includes a gas range, microwave, and dishwasher. The fully integrated refrigerator with an ice maker and water filter from Blomberg completes the sleek kitchen. The desirable split bedroom layout features a spacious owner's suite with a generous walk-in closet and an en suite bathroom with a walk-in shower. The second bedroom is placed across from the well-appointed guest bath and features a walk-in closet of its own. Additional must-have apartment features include a washer/dryer hook-up, herringbone hardwood floors throughout, Grohe & Toto bathroom fixtures, and a Nest-controlled HVAC system for year-round temperature comfort. The Art House is a perfect mix of only 35 condominium residences, located in the epicenter of convenience, combined with 24-hour virtual doorman service, dramatic double-height lobby, beautifully landscaped rooftop terrace wet bar, grill station, and panoramic city views, private roof cabanas, state-of-the-art rooftop fitness center with skyline views, curated canvas art in all common areas, and convenient package room make this a world-class building. Enjoy the benefits of this rare property with 17 more years of tax abatement (until 2038). Steps from every imaginable subway line F train (0.16 miles) and the N train (.31 miles) you can be whisked to your destination within minutes of leaving your home.`,
  floorPlan:[
   
 ],
 lng: -73.97557781624823,
 lat:40.6101555869804,
 tax:400,
 insurance:258,
 hoa:349,
 year:'2018',
 zipCode:11204,
 mls:'11024829'
},


{
  address:'1238 63rd St #D215',
  phone:'3478371813',
  bedroom:'2',
  bathroom:'2',
  area:'988',
  price:'747135',
  garage:'',
  images:[
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643925915/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/1238%2063Rd/21493831604518a634b5a2afecae59bd-cc_ft_768_pvmaf6.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643925915/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/1238%2063Rd/57c82b8d0638a8b988ff40fbddd86eda-cc_ft_768_qn42tl.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643925915/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/1238%2063Rd/706d8f0dcf04072a0ce86287a43f5cdf-cc_ft_768_i20kqe.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643925915/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/1238%2063Rd/c98838b2720601595e3e3a009bf1e2ad-cc_ft_768_u4affk.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643925915/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/1238%2063Rd/69b49969ee15886a3b902cad2f2721bd-cc_ft_768_blqche.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1643925915/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/1238%2063Rd/6fe03a01cbd0359df2b5fa67756f8d45-cc_ft_768_byazgm.webp',
    
  ],
  tourUrl:'https://www.zillow.com/homedetails/1238-63rd-St-D215-Brooklyn-NY-11219/2068382892_zpid/?mmlb=t,0',
  location:'Brooklyn',
  description:`Located in the heart of the culturally rich, historic community of Dyker Heights, Dyker Gardens is south Brooklyn's newest and most elegant residential condominium development offering everything you desire in the place you call home with more amenities than any other condominium development in South Brooklyn.
  Everyday Glamour
  Dyker Gardens offers smartly designed living spaces that brighten your life with abundant natural light. Each residence offers carefully selected finishes in kitchen and bathroom. Customized kitchen cabinets, gracefully designed backsplash and countertop give an airy aesthetic to the cooking space. The bathroom is defined by clean modern lines. The hand selected tiles create a calm vibe where you can enjoy a relaxing shower. The bathroom also comes with a LED fog-free mirror with adjustable brightness.
  Home Features with Thoughtful Design
  *	Balcony or terrace in select units
  *	Smart Mitsubishi-Trane standalone heating
  and cooling system
  *	Secure Aiphone touch screen video
  intercom system
  *	LG washer and dryer in every residence
  *	Samsung stainless steel electric range
  and oven with storage drawer
  *	Pacific Range Hoods 3 speed touch control vented kitchen exhaust hood (850 CFM)
  *	LG 3-door refrigerator
  *	Samsung dishwasher with integrated digital control
  *	Walk-in shower bathrooms
  *	Kohler dual flush in-wall tank and wall-hung toilet
  *	Elegant bathroom fixtures by Grohe
  *	Electric floor heating system in all bathrooms
  
  State of the Art Condominium Amenities
  40,000 Sq Ft Rooftop Landscaped Garden with:
  *	Basketball court
  *	Children's Playground
  *	Outdoor 2 lane running track
  *	Picnic Area
  *	Sunbathing Area
  Indoor Amenities
  *	Staffed with 24-hour concierge and live-in resident manager, the building has 2 residential lobbies with convenient access to 63rd and 64th streets. 
  
  *	A residents-only lounge is an extension of your living room--a unique and inviting social space for you and your guests. Whether you're looking for a space for an intimate chat or a lively gathering with friends, the residents lounge provides a social space without sacrificing privacy.
  
  *	In the Fitness Center focus on your body and mind and achieve your fitness goal without leaving home. The state-of-the-art fitness center is equipped with treadmills, ellipticals, exercise bikes and a strength training area, as well as a dedicated area for yoga lovers.`,
  floorPlan:[
   
 ],
 lng:  -74.00174747391814,
 lat:40.627983875973875,
 tax:405,
 insurance:261,
 hoa:560,
 year:'2021',
 zipCode:11219,
 mls:''
},


{
  address:'100 Hilton Ave PH 1',
  phone:'3478371813',
  bedroom:'3',
  bathroom:'3',
  area:'',
  price:'2880000',
  garage:'',
  images:[
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643928608/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/garden%20city/0580233c2d3f7f883d2cbb2ce5c8c6b8-cc_ft_1536_kvc8wg.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643928608/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/garden%20city/bcdea5283f5e88f88fd1f5df54f6b579-cc_ft_768_pt0cai.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643928608/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/garden%20city/160b768c592452e8949ceb7510b1b8e0-cc_ft_768_l4nzvj.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643928608/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/garden%20city/6e141041884eaf54267c95ad7cb0c0db-cc_ft_768_x4yuqp.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643928607/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/garden%20city/2f22dd78f60487c7e1f0ed315e092e3d-cc_ft_768_atygoc.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643928607/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/garden%20city/69831651903d603b268c1d00fd347776-cc_ft_768_qbf6ye.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643928607/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/garden%20city/8958296e30a90decc8a8cf0f8e5e1ac5-cc_ft_768_wpm8tb.webp',
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643928607/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/garden%20city/337a4754f0965abfe150a9a49d2ea36d-cc_ft_768_apot8i.webp',
  ],
  tourUrl:'https://my.matterport.com/show/?m=4PHnP1Xru15&play=1&brand=0',
  location:'Garden City',
  description:`Beautifully renovated penthouse offering 1st flr bdrm & over 900 sqft of outdoor living space. Expect all the amenities of a luxury building incl. 24-hour concierge & doorman, fabulous views, newly renovated clubhouse, gym & spa-like pool. Offering 3 bdrms & 3 full bths, this stunning respite leaves nothing to be desired. The elegant foyer welcomes you w/impressive details for a phenomenal first impression. Featuring a custom designed kit w/granite countertop & high end appliances. Spacious mster bdrm w/wood-burning fireplace, 2 large closets offering custom built-ins & a sanctuary mster bth. This exquisite penthouse boasts more than 2500 sqft of living space, hrdwd floors, pvt laundry & plenty of storage. The spacious open living rm/dining rm, inclusive of a wood-burning fireplace & sunroom leading out to an expansive outdoor terrace, makes it great for both relaxing or entertaining! The Wyndham also has an outdoor patio w/BBQ, walking path, idyllic pond w/beautiful gardens & seating.`,
  floorPlan:[
   
 ],
 lng:  -73.64022677391482,
 lat:40.72590883271429,
 tax:5304,
 insurance:1008,
 hoa:2368,
 year:'1989',
 zipCode:11530,
 mls:''
},


]

const getApartment = ()=>{
    return DATA;
}

export default getApartment;