import React,{useRef,useState,useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {Link} from 'react-router-dom';
// @material-ui/icons
import Carousel from '../../../components/Carousel/index'
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import {Typography,Paper,Button} from '@material-ui/core'
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import ReactPannellum from "react-pannellum";

const useStyles = makeStyles(styles);

const BUY ='https://res.cloudinary.com/dlapk94rx/image/upload/v1630527749/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/test_tw1lyu.jpg'

export default function ProductSection({language,grid}) {
  const classes = useStyles();
  const config = {
    autoRotate: -5,
    autoLoad: true,
    showControls:false,
    uiText:{
      loadButtonLabel: "Click to<br>Load<br>Panorama",
    loadingLabel: "Loading...",
    bylineLabel: "360",
    noPanoramaError: "No panorama image was specified.",
    fileAccessError: "The file %s could not be accessed.",
    malformedURLError: "There is something wrong with the panorama URL.",
    iOS8WebGLError: "Due to iOS 8's broken WebGL implementation, only progressive encoded JPEGs work for your device (this panorama uses standard encoding).",
    genericWebGLError: "Your browser does not have the necessary WebGL support to display this panorama.",
    textureSizeError:  "This panorama is too big for your device! It's %spx wide, but your device only supports images up to %spx wide. Try another device. (If you're the author, try scaling down the image.)",
    unknownError: "Unknown error. Check developer console."
    }
  };

  const STYLE ={width: '250px',height:'250px',boxShadow:' 0px 0px 17px 1px #000',borderRadius:'50%',zIndex:'10'}

  const flex ={display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}
  const TITLE = { position:'absolute',top:'50%',left:'30%',fontWeight:'bold',zIndex:'100'}

  const LINK ={textDecoration:'none',color:'#fff',zIndex:'100'}

  const FLEX ={position:'relative',}
  const mid = {borderRadius:'50%',position:'relative'}

  const EVEN = { display:'flex',justifyContent:'space-between',alignItems:'center',position:'relative'}

  const BACKGROUND = {width: '330px',height:'330px',borderRadius:'50%',position:'absolute',zIndex:'-1'}

  const line ={width: '380px',height:'380px',borderRadius:'50%',position:'absolute',zIndex:'-1'}
  const mar={marginTop:'40px'}

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };

  const GLASS = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1632417403/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/IMG_4227_sv2xhe.png'

  const LINE = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1632766498/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/360-removebg-preview_tfhi2m.png'
  // const BACKGROUND ={backgroundImage:'https://res.cloudinary.com/dlapk94rx/image/upload/v1632417403/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/IMG_4227_sv2xhe.png',width:'270px',height:'auto',zIndex:'-1',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',position:'relative', }

  const Images = 
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1631893827/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/PIC_20201118_103423_20201118165104_rqeevw.jpg'
   
    const [width,setWidth] = useState(0)
  const target = useRef(null)
  useEffect(()=>{
    const temp = target.current.clientWidth
    setWidth(temp)
  },[])
  return (
    <div className={classes.section}>
          <div ref={target} style={FLEX}>

          <Carousel width={width} style={{width: '100%'}}/>
          </div>


      <GridContainer justify="center">
    
        <GridItem xs={12} sm={12} md={8}>
          <Typography variant='h2' className={classes.title}>{language.home.title2}</Typography>
          <Typography variant='subtitle1' className={classes.description}>
            {language.home.description2}
          </Typography>
        </GridItem>
      </GridContainer>


  
        <div style={grid ? EVEN :flex}>
      <div style={mar}>
      <div style={mid}>
        <ReactPannellum
          id="12"
          sceneId="firstScene"
          imageSource={Images}
          config={config}
          style={STYLE} 
        /> 
        {/* <img style={BACKGROUND} src={GLASS} alt="" /> */}
        {/* <img style={line} src={LINE} alt="" /> */}
          <Link style={LINK} to='/realty'>
        <Typography style={TITLE} variant='h3' color='initial'>{language.home.category.buy}</Typography>
        </Link>
        </div>
      </div>
        
      <div style={mar}>
      <div  style={mid}>
        <ReactPannellum
          id="13"
          sceneId="firstScene"
          imageSource={BUY}
          config={config}
          style={STYLE}
          
        /> 
        <Link style={LINK} to='/rent'>
        <Typography style={TITLE} variant='h3' color='initial'>{language.home.category.rent}</Typography>
       </Link>
        </div>
      </div>
        
       

       </div>
     

{/* 
        <GridItem xs={12} sm={12} md={4}>

        <ReactPannellum
          id="7"
          sceneId="firstScene"
          imageSource={BUY}
          config={config}
          style={STYLE}
          
        /> 
        <Link style={LINK} to='/commercial'>
        <Typography align='center' style={TITLE} variant='h3' color='initial'>{language.home.category.business}</Typography>
        </Link>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>

        <ReactPannellum
          id="8"
          sceneId="firstScene"
          imageSource={BUY}
          config={config}
          style={STYLE}
          
        /> 
        <Link style={LINK} to='/'>
        <Typography  style={TITLE} variant='h3' color='initial'>{language.home.category.new}</Typography>
       </Link>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>

        <ReactPannellum
          id="9"
          sceneId="firstScene"
          imageSource={BUY}
          config={config}
          style={STYLE}
          
        /> 
        <Link style={LINK} to='/'>
        <Typography style={TITLE} variant='h3' color='initial'>{language.home.category.law}</Typography>
       </Link>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>

        <ReactPannellum
          id="10"
          sceneId="firstScene"
          imageSource={BUY}
          config={config}
          style={STYLE}
          
        /> 
        <Link style={LINK} to='/'>
        <Typography style={TITLE} variant='h3' color='initial'>{language.home.category.land}</Typography>
       </Link>
        </GridItem>
        

         */}

      <div>
        {/* <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Free Chat"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={Chat}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Verified Users"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Fingerprint"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={Fingerprint}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer> */}
      </div>
    </div>
  );
}
