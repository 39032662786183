import React from 'react'
import NavBar from '../../components/NavBar/index'

export default function Decoration({handleChangeLanguage,language}) {
    return (
        <div>
             <NavBar handleChangeLanguage={handleChangeLanguage} language={language}/>
            <h2>Decoration</h2>
        </div>
    )
}

