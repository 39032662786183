import React ,{useState,useRef}from 'react'
import {List,ListItem,Menu,MenuItem,Typography} from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import {Link} from 'react-router-dom'
import styles from '../../assets/jss/material-kit-react/views/componentsSections/navbarsStyle'
import LanguageIcon from '@material-ui/icons/Language';
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import Header from "components/Header/Header.js";
import Button from "components/CustomButtons/Button.js";
// import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
const useStyles = makeStyles(styles);
const ICON = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1630437484/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/icons/global_cbma6v.png';

const LOGO = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1644515163/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/logo%E6%9C%80%E6%9C%80%E7%BB%882_oivdia.png'

export default function NavBar({fixed,handleChangeLanguage,language}) {

    const classes = useStyles();

    const [isLanguage,setIsLanguage] = useState(false);
    const ref = useRef(null);

    const btn = useRef(null)
    const [open,setOpen] = useState(false);

 

    const handleChinese = ()=>{

        handleChangeLanguage(0)
        setIsLanguage(false);

    }

    const handleEnglish=()=>{
        handleChangeLanguage(1)
        setIsLanguage(false);
    }
  

    return (
       
        <div id="navbar" className={classes.navbar}>
        <Header
        fixed={fixed}   
        color={fixed?"transparent":'info' }  
        changeColorOnScroll={{
          height: fixed ? 10:0,
          color: "info",
        }}
        leftLinks={
            <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                  <Button
                    className={classes.navLink}
                    color="transparent"
                  >
                      <Link className={classes.h2} to='/'> 
                        线上地产
                      </Link>
                      </Button>
                  </ListItem>
            </List>
        }
        brand={"https://res.cloudinary.com/dlapk94rx/image/upload/v1645026985/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/40x80_ndci0p.png" }
        // brand={language.h1}

            rightLinks={
              <List className={classes.list}>

                  {/* <ListItem className={classes.listItem}>
                  <Button 
                    ref={btn}
                    aria-controls="basic-menu"
                    aria-haspopup="true"
                    className={classes.navLink}
                    color="transparent"
                    onClick={()=>setOpen(true)}
                    >
                        {language.navbar[0].name}                    
                    </Button>
                    <Menu
                        open={open}
                        anchorEl={btn.current}
                        onClose={() => setOpen(false)}

                      
                      
                        PaperProps={{
                        sx: { width: 200, maxWidth: '100%',marginTop:'30px' }
                        }}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    >
                        <MenuItem>
                            <Link to='/component'>测试1</Link>
                        </MenuItem>

                        <MenuItem>
                        <Link>测试2</Link>

                        </MenuItem>

                    </Menu>
                  </ListItem>

                         */}
                
                <ListItem className={classes.listItem}>
                <CustomDropdown
                      buttonText={language.navbar[0].name}
                      dropdownHeader={language.navbar[0].name}
                      buttonProps={{
                        className: classes.navLink,
                        color: "transparent",
                      }}
                      dropdownList={[
                        <Link className={classes.down} to='/'>经纪1</Link>,
                        { divider: true },
                        <Link className={classes.down} to='/'>经纪2</Link>,
                        { divider: true },
                        <Link className={classes.down} to='/'>经纪3</Link>,
                        { divider: true },
                        <Link className={classes.down} to='/component'>经纪4</Link>,

                      ]}
                    />
                </ListItem>

                <ListItem className={classes.listItem}>
                 
                <CustomDropdown
                      buttonText={language.navbar[1].name}
                      dropdownHeader={language.navbar[1].name}
                      buttonProps={{
                        className: classes.navLink,
                        color: "transparent",
                      }}
                      dropdownList={[
                        <Link className={classes.down} to='/sell/self'>自住房</Link>,
                        // { divider: true },
                        <Link className={classes.down} to='/sell/commercial'>商业</Link>,
                      ]}
                    />
                </ListItem>
                <ListItem className={classes.listItem}>
                <CustomDropdown
                      buttonText={language.navbar[2].name}
                      dropdownHeader={language.navbar[2].name}
                      buttonProps={{
                        className: classes.navLink,
                        color: "transparent",
                      }}
                      dropdownList={[
                        <Link className={classes.down} to='/'>咨询我们</Link>,
                        <Link className={classes.down} to='/'>我要贷款</Link>,
                        <Link className={classes.down} to='/'>贷款说明</Link>,
                      ]}
                    />
                </ListItem>

                <ListItem className={classes.listItem}>
                <CustomDropdown
                      buttonText={language.navbar[3].name}
                      dropdownHeader={language.navbar[3].name}
                      buttonProps={{
                        className: classes.navLink,
                        color: "transparent",
                      }}
                      dropdownList={[
                        <Link className={classes.down} to='/'>测试1</Link>,
                        { divider: true },
                        <Link className={classes.down} to='/'>测试2</Link>,
                        { divider: true },
                        <Link className={classes.down} to='/'>测试3</Link>,
                        { divider: true },
                        <Link className={classes.down} to='/component'>测试4</Link>,

                      ]}
                    />
                </ListItem>
                
                {/* <ListItem className={classes.listItem}>
                <Link className={classes.aTag} to='/login'>
                  <Button
                    className={classes.registerNavLink}
                    color="rose"
                    round
                  >
                       {language.navbar[3].name}
                  </Button>
                  </Link>
                </ListItem> */}

                {/* <ListItem className={classes.listItem}>
                    <Button 
                    ref={ref}
                    className={classes.navLink}
                    color="transparent"
                    onClick={()=>setIsLanguage(true)}
                    >
                        <LanguageIcon style={{width:25}}/>                        
                    </Button>
                    <Menu
                        open={isLanguage}
                        anchorEl={ref.current}
                        onClose={() => setIsLanguage(false)}
                        PaperProps={{
                        sx: { width: 200, maxWidth: '100%' }
                        }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <MenuItem>
                            <Button onClick={()=>handleChinese()} variant='contained' color='primary'>
                                 &nbsp; &nbsp;    中文  &nbsp; &nbsp; 
                            </Button>
                        </MenuItem>

                        <MenuItem>
                            <Button onClick={()=>handleEnglish()}  variant='contained' color='primary'>
                                English
                            </Button>
                        </MenuItem>

                    </Menu>
                </ListItem> */}

              </List>
            }
          />
      </div>
    )
}
