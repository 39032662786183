import React from 'react'
import NavBar from '../../components/NavBar/index'

export default function RegisterPage({handleChangeLanguage,language}) {
    return (
        <div>
             <NavBar handleChangeLanguage={handleChangeLanguage} language={language}/>

            <h2>I am register page</h2>
        </div>
    )
}
