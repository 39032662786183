const DATA = [
{
    address:'74 Beverly Rd, Great Neck',
    phone:'3478371813',
    bedroom:'8',
    bathroom:'7',
    area:'11565',
    price:'3688888',
    garage:'6',
    images:[
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1643992803/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/11042763/3a63a04ec093b2629849d6ba920f6760-cc_ft_1536_gqgpir.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1643992802/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/11042763/e86d53f6d50a31158f75090020e1aab6-cc_ft_768_mhdki8.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1643992802/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/11042763/3408b5f8210604c20f58a8c00f82c416-cc_ft_768_qvajhl.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1643992802/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/11042763/2e097ec84da0bd6f46d55fa8c2e73095-cc_ft_768_eiy3yi.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1643992802/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/11042763/30903519385781be6c4ae8250afe0dc7-cc_ft_768_umnyhv.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1643992802/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/11042763/2a5ba096cbe95bfe806ac110c3be3264-cc_ft_768_utyfjv.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1643992802/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/11042763/0d051cbf8dea88e78677701d9b63f32e-cc_ft_768_wm8fp3.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1643992802/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/11042763/c56f0f48d7a701bfc6436b57b4ab2041-cc_ft_768_s0czy2.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1643992802/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/11042763/73d3bc082ef64db2f114d3e2387fdd00-cc_ft_768_ttxitp.webp',
    ],
    tourUrl:'https://my.matterport.com/show/?m=RYUEvVfvUH2&play=1',
    location:'Great Neck',
    description:`Open House 2.6.22 at 12pm-2pm by Gold Homes. This Kensington mansion that has been fully renovated, offering a showcase of amenities and beautiful architectural elements. The home has 8,700 SF of living space on three floors. Step into a grand foyer with its soaring double height entry, carved molding staircase with exceptional details, and chandeliers. A Double Gourmet Kitchen featuring 2 sinks, dishwashers,and refrigerators, with high-end appliances by Meile, and Subzero breakfast room overlooking the patio, with wall to ceiling windows. Also on the first floor a pantry area, laundry room, living room and dining room. On the 2nd floor a Spacious master suite with spa master bathroom. and 5 other bedrooms. Fully finished basement with large entertaining rooms, gym, bar area, and 2 additional bedrooms. A high energy efficient central heating and cooling system was installed with 5 zones. Residents of the Village of Kensington enjoy membership to exclusive pool club, as well as private police, and Parkwood Pool district. Baker Hill elementary school and optional choice of North or South middle and high school.`,
    floorPlan:[
     'https://res.cloudinary.com/dlapk94rx/image/upload/v1643992795/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/11042763/f6e5e2e6a58861a8a59077a367943209-cc_ft_768_euczls.webp'
   ],
   lng:-73.7154337732965,
   lat:40.793323351886,
   tax:6794,
   insurance:1291,
   hoa:0,
   year:'1920',
   zipCode:11021,
   mls:'11042763'
  },


  {
    address:'10 Ipswich Ave APT 2J, Great Neck',
    phone:'3478371813',
    bedroom:'1',
    bathroom:'2',
    area:'1000',
    price:'379000',
    garage:'',
    images:[
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1643993529/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3369424/54086a7282119a62a8f002f5a0e1a36e-cc_ft_768_aeioui.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1643993529/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3369424/866804e303008aa90ef1ac6ee4d53365-cc_ft_768_nc1dmy.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1643993529/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3369424/ea785d43687dd001e958ae76c2aba326-cc_ft_768_cthvck.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1643993529/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3369424/229eee0b91e3b07b0eeae74afa5d5296-cc_ft_768_azzssp.webp',
    ],
    tourUrl:'',
    location:'Great Neck',
    description:``,
    floorPlan:[
     
   ],
   lng:-73.7341219732968,
   lat:40.78413868094132,
   tax:0,
   insurance:133,
   hoa:957,
   year:'1971',
   zipCode:11021,
   mls:'3369424'
  },

  {
    address:'1 Kensington Gate PENTHOUSE 6, Great Neck',
    phone:'3478371813',
    bedroom:'2',
    bathroom:'2',
    area:'1500',
    price:'619000',
    garage:'',
    images:[
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1643993922/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3354384/d489cd410dac977c54870659a7301937-cc_ft_768_ndqjph.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1643993922/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3354384/96f5e75f25588016db7b9b500217b3e8-cc_ft_768_curvab.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1643993922/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3354384/318dfb0fec90427345df21eac792884d-cc_ft_768_w4bzb0.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1643993922/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3354384/5b60572c36d5b623bf6b5364804f1a7c-cc_ft_768_jeajwl.webp'
    ],
    tourUrl:'https://www.vr-360-tour.com/e/5fABfG6wuWM/e?fadetransition=true&hide_background_audio=true&hide_e3play=true&hide_logo=true&hide_nadir=true&hidelive=false&horizontal_transitions=true&share_button=true',
    location:'Great Neck',
    description:`"PREPARE TO BE SPOILED!"  (w/ 24 HR Doorman, Garage Attendant and Super). Tranquil Garden Views In Your 1500 sq. ft.  Contemporary 2 BR, 2 BTHS, Eat-in Kitchen, Enclosed Terrace, Washer/Dryer Permitted In Each Apartment.   one block from Town, Railroad, Restaurants & Shops. MOVE IN READY.  Residents only, Inground Pool & Picnic/BBQ area, ***"MAINTENACE INCLUDEDS:  CAC, Electric, 24 hour Doorman & 24 hour Garage Attendant, Taxes, Pool, Exercised Room, Grassy Sitting & BBQ area, Etc.***"`,
    floorPlan:[
     
   ],
   lng:-73.7321804450772,
   lat:40.79270811795721,
   tax:0,
   insurance:217,
   hoa:2570,
   year:'1969',
   zipCode:11021,
   mls:'3354384'
  },

  {
    address:'57-17 246th Cres, Little Neck',
    phone:'3478371813',
    bedroom:'2',
    bathroom:'1',
    area:'',
    price:'368800',
    garage:'',
    images:[
       'https://res.cloudinary.com/dlapk94rx/image/upload/v1643994750/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3334871/894be6e56cf4fe9952ad4a9a4dcd3375-cc_ft_1536_korvuc.webp',
       'https://res.cloudinary.com/dlapk94rx/image/upload/v1643994750/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3334871/6645be50e9873abc46483b0f9454c279-cc_ft_768_sxo6g8.webp',
       'https://res.cloudinary.com/dlapk94rx/image/upload/v1643994750/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3334871/e3db64e488e4a5cb555bab35e5037d09-cc_ft_768_gnnud8.webp',
       'https://res.cloudinary.com/dlapk94rx/image/upload/v1643994750/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3334871/8ca4f0d150f01c463be75d475cddce35-cc_ft_768_vcecqa.webp'
    ],
    tourUrl:'https://kuula.co/share/collection/7k9RM?fs=1&vr=1&sd=1&initload=0&thumbs=-1&info=0&logo=0',
    location:'Little Neck',
    description:`Convenience meets suburban living in this apt located in deep courtyard, Mint 2bedroom lower-floor Garden style unit in the NE Queens section of Douglaston, just 1 mile from the Nassau border, providing you the best of both worlds! The sun shines through the spacious and airy unit, featuring 2 bedroom with separate formal dining room with new washer, dryer, kitchen with granite counters, stainless steel appliances. Development approved floating hardwood floors, All utilities included in the maintenance, you can move right in. Bonuses include a tranquil St, right across laundry room, 2 parking lots, onsite playgrounds including a spray park, FREE lot parking for up to 2 cars, onsite storage, easy access to major highways, public transportation, shopping, golf, parks, A-rated schools, and cats are welcome. This is a value you won't want to miss! 360 Degree Virtual Tour Available`,
    floorPlan:[
     
   ],
   lng:-73.73589960274899,
   lat:40.75829242225827,
   tax:0,
   insurance:129,
   hoa:1032,
   year:'1955',
   zipCode:11362,
   mls:'3334871'
  },


  {
    address:'69 Memorial Pl, Manhasset',
    phone:'3478371813',
    bedroom:'3',
    bathroom:'2',
    area:'1986',
    price:'925000',
    garage:'3',
    images:[
       'https://res.cloudinary.com/dlapk94rx/image/upload/v1644001673/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3344597/d6e907a74f0d6202327ab42d2b77544d-cc_ft_1536_h1xleh.webp',
       'https://res.cloudinary.com/dlapk94rx/image/upload/v1644001673/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3344597/ed46194b24dc0b8720e538b5a2235af1-cc_ft_768_fkxzh2.webp',
       'https://res.cloudinary.com/dlapk94rx/image/upload/v1644001672/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3344597/ef608f57ef8f71807d7dd97d6bb976dd-cc_ft_768_qb2xo6.webp'
    ],
    tourUrl:'https://www.zillow.com/homedetails/69-Memorial-Pl-Manhasset-NY-11030/31077187_zpid/?mmlb=p,0',
    location:'Manhasset',
    description:`Charming Colonial, featuring enclosed porch, Large Living Room, spacious formal dining room, updated eat-in kitchen, and bath. Upstairs offers 3 bedrooms and a full bath. Hardwood Floors Throughout. Large Walk Up Attic With Finished Rooms, Finished basement with Separate Entrance. Detached Garage. Large backyard with storage, the driveway has 3 parking spots, Lot Size 45X131. Close to Manhasset RR station/22 minutes to the city. Close to town, library, restaurants, and shopping. Express Bus to Flushing.`,
    floorPlan:[
     
   ],
   lng:-73.7013969600035,
   lat:40.795057725027775,
   tax:1704,
   insurance:324,
   hoa:0,
   year:'1913',
   zipCode:11030,
   mls:'3344597'
  },

  {
    address:'44 Heights Rd, Manhasset',
    phone:'3478371813',
    bedroom:'6',
    bathroom:'4',
    area:'',
    price:'3195000',
    garage:'2',
    images:[
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644002274/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3358565/4c2c6150578083f2b01b8667021b11ee-cc_ft_768_jufi6i.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644002274/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3358565/bea12d5774e55cb440b84f1a52cfe891-cc_ft_1536_fxjo8j.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644002274/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3358565/f967902b751b94e22172a9730da075d2-cc_ft_768_auldck.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644002274/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3358565/eb327a0b155d024533f53620cf590f13-cc_ft_768_i1kmqe.webp',
        
    ],
    tourUrl:'https://www.zillow.com/homedetails/44-Heights-Rd-Manhasset-NY-11030/31072526_zpid/?mmlb=p,0',
    location:'Manhasset',
    description:`This exceptional Colonial-style residence is located in the Incorporated Village of Plandome.  Situated on one of the most prestigious and sought-after streets, this meticulously maintained home is tucked away on the private, Heights Rd cul-de-sac and is set on a landscaped, flat, half-acre parcel.  Approximately 4,000 square feet and built in 1963 this home boasts a total of 12-rooms, including 6-bedrooms and 4-full baths and is being introduced to the marketplace for the first time.  This unique offering includes access to the Field and Marina and The Village Green and is conveniently located to Manhasset schools, shopping, transportation and is truly a very special opportunity!`,
    floorPlan:[
     
   ],
   lng:-73.69886195862017,
   lat:40.80345316831106,
   tax:5884,
   insurance:1118,
   hoa:0,
   year:'1963',
   zipCode:11030,
   mls:'3358565'
  },


  {
    address:'539 Manhasset Woods Rd, Manhasset',
    phone:'3478371813',
    bedroom:'5',
    bathroom:'6',
    area:'5398',
    price:'2095000',
    garage:'3',
    images:[
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1644004294/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3345977/e43ac8b630ed816c2e920976433cf7a8-cc_ft_768_gkrkf8.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1644004294/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3345977/14f4d888e193b6bf82437ab5563c34e6-cc_ft_768_ytovfv.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1644004293/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3345977/1fd9990fd322eb8d8113f3502556994d-cc_ft_768_xxwxph.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1644004294/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3345977/9eb60f8ac8334fd6c443d9a87ac0975d-cc_ft_768_rawful.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1644004293/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3345977/4c88e3efd2c6630b37c239497caa9909-cc_ft_768_kj15cz.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1644004293/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3345977/8908f0ecae723ef541bd2f18b7a98ef2-cc_ft_768_boss50.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1644004293/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3345977/ec9a2c310833bd1adc43179b8df27904-cc_ft_768_gswfdy.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1644004293/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3345977/7fc574ead29468648f19c72772728e5e-cc_ft_768_p20cko.webp',
      'https://res.cloudinary.com/dlapk94rx/image/upload/v1644004293/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3345977/94216fafda4343e9140e40e6e4c01665-cc_ft_768_ixpuuv.webp',
    
    ],
    tourUrl:'https://www.zillow.com/homedetails/539-Manhasset-Woods-Rd-Manhasset-NY-11030/31075597_zpid/?mmlb=p,0',
    location:'Manhasset',
    description:` Enjoy cathedral ceilings and open spaces throughout, three finished levels, main floor boasts a fantastic floor plan for today's living,. Spacious gourmet kitchen, informal dining/breakfast area, impressive sunken living room, sunroom, elegant dining room, private office, family room, and includes a sumptuous principal room with two baths. Through the grand entry foyer with fireplace a beautiful staircase rises to the second balcony landing and introduces two additional bedrooms and bath. House features five bedrooms and 5 and a half baths, 2 fireplaces, open terraces along with a finished basement and three car garage with circular driveway. All within close proximity to major highways LIRR, Americana shops and Country Clubs.`,
    floorPlan:[
     
   ],
   lng:-73.68824810279703,
   lat:40.809496548631124,
   tax:3858,
   insurance:733,
   hoa:0,
   year:'1928',
   zipCode:11030,
   mls:'3345977'
  },


  {
    address:'26 Center Dr, Syosset',
    phone:'3478371813',
    bedroom:'3',
    bathroom:'3',
    area:'',
    price:'879000',
    garage:'2',
    images:[
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644006339/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3371066/90ca2a8894ea72ad35d0c7483af4efb3-cc_ft_1536_trqx0g.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644006338/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3371066/e4983dc9065638a0bf82619b103db84e-cc_ft_768_gh0qfe.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644006338/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3371066/ed163dcd4151f3c928a518e79eef1c92-cc_ft_768_eyddpm.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644006339/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3371066/fc296dd4155cc687acf85c2782220c2f-cc_ft_768_gcvsao.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644006338/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3371066/9339fa51c95ed514e35f23e9ecdb668e-cc_ft_768_k1rlbp.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644006338/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3371066/3d0755b9f22207232e94f0072658b24b-cc_ft_768_hxn14e.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644006338/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3371066/7667341caab9e9a505736c72d2a4a30e-cc_ft_768_u2zliq.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644006338/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3371066/5c773575dcb93ff2f11f8b751c9b37ee-cc_ft_768_vvfjtl.webp',
    ],
    tourUrl:'https://my.matterport.com/show/?m=dMEPK1NSj7e&play=1',
    location:'Syosset',
    description:`Perfect Mid Block Location! This spacious 3 bedroom, 3 full bath home offers, living room, dining room, updated EIK w/granite and stainless steel appliances. Master bedroom with ensuite, 2 bedrooms and full bath. Anderson sliders and windows, family room style lower lever with full bath, finished basement, Central AC replaced in 2020. Beautiful yard with Trex deck and paver patio for entertaining. IGS. 1.5 car garage. Robbins Lane Elementary School and Thompson Middle School!`,
    floorPlan:[
     
   ],
   lng:-73.50660910232686,
   lat:40.794332838864385,
   tax:1619,
   insurance:308,
   hoa:0,
   year:'1955',
   zipCode:11791,
   mls:'3371066'
  },


  {
    address:'45 Kathleen Dr, Syosset',
    phone:'3478371813',
    bedroom:'4',
    bathroom:'4',
    area:'2000',
    price:'849000',
    garage:'2',
    images:[
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644253779/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3374041/f00f59ea62e3ee46e7bb7e0656c0a4fc-cc_ft_1536_rg5hey.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644253778/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3374041/6134aeec418a15a8577df6862bbbe8a5-cc_ft_768_lfdisz.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644253778/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3374041/3ad2eaf1b04990474d54f0c24c374bb5-cc_ft_768_gwtru0.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644253778/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3374041/c53f17025a06c04839838a7e3e680af5-cc_ft_768_lgvyk0.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644253778/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3374041/0fbd00ad6ac24fbed66ca07d700dd899-cc_ft_768_tctpvb.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644253778/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3374041/bb4cd7e6ebf39a3ec8aaa842b8f67b49-cc_ft_768_eodg6a.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644253778/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3374041/5ecbd681386c6fbf358e547eb510b736-cc_ft_768_zffyxe.webp',
    ],
    tourUrl:'',
    location:'Syosset',
    description:`Split Level in Syosset Woods, featuring 4 bedrooms 4 baths including a living room, updated kitchen/dining room with sliding glass doors to yard, full finished basement with exit to side yard, Den on ground level with bedroom and bath with exit to yard, prviate yard fully fenced with semi-in-ground pool (as gift)  private driveway.  Close to Schools, the Long Island xpressway, syosset LIRR station and Shopping.`,
    floorPlan:[
     
   ],
   lng:-73.49740450253502,
   lat:40.80234712870428, 
   tax:1564,
   insurance:297,
   hoa:0,
   year:'1955',
   zipCode:11791,
   mls:'3374041'
  },


  {
    address:'421 Split Rock Rd, Syosset',
    phone:'3478371813',
    bedroom:'5',
    bathroom:'6',
    area:'2000',
    price:'1675000',
    garage:'2',
    images:[
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254202/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3366487/0154be174291fc9771bc7a395a4ca470-cc_ft_1536_mhmpff.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254202/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3366487/25002555177c4df69451ea5f6ecbade4-cc_ft_768_a4befz.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254202/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3366487/87f2df6efab0aee970ccda8dab356e58-cc_ft_768_s4vsdq.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254201/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3366487/ee5b0a5eed7a4738f47e7917db9dc245-cc_ft_768_bsvrkh.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254201/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3366487/37b1c0e4b0a8fd03b727ec3e139e5fca-cc_ft_768_yor5xm.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254201/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3366487/1aab579715cd17e4735ffc1e9c50d7ba-cc_ft_768_ab04ny.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254201/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3366487/3c32390fc359726477051faa4b5ffd4c-cc_ft_768_tbygjx.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254201/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3366487/18d6d4c7e06a196adf3a02b6a6c26fb6-cc_ft_768_gqaafp.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254201/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3366487/58f182f058e4a37e0f45e25391bcfc23-cc_ft_768_us8yl6.webp',
       'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254201/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3366487/51f6ca2760c905835e0c4e1fe5fa4918-cc_ft_768_gz7atd.webp'
    ],
    tourUrl:'',
    location:'Syosset',
    description:` Don't miss this special property centrally located near schools, transportation, restaurants, and everything else the north shore has to offer.  Syosset Schools`,
    floorPlan:[
     
   ],
   lng:-73.51554028711952,
   lat:40.83948298383624, 

   tax:3085,
   insurance:586,
   hoa:0,
   year:'1937',
   zipCode:11791,
   mls:'3366487'
  },


  {
    address:'249 Cold Spring Rd, Syosset',
    phone:'3478371813',
    bedroom:'5',
    bathroom:'4',
    area:'3700',
    price:'1699000',
    garage:'4',
    images:[
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254936/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3370651/fa503b2dc24c0cc25e5eff84fbd94e33-cc_ft_768_ax850x.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254936/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3370651/b7c4b4d89b7a2e447993e194fb25dc21-cc_ft_768_tilyj8.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254936/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3370651/93b309460a0d1e81f4021d8c25d28d48-cc_ft_1536_wwao7l.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254936/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3370651/a52ae096ed3bdafda278ad05fcd9da65-cc_ft_768_vmmjux.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254936/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3370651/dceafe8812f21da56f62a21fdacc78fa-cc_ft_768_ideofc.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254936/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3370651/074b5690a4f8e0952bd23eb705831c65-cc_ft_768_nnaca0.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254936/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3370651/08aa3772bcd03b9911dbcb7cda2a4a69-cc_ft_768_xxtu35.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254936/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3370651/62c2a3cd265e6d3fa9762c8b5a847b6d-cc_ft_768_xu3cvu.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254936/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3370651/76c538dd6f2663403e11e86ac1d93380-cc_ft_768_sjcogw.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254936/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3370651/5eadd69fe7408a3b6ba4f3baf11e0bc0-cc_ft_768_ckkmnv.webp',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1644254936/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/APT/3370651/04a257a70b726af74f79a9c9e98644ea-cc_ft_768_mzhpxw.webp',
        
    ],
    tourUrl:'',
    location:'Syosset',
    description:`Set back and nestled on a private 1.7 acres of manicured grounds this stunning North Syosset home has much to offer.  Renovated in 2015 including insulation, windows, siding, roofing, plumbing, and electric. Updated bright and light kitchen with radiant heat flooring.  Master bedroom overlooks spectacular grounds that are well equipped for entertaining. Finished basement adds to the entertaining space. Award winning Syosset School Disctrict.`,

    floorPlan:[
     
   ],
   lng:-73.48902513129727,
   lat: 40.83532270919477,
   
   tax:3129,
   insurance:595,
   hoa:0,
   year:'1937',
   zipCode:11791,
   mls:'3370651'
  },


]


const getSchoolData = ()=>{
    return DATA;
}

export default getSchoolData;