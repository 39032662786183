import React from 'react'
import {Link} from 'react-router-dom'
import {Button,Grid,Typography} from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles({
    container:{
        paddingLeft:'15%',
        paddingRight:'15%'
    },
    cover:{
        objectFit:'cover',
        width:'70%'
    }
})

export default function Page404() {
const classes = useStyles()
    return (
        <div>
            <Grid className={classes.container} container>

                <Grid item xs={12} sm={12}>

                    <img className={classes.cover} src="https://res.cloudinary.com/dlapk94rx/image/upload/v1630939720/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/404_ph8qrp.png" alt="" />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Typography variant='h2'>
                        Oops! 
                    </Typography>
                    <Typography variant='h2'>
                        Page Not Found
                    </Typography>
                    <Typography variant='subtitle1' color='secondary'>
                        Something Went Wrong!
                    </Typography>
                   <Link to='/'>
                   <Button size='large' variant='contained' color='primary'>
                       Back To Home
                   </Button>

                   </Link> 
                </Grid>

            </Grid>
            
        </div>
    )
}
