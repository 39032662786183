import React,{useState,useEffect} from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "react-slick";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import LocationOn from "@material-ui/icons/LocationOn";
import Phone from '@material-ui/icons/Phone'
import {Typography,Button,Modal,Paper} from '@material-ui/core'
import NumberFormat from 'react-number-format';
import {Link } from 'react-router-dom';

const useStyles = makeStyles({

    img:{
        height:'300px',
        width:'300px',
        objectFit:'cover',
        borderRadius:'50%'

    },
    flex:{
        height:'300px',
        width:'300px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    carWrap:{
        alignItems: 'center',
        justifyContent: 'center',
        display:'flex'
    },
    title:{
        marginTop:'30px'
    },
    container:{
        backgroundColor:'#FFFFFF',
        padding:'10px',
        margin:'20px',
        borderRadius:'10px',
        boxShadow:'0px 0px 6px 0px #000000',
        "&:hover":{
            backgroundColor:'#c9ced6',
            // transform:'scale(1.02,1.1)'
           


        }
    },
    container1:{
        backgroundColor:'#FFFFFF',
        padding:'10px',
        margin:'20px,50px,20px,50px',
        marginLeft:'10%',
        marginRight:'10%',
        borderRadius:'10px',
        boxShadow:'0px 0px 6px 0px #000000',
        "&:hover":{
            backgroundColor:'#c9ced6',
            // transform:'scale(1.02,1.1)'
            // transform:'translate(-10%,0px)',
            // transition:'1.2s'

        }
    },
    modal:{
        position:'absolute',
        width:'100%',
    },
    left:{

       
    },
   

})


export default function Left({item,index}) {

    const classes= useStyles();
    const [phone,setIsPhone] = useState(false)

    const [open,setOpen] = useState(false)

    const handleOpen = (i)=>{
        setOpen(true)
    }

    const handleClose = ()=>{
        setOpen(false)
    }

    useEffect(()=>{
        const width = window.innerWidth
        if(width>500){
            setIsPhone(false)
        }
        else{
            setIsPhone(true)
        }
      },[])

    const settings = {
        dots: false,
        infinite: true,
        speed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
      };
    return (
        <div className={classes.left}>

            <GridContainer className={phone ? classes.container: classes.container1}>
                <GridItem className={classes.carWrap}  xs={phone ? 12 :6} sm={phone ? 12 :6} md={6}>


                    <Carousel className={classes.flex} {...settings}>
                        {
                            item.images.map((v,i)=>(
                                <img className={classes.img} key={i} src={v} alt="" />
                            ))
                        }
                    </Carousel>
                </GridItem>
                <GridItem xs={phone ? 12 :6} sm={phone ? 12 :6} md={6}>

                  
                            <Typography variant='h5'>
                                {item.title}
                            </Typography>
                            <Typography className={classes.content}>
                                Address
                                :{ item.address}
                            </Typography>
                            <Typography>
                                Phone
                                : <a href={`tel:${item.phone}`}>
                                    {item.phone}
                                    </a> 
                            </Typography>
                            <Typography variant={phone ? 'subtitle1' : 'h5'}>
                            Lot: {item.area ?<NumberFormat
                                    value={item.area}
                                    displayType='text'
                                    thousandSeparator={true} 
                                    />: '--'} sq-ft
                        </Typography>


                            <GridContainer>
                                <GridItem className={classes.title} xs={phone ? 6 :6} sm={phone ? 6 :6}>
                                <Typography variant={phone ? 'subtitle1' : 'h5'}>
                                Price:
                                $<NumberFormat
                                        value={item.price}
                                        displayType='text'
                                        thousandSeparator={true} 
                                        />+
                            </Typography>
                                </GridItem>

                                <GridItem className={classes.title} xs={phone ? 6 :6} sm={phone ? 6 :6}>
                                <Typography variant={phone ? 'subtitle1' : 'h5'}>
                                Garage: {item.garage}
                            </Typography>
                            </GridItem>

                                <GridItem className={classes.title} xs={phone ? 6 :6} sm={phone ? 6 :6}>
                                <Typography variant={phone ? 'subtitle1' : 'h5'}>
                                Bedroom : {item.bedroom}
                            </Typography>
                            </GridItem>

                            <GridItem className={classes.title} xs={phone ? 6 :6} sm={phone ? 6 :6}>
                            <Typography variant={phone ? 'subtitle1' : 'h5'}>
                                Bathroom : {item.bathroom}
                            </Typography>
                            </GridItem>

                            <GridItem className={classes.title} xs={phone ? 6 :6} sm={phone ? 6 :6}>
                            <Link to={`/realty/${index+1}`}>
                            <Button variant='contained' color='primary'>View More</Button>
                            </Link>
                            </GridItem>

                            <GridItem className={classes.title} xs={phone ? 6 :6} sm={phone ? 6 :6}>
                           
                            <Button onClick={()=>handleOpen()} variant='contained' color='secondary'>                            3D Tour
</Button>
                        
                            </GridItem>





                          

                           
                           

                          
                         
                            </GridContainer>


                </GridItem >
            </GridContainer>

            <Modal
             open={open}
             onClose={handleClose}
             className={classes.modal}
            >
                    <iframe width="100%" height="500px" src={item.tourUrl} frameborder="0"></iframe>
                    
            </Modal>
        </div>
    )
}
