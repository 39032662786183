import { container, title } from "assets/jss/material-kit-react.js";

const landingPageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container,
    width: "100%",
    paddingTop: "100px",
    // paddingBottom: "180px",
 
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "0px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  search:{
    display:'flex',
    // marginBottom:'30px'
  },
  keyword:{
    backgroundColor:'white',
    width:'70%',
    // height:'46px',
  },
  icon:{
    backgroundColor:'#C82021',
    // height:'46px',
    borderRadius:'0px',
    "&:hover": {
      backgroundColor: '#FD798C',
    },
   
  },
  span:{
    color:'#C82021'
  },
  topP:{
    marginTop:'20px',
    width:'80%',
    paddingLeft:'10%',
    marginBottom:'25px'
  },
  midWrap:{
    width:'98%',
    paddingLeft:'1%',
    backgroundColor:'#F4F5F9',
    paddingTop:'50px',
    height:'auto',
    position:'relative'
  },
  half:{
    width:'100%',
  },
  halfImg:{
    width:'100%',
    border:'2px solid black',

  },
  gridWrap:{
    width:'100%',
    position:'relative'
  },
  btnGroup:{
    position:'absolute',
    left:'5%',
    width:'90%',
    display:'flex',
    justifyContent: 'space-between',
    zIndex:'100',
    top:'85%'
  },
  iframe:{
    width:'100%',
  },
  video:{
    width:'100%',

  },
  doll:{
    width:'100%',

  },
  car:{
    objectFit:'cover',
  },
  phoneI:{
    width:'100%',
    marginBottom:'10px'
  },
  phoneWrap:{
    position:'relative',
    width:'100%',
  },
  halfImg1:{
    width:'100%',
    position:'relative'
  },
  iframe1:{
    width:'100%',
    zIndex:'0'
  },
  btnGroup1:{
    position:'relative',
    left:'5%',
    width:'90%',
    display:'flex',
    justifyContent: 'space-between',
    zIndex:'100',
    bottom:'0'
  },
  btn:{
    zIndex:'1000',

  },
  pStyle:{
    border:'2px solid black',
    borderRadius:'10px',
    paddingLeft:'2%',
    paddingRight:'2%',
    paddingTop:'2%',
    paddingBottom:'2%',
    backgroundColor:'white'
  },
  '@keyframes rotating' :{
    from:{
     
      transform: 'rotate(0deg)',
    },
    to:{
     
      transform: 'rotate(360deg)',
    }
  },
  bg:{
    width:'100%',
    position:'absolute',
    animation: '$rotating 20s linear infinite',
    zIndex:'2000'
  },
  logo:{
    width:'100%',
    position:'absolute',
   
  },
  aniWrap:{
    position:'relative',
  },
  
};

export default landingPageStyle;
