import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import {Typography} from '@material-ui/core'
import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";



const useStyles = makeStyles(styles);

const style = makeStyles({
  container:{
    overflowX:'scroll',
    display:'flex',
    // justifyContent: 'center',
    alignItems: 'space-between',
    width:'100%',
  },
  item:{
    height:'auto',
    width:'300px',
    display:'flex',
    alignItems: 'center',
    flexDirection:'column',
    justifyContent:'space-between',
  },
  img:{
    width:'50%',
    borderRadius:'50%'
  },
  wrap:{
    width:'100%'
  }
});

export default function TeamSection({language}) {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const classnames= style();
  const {teams} = language.home;
  return (
    <div className={classes.section}>
      <Typography gutterBottom variant='h3' style={{color:'black',fontWeight:'bold'}} >{language.home.teamTitle}</Typography>
      {/* <div>
        <GridContainer>
          { 
          teams&&teams.map((v,i)=>(
              <GridItem key={i} xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img style={{backgroundColor:'#7ef2ee'}} src={v.avatar} alt="..." className={imageClasses} />
              </GridItem>
              <Typography variant='h5' className={classes.cardTitle}>
                {v.name}
                <br />
                <small className={classes.smallTitle}>{v.title}</small>
              </Typography>
              <CardBody>
                <Typography variant='subtitle1' className={classes.description}>
                  {v.intro}
                </Typography>
              </CardBody>
                
            </Card>
          </GridItem>
          ))
          }
          

          </GridContainer>
      </div> */}


      <div className={classnames.container}>
        {
          teams&&teams.map((v,i)=>(
            <div key={i} className={classnames.wrap}>

            <div  className={classnames.item}>
<img style={{backgroundColor:'#7ef2ee'}} src={v.avatar} alt="..." className={classnames.img} />
<Typography variant='h5' className={classes.cardTitle}>
                {v.name}
                <br />
                <small className={classes.smallTitle}>{v.title}</small>
              </Typography>
              <CardBody>
                <Typography variant='subtitle1' className={classes.description}>
                  {v.intro}
                </Typography>
              </CardBody>
      </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}
