import React ,{useState} from 'react'
import { createBrowserHistory } from "history";
import {HashRouter as Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import Home from 'views/Home/index';
import RealtyPage from 'views/RealtyPage/index';
import AptPage from 'views/AptPage/index';
import CommercialPage from 'views/CommercialPage/index';
import CommercialRentPage from 'views/CommercialRentPage/index';
import PropertyManagement from './views/PropertyManagement/index';
import Decoration from 'views/Decoration/index'
import getLanguage from 'utils/language'
import AboutUs from 'views/AboutUs/index';
import Page404 from 'views/Page404/index';
import RegisterPage from 'views/RegisterPage/index';
import HousePage from 'views/HousePage/index';
import SelfSellPage from 'views/Sell/Self';
import CommercialSellPage from 'views/Sell/Commercial'
import SamplePage from 'views/SamplePage/index'
import PanoramaPage from 'views/PanoramaPage/index';
import MakeAppointmentPage from 'views/MakeAppointmentPage';
import StudyPage from 'views/Study/index'
import GroupPage from 'views/Group/index';
import ScrollToTop from './components/ScrollToTop/index'

var hist = createBrowserHistory();


export default function App() {
    const LANGUAGE = getLanguage();
   
    const [language,setLanguage] = useState(LANGUAGE[0])

    const handleChangeLanguage = (i)=>{
        setLanguage(LANGUAGE[i]);
    }

    // console.log(language);
    return (
        <Router history={hist}>
          <ScrollToTop>
        <Switch>
          <Route path="/landing" exact={true} render={()=><LandingPage language={language}  handleChangeLanguage={handleChangeLanguage}/>} />
          <Route path="/profile" exact={true} component={ProfilePage} />
          <Route path="/login" exact={true} render={()=><LoginPage language={language}  handleChangeLanguage={handleChangeLanguage}/>} />
          <Route path="/component" exact={true} component={Components} />
      
          <Route path='/apt' exact={true} render={()=><AptPage language={language}  handleChangeLanguage={handleChangeLanguage} />} />
          <Route path='/realty' exact={true} render={()=><RealtyPage language={language} handleChangeLanguage={handleChangeLanguage}/>} />
          <Route path='/commercial' exact={true} render={()=><CommercialPage language={language}  handleChangeLanguage={handleChangeLanguage} />} />
          <Route path='/rent' exact={true} render={()=><CommercialRentPage language={language}  handleChangeLanguage={handleChangeLanguage}/>} />
          <Route path='/management' exact={true} render={()=><PropertyManagement language={language}  handleChangeLanguage={handleChangeLanguage}  />}/>
          <Route path='/decoration' exact={true} render={()=><Decoration language={language} handleChangeLanguage={handleChangeLanguage}/>} />
          <Route path='/about' exact={true} render={()=><AboutUs language={language} handleChangeLanguage={handleChangeLanguage}/>}/>
          <Route path='/register' exact={true} render={()=><RegisterPage language={language} handleChangeLanguage={handleChangeLanguage}/>}/>
          <Route path='/realty/:id' render={(history)=><HousePage history={history}  language={language} handleChangeLanguage={handleChangeLanguage}/>} />
          <Route path='/sell/self'  render={()=><SelfSellPage language={language} handleChangeLanguage={handleChangeLanguage}/> } /> 

          <Route path='/sell/commercial' render={()=><CommercialSellPage language={language} handleChangeLanguage={handleChangeLanguage}/> } /> 
          <Route path='/sample/:id' render={(history)=><SamplePage  history={history} language={language} handleChangeLanguage={handleChangeLanguage} />} />
          <Route  path='/panorama/:id' render={()=><PanoramaPage history={history} />}/>
          <Route  path='/appoint/:id' render={()=><MakeAppointmentPage history={history} />}/>
          <Route  path='/study' render={()=><StudyPage language={language}  handleChangeLanguage={handleChangeLanguage} history={history} />}/>
          <Route path='/group' render={()=><GroupPage language={language}  handleChangeLanguage={handleChangeLanguage} history={history}/>} />
          

          <Route path='/' exact={true} render={()=><Home language={language}  handleChangeLanguage={handleChangeLanguage}   />} />
            <Route  component={Page404}/>

        </Switch>
        </ScrollToTop>
      </Router>
    )
}
