import React,{useState,useEffect} from 'react'
import { makeStyles } from "@material-ui/core/styles";
// import './index.css'
import {Grid} from '@material-ui/core'
import {Link} from 'react-router-dom'

const useStyles = makeStyles({
    '@keyframes rotating' :{
        from:{
         
          transform: 'rotate(0deg)',
        },
        to:{
         
          transform: 'rotate(360deg)',
        }
      },
      category:{
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: 'rgb(218, 214, 214)'
    },
    itemContainer: {
        width: '100%',
        marginBottom: '20px',
    },
    item:{
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    itemWrap:{
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: 'rgb(218, 214, 214)',
        marginTop: '30px',
    },
    itemImg:{
        width: '75%',
        boxShadow: '5px 5px 15px 5px #000000',
        borderRadius: '50%',
        animation: '$rotating 50s linear infinite',
    },
    itemLink:{
        position: 'absolute',
        background: 'white',
        width: '80%',
        textAlign: 'center',
        borderRadius: '30px',
        top: '60%',
        color: 'black',
        textDecoration: 'none',
        fontWeight: 'bold',
        fontSize: 'larger',
    }
})


export default function Category() {

    const [grid,setGrid] = useState(true)
    const classes = useStyles();
    useEffect(()=>{
        const width = window.innerWidth
      
        if(width>500){
            setGrid(true)
          
        }
        else{
            setGrid(false)
        }
      },[])
      const data =[
          {
              name:'投资房 回报率13%',
              link:'/realty',
              img:'https://res.cloudinary.com/dlapk94rx/image/upload/v1637611472/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/a3_mnajcn.png'
          },
          {
            name:'商业 回报率15%',
            link:'/commercial',
            img:'https://res.cloudinary.com/dlapk94rx/image/upload/v1637611472/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/a7_dlsgx5.png'
        },
        {
            name:'拼房',
            link:'/group',
            img:'https://res.cloudinary.com/dlapk94rx/image/upload/v1637611472/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/a4_juzfgu.png'
        },
        {
            name:'学区房',
            link:'/study',
            img:'https://res.cloudinary.com/dlapk94rx/image/upload/v1637611472/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/a6_j8ct8b.png'
        },
        {
            name:'公寓',
            link:'/apt',
            img:'https://res.cloudinary.com/dlapk94rx/image/upload/v1637611472/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/a5_zrmaol.png'
        },
        {
            name:'商业租',
            link:'/rent',
            img:'https://res.cloudinary.com/dlapk94rx/image/upload/v1637611472/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/a1_h193g1.png'
        }
      ]

    return (

        <div className={classes.category}>
            
            <Grid container className={classes.itemContainer}>
                {
                    grid ? data.map((v,i)=>(
                        <Grid className={classes.item} key={i} item xs={4}>
                            <div className={classes.itemWrap}>
                            <img className={classes.itemImg} src={v.img} alt="" />
                           <Link className={classes.itemLink} to={v.link}>{v.name}</Link>
                            </div>
                          
                        </Grid>
                    ))
                    : data.map((v,i)=>(
                        <Grid className={classes.item} key={i} item xs={6}>
                        <div className={classes.itemWrap}>
                        <img className={classes.itemImg} src={v.img} alt="" />
                       <Link className={classes.itemLink} to={v.link}>{v.name}</Link>
                        </div>
                      
                    </Grid>
                        ))
                }

            </Grid> 

        </div>
    )
}
