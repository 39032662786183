import React,{useState,useEffect} from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "react-slick";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavBar from '../../components/NavBar/index'
import {Typography,Button,Modal,Breadcrumbs} from '@material-ui/core'

import NumberFormat from 'react-number-format';
import getRealty from '../../utils/constant'
import {Link,useHistory} from 'react-router-dom'
import Loan from '../../components/Loan/index'



const useStyles = makeStyles({
    bar:{
        margin:'15px'
    },
    img:{
        width:'100%',
        height:'500px',
        objectFit:'cover',

    },
    img1:{
        width:'300px',
        height:'200px'
    },
    flex:{
        height:'auto',
        width:'100%',
        alignItems: 'center',
        justifyContent: 'center',
        border: '3px solid black',
    },
    carWrap:{
        alignItems: 'center',
        justifyContent: 'center',
        display:'flex'
    },
    title:{
        marginTop:'10px'
    },
    container:{
        backgroundColor:'#FFFFFF',
        padding:'10px',
        margin:'20px',
        borderRadius:'10px',
        boxShadow:'0px 0px 6px 0px #000000'
        
    },
    modal:{
        position:'absolute',
        width:'100%',
    },
    floor:{
        marginLeft:'20px'
    },
    floorPlan:{
        backgroundColor:'#ede3ff',
        marginTop:'30px'
    },
    floorImg:{
        width:'100%',
        height:'auto'
    }
})

export default function HousePage({handleChangeLanguage,language}) {
    const classes = useStyles()
    const history = useHistory()

    const [phone,setIsPhone] = useState(false)

    const [open,setOpen] = useState(false)
    const [floor,setFloor] = useState(1)
const DATA = getRealty()
const index= history.location.pathname.split('/')[2]-1;
    const item = DATA[index];
    const handleFloor = (i)=>{
        setFloor(i)
    }

    const handleOpen = (i)=>{
        setOpen(true)
    }

    const handleClose = ()=>{
        setOpen(false)
    }

    useEffect(()=>{
        const width = window.innerWidth
        if(width>500){
            setIsPhone(false)
        }
        else{
            setIsPhone(true)
        }
      },[])

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
      
      };
      console.log(item.title)
    return (
        <div>
             <title>{item.title}</title>
            <NavBar handleChangeLanguage={handleChangeLanguage} language={language}/>
            <div className={classes.bar}>
            <Breadcrumbs>

                <Link to='/'>
                    <Typography variant='h6'>
                        返回首页
                    </Typography>  
                </Link>

                <Link to='/realty'>

                    <Typography variant='h6'>
                        返回房屋列表
                    </Typography>

                </Link>
                {item.name&&<Typography>
                    {item.name}
                </Typography>}

            
            </Breadcrumbs>
            </div>
           
           
{item&&<GridContainer className={classes.container}>
                <GridItem className={classes.carWrap}  xs={ 12 } sm={ 12 } md={12}>

                    <Carousel className={classes.flex} {...settings}>
                        {
                            item.images.map((v,i)=>(
                                i!==0&&<img className={phone ?classes.img1:classes.img} key={i} src={v} alt="" />
                            ))
                        }
                    </Carousel>
                </GridItem>
                <GridItem xs={12} sm={ 12 } md={12}>
          
                            <Typography variant='h4'>
                                {item.name}
                            </Typography>
                            <Typography className={classes.content}>
                                Address
                                :{ item.address}
                            </Typography>
                            <Typography>
                                Phone
                                : <a href={`tel:${item.phone}`}>
                                    {item.phone}
                                    </a> 
                            </Typography>
                            <Typography>
                                房屋编号:NO. 
                                    {item.No}                 
                            </Typography>

                            <GridContainer>
                                <GridItem className={classes.title} xs={phone ? 6 :6} sm={phone ? 6 :6}>
                                <Typography variant={phone ? 'subtitle1' : 'h5'}>
                                Price:
                                $<NumberFormat
                                        value={item.price}
                                        displayType='text'
                                        thousandSeparator={true} 
                                        />+
                            </Typography>
                                </GridItem>

                                <GridItem className={classes.title} xs={phone ? 6 :6} sm={phone ? 6 :6}>
                                <Typography variant={phone ? 'subtitle1' : 'h5'}>
                                Garage: {item.garage}
                            </Typography>
                            </GridItem>

                                <GridItem className={classes.title} xs={phone ? 6 :6} sm={phone ? 6 :6}>
                                <Typography variant={phone ? 'subtitle1' : 'h5'}>
                                Bedroom : {item.bedroom}
                            </Typography>
                            </GridItem>

                            <GridItem className={classes.title} xs={phone ? 6 :6} sm={phone ? 6 :6}>
                            <Typography variant={phone ? 'subtitle1' : 'h5'}>
                                Bathroom : {item.bathroom}
                            </Typography>
                            </GridItem>

                            <GridItem className={classes.title} xs={12} sm={12}>
                           
                            <Button onClick={()=>handleOpen()} variant='contained' color='secondary'>View Virtual Tour</Button>
                        
                            </GridItem>

                            <GridItem className={classes.floorPlan} xs={12} sm={12}>
                           
                                {
                                    item.floorPlan&&item.floorPlan.map((v,i)=>(
                                        <Button key={i} onClick={()=>handleFloor(i)} className={classes.floor} variant='contained' color='primary'>{i+1} Floor</Button>
                                    ))
                                }
                                <img className={classes.floorImg} src={item.floorPlan[floor]} alt="" />
                       
                           </GridItem>

                            <GridItem className={classes.title} xs={12} sm={12}>
                                <Typography variant='h6'>
                                        Location:
                                </Typography>

                            </GridItem>

                            <GridItem className={classes.title} xs={12} sm={12}>
                                <Typography variant='body1'>
                                        {item.location}
                                </Typography>

                            </GridItem>

                            <GridItem className={classes.title} xs={12} sm={12}>
                                <Typography variant='h6'>
                                        Description:
                                </Typography>

                            </GridItem>

                            <GridItem className={classes.title} xs={12} sm={12}>
                                <Typography variant='body1'>
                                        {item.description}
                                </Typography>

                            </GridItem>

                            <GridItem className={classes.title} xs={12} sm={12}>
                                <Typography variant='h6'>
                                        CapRate:
                                </Typography>

                            </GridItem>

                            <GridItem className={classes.title} xs={12} sm={12}>
                                <Typography variant='body1'>
                                        {item.capRate}
                                </Typography>

                            </GridItem>



                          

                           
                           

                          

                            </GridContainer>


                </GridItem >

                <GridItem xs={12} sm={12}>

                <Loan item={item}/>  
                </GridItem>

            </GridContainer>

            }
            <Modal
             open={open}
             onClose={handleClose}
             className={classes.modal}
            >
                    <iframe width="100%" height="500px" src={item.tourUrl} frameborder="0"></iframe>
                    
            </Modal>     

             </div>
    )
}

