const house = {
    images:[
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911418/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_EXT_01_ar96vz.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634918224/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/2-meritage-tampa/MRT_TMP_Lantana_Grove_4L15_B_ezijve.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634920418/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/3-meritage-tampa/MRT_TMP_Lantana_Grove_4L60_A_bspmd4.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634920960/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/4-meritage-tampa/MRT_TMP_Lantana_Grove_4L60_B_o1qgj2.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285891/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-025-28-Back_Yard-1500x998-72dpi_zdq3dy.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285890/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-026-27-Exterior_Pool-1500x1000-72dpi_lk9nqk.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285890/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/3920_North_Haven_J_klcojk.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285890/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/3920_North_Haven_K_q6qygd.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/3408_Kallan_J_bg1dqp.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/3408_Kallan_K_xnez28.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287410/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/3408_Kallan_L_gc91fi.jpg',

    ],
}

const getHouse= ()=>{
    return house
}

export default getHouse;