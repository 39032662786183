import React from 'react'
import {Link,useHistory} from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";

import getRealty from '../../utils/sample'




const useStyles = makeStyles({
    logo:{
        width:'80px'
    },
    wrap:{
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection:'column',
    }
})


const house = getRealty()

export default function PanoramaPage() {
    const classes = useStyles()

    const history = useHistory()
    const index= history.location.pathname.split('/')[2]-1;
    const item = house[index];
    const page = index+1
    console.log(index,item)
    return (
        <div className={classes.wrap}>
            <Link to={`/sample/${page}`}>
            <img className={classes.logo} src="https://res.cloudinary.com/dlapk94rx/image/upload/v1639863600/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/logo%E8%8B%B1%E6%96%87_qmvjwj.png" alt="" />
            </Link>

            <iframe width='100%' height="452px" src={item.tourUrl} frameborder="0"></iframe>
         
            
        </div>
    )
}
