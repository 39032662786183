
const data = [
    {
        address:'172 Van Duzer St',
        phone:'3478371813',
        area:'4326',
        price:'1299999',
        garage:'8',
        images:[ 
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644255996/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/merchant/172%20Van%20Duzer%20st/172-Van-Duzer-St-Staten-Island-NY-Photo-Nov-24-2-18-27-PM-1-HighDefinition_nnztbd.jpg',
        ],
        tourUrl:'',
        location:'Staten Island',
        description:`Once in a lifetime opportunity to own a 14 unit student dormitory single room occupancy. This brick building sits on a 53x115 with a R3-2/C2-1 overlay and is located within close proximity to St John's University & Wagner College. The building has central air/forced hot air, a wide driveway with parking for 6 cars in the yard. The building is currently vacant and leaves endless possibilities for the new owner. It is currently vacant but seller has inspections completed with the city to rent each room for $1426 per room`,
    
        floorPlan:[
         
       ],
       lng:-74.07657698712576,
       lat: 40.6340207185441,
       tax:23295/12,
       year:'1920',
       zipCode:11791,
       mls:'3370651',
       cap:'13.88%',
       opt:true,
       type:'Office',
      },
      {
        address:'Broadway - Manhattan Downtown Hotel',
        phone:'3478371813',
        area:'35496',
        price:'50000000',
        garage:'',
        images:[ 
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644259147/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/merchant/manhattan%20broadway/Broadway-Manhattan-NY-Building-Photo-1-HighDefinition_hme3pc.jpg',
        ],
        tourUrl:'',
        location:'Manhattan',
        description:``,
      
       lng:-74.0017753623403,
       lat:40.71919320432077, 
       tax:400000/12,
       year:'1915',
       zipCode:10022,
       mls:'3370651',
       cap:'12%',
       opt:false,
       type:'Hospitality',
      },
      {
        address:'573 78th St',
        phone:'3478371813',
        area:'2880',
        price:'1590000',
        garage:'',
        images:[ 
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644259831/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/merchant/573%2078th/573-78th-St-Brooklyn-NY-573-78-1-HighDefinition_b6aj5l.jpg',
        ],
        tourUrl:'',
        location:'Brooklyn',
        description:``,
      
       lng:-74.02156875844524,
       lat:40.626481279816566, 
       tax:115000/12,
       year:'1899',
       zipCode:11209,
       mls:'3370651',
       cap:'8.5%',
       opt:false,
       type:'Apartment',
      },
      {
        address:'40-19+21 23rd Street',
        phone:'3478371813',
        area:'4250',
        price:'5388000',
        garage:'',
        images:[ 
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644260215/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/merchant/4019%2023rd%20st/4019-23rd-St-Long-Island-City-NY-Calling-All-Investors-Developers-End-Users-4250-Sqft-M1-3-Industrial-Building-For-Sale-1-HighDefinition_z8neoe.jpg'
        ],
        tourUrl:'',
        location:'Long Island City',
        description:`The Building Features Great Exposure, Excellent Signage, 16' Ceilings, 3 Phase Power, Private Offices, Waiting Room`,
      
       lng:-73.9398800226719,
       lat: 40.75614328072402, 

       tax:17392/12,
       year:'1940',
       zipCode:11101,
       mls:'3370651',
       cap:'8.52%',
       opt:true,
       type:'Industrial',
      },

      {
        address:'1715 Lexington Ave',
        phone:'3478371813',
        area:'4320',
        price:'1650000',
        garage:'',
        images:[ 
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644263330/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/merchant/lexington/1715-Lexington-Ave-New-York-NY-Primary-Photo-1-HighDefinition_iwqcw8.jpg'
        ],
        tourUrl:'',
        location:'New York',
        description:``,
      
       lng:-73.94549184489175,
       lat:40.79318648455021, 
       tax:10050,
       year:'1910',
       zipCode:10029,
       mls:'3370651',
       cap:'8.28%',
       opt:false,
       type:'Retail',
      },

      {
        address:'1726-1734 McDonald Ave',
        phone:'3478371813',
        area:'34800',
        price:'16000000',
        garage:'40',
        images:[ 
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644263648/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/merchant/1726-1734%20McDonald%20Ave/1726-1734-McDonald-Ave-Brooklyn-NY-Primary-Photo-1-HighDefinition_ims25u.jpg'
        ],
        tourUrl:'',
        location:'Brooklyn',
        description:`36,000 SF Commercial building for sale. 10,000 SF Occupied with Lease. Rest of building is vacant/can be delivered vacant. Projected $1,536,000 annual income. Around 8,000 SF of parking available (approx. 40 cars).
        Owner occupied about 18,000SF (will vacate upon sale), Dollar General also occupying space here
        Located in Between Avenue O & Avenue P with Easy Access to the F Subway Line`,
      
       lng:-73.97373903140488,
       lat: 40.61078179913213,
 
       tax:801000/12,
       year:'1971',
       zipCode:11230,
       mls:'3370651',
       cap:'9.38%',
       opt:false,
       type:'Retail',
      },

      {
        address:'111-22+24 Rockaway Beach Blvd',
        phone:'3478371813',
        area:'',
        price:'2500000',
        garage:'40',
        images:[ 
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644264013/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/merchant/rockaway%20beach/11124-Rockaway-Beach-Blvd-Rockaway-Park-NY-Rendering-1-HighDefinition_txepe5.jpg'
        ],
        tourUrl:'',
        location:'Brooklyn',
        description:``,
      
       lng:-73.83385634489954,
       lat:40.58110690217293, 
       tax:17400/12,
       year:'1920',
       zipCode:11230,
       mls:'3370651',
       cap:'20%',
       opt:false,
       type:'Retail',
      },

      {
        address:'9226 Merrick Blvd',
        phone:'3478371813',
        area:'11300',
        price:'4150000',
        garage:'',
        images:[ 
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644264378/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/merchant/9226%20merrick%20blvd/9226-Merrick-Blvd-Jamaica-NY-Calling-All-Investors-Developers-End-Users-11300-Sqft-Retail-Warehouse-Building-For-Sale-1-HighDefinition_iau1ut.jpg'
        ],
        tourUrl:'',
        location:'Jamaica',
        description:`Calling All Investors, Developers & End-Users!!! 11,300 Sqft. Retail/ Warehouse Building For Sale!!! The Property Features Excellent Signage, Great Exposure, High Ceilings, Heavy Power, +++!!! The Building Is Located In The Heart Of Jamaica (The Intersection Of Jamaica Avenue & Merrick Blvd.)!!! Neighbors Include Walgreens, Wendy's, Marshalls, Taco Bell, Dollar General, Jamaica Hospital, Burlington, Planet Fitness, Jamaica Cinemas, York College, +++!!! All Tenants Are Month To Month Making This A Perfect Owner User/ Development Opportunity!!! The Property Can Be Delivered Vacant If Need Be.
        Income:
        1st Floor: $252,000 M-M
        2nd Floor: $180,000 (Proforma)
        Gross Income: $432,000 (Proforma)
        Total Expenses: $61,744
        Net Operating Income (NOI): $370,256 (Proforma 8.92 Cap)`,
      
       lng:-73.7936214314014,
       lat: 40.70519917785202,
       tax:173250/12,
       year:'1931',
       zipCode:11433,
       mls:'3370651',
       cap:'8.92%',
       opt:true,
       type:'Retail',
      },

      {
        address:'213 Bond St',
        phone:'3478371813',
        area:'4500',
        price:'2000000',
        garage:'',
        images:[ 
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644264881/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/merchant/213%20Bond%20st/213-Bond-St-Brooklyn-NY-Building-Photo-1-HighDefinition_fmxd6e.jpg'
        ],
        tourUrl:'',
        location:'Brooklyn',
        description:`four-story commercial building located between Baltic & Butler Street in the Boerum Hill neighborhood of Brooklyn, NY. The subject property is built full 20 ft x 75 ft on the first two floors, 20 ft x 55 ft on the third floor and 20 ft x 30 ft on the 4th floor. The building is located within the Gowanus rezoning but currently zoned M1-2 (Block: 405 Lot: 7).
        The property is configured with a ground floor commercial space and two residential apartments above. The 3rd and 4th floors are a duplex apartment. The building is currently leased on a NNN basis to 213 Bond Street Inc. The current tenant has a lease until September 31st 2024 and the monthly rent is $6,190.
        The property falls within the Gowanus Neighborhood Plan rezoning. The proposed rezoning would change the current zoning from M1-2 to M1-3/R6A, allowing for significant redevelopment opportunities. The building is also located in a Qualified Opportunity Zone.
        The subject property is located three blocks from the Bergen Street subway station, serviced by the F & G lines. 213 Bond Street offers owner-users and investors the opportunity to purchase a strategically located commercial building in the rapidly developing Boerum Hill neighborhood of Brooklyn.`,
      
       lng:-73.98735210256679,
       lat:40.6829537990315, 
       tax:104000/12,
       year:'2008',
       zipCode:11217,
       cap:'',
       opt:true,
       type:'Office',
      },

      {
        address:'191 Canal St',
        phone:'3478371813',
        area:'42253',
        price:'11599000',
        garage:'',
        images:[ 
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644265243/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/merchant/191%20cannal%20st/191-Canal-St-Staten-Island-NY-191_CANAL_ST_SI_2020_03_01_page-0001-1-HighDefinition_xjiyzl.jpg'
        ],
        tourUrl:'',
        location:'Staten Island',
        description:` `,
      
       lng:-74.07917258907521,
       lat:40.6261265376906, 
       tax:1300000/12,
       year:'',
       zipCode:10304,
       cap:'',
       opt:false,
       type:'Land',
      },

      {
        address:'3881 Richmond Ave',
        phone:'3478371813',
        area:'3483',
        price:'5000000',
        garage:'16',
        images:[ 
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644265745/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/merchant/bank%20of%20am/3881-Richmond-Ave-Staten-Island-NY-2logo-1-HighDefinition_mamu9p.jpg'
        ],
        tourUrl:'',
        location:'Staten Island',
        description:`Matthews Real Estate Investment Services is pleased to exclusively represent the sale of the Bank of America in Staten Island, NY. The property benefits from a long-term 13 year Absolute NNN Lease, 2014 construction, and a hard corner location in a premium MSA. Within 5 miles of the property, there are 281,773 people with an average household income of $121,501; a highly sought-after market for a bank location. The property is also located in an affluent retail corridor with tenants such as Stop & Shop, Rite Aid, Advance Auto Parts, Wendy’s, TD Bank, Wendy’s, and Burger King. Immediately behind the property, Eltingville Plaza is currently being constructed, which is a brand new mixed used development containing 56,250 SF that's already 100% leased prior to its completion. `,
      
       lng:-74.16406700257183,
       lat: 40.544012637766514, 
      
       tax:'',
       year:'2014',
       zipCode:10312,
       cap:'4.88%',
       opt:false,
       type:'Retail',
      },

      {
        address:'37-57+51 80th',
        phone:'3478371813',
        area:'41500',
        price:'13900000',
        garage:'',
        images:[ 
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1644272195/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/merchant/37-57%2080th/Jackson-Heights-Building-Photo-1-HighDefinition_gcpu00.jpg'
        ],
        tourUrl:'',
        location:'Jackson Heights',
        description:` Just Relisted- Exclusive Right To Sell
        0PPORTUNITY ZONE- PORTFOLIO 54 UNITS (package of two each 27 units) Seller may also consider selling one building at a time.
        Rent stabilized large size apartments very well maintained by long term owner.
        Excellent location, steps from #7 subway line, no violations. Undeveloped square footage of 10,050 for use residential and community facility.`,
      
       lng:-73.88563437545002,
       lat:40.74807928383977,  
       tax:'',
       year:'1922',
       zipCode:11372,
       cap:'',
       opt:true,
       type:'Apartment',
      },

]

const getMerchantData = ()=>{
    return data
}

export default getMerchantData