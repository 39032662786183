import React from 'react'
import { Button,TextField} from '@material-ui/core'
import {  makeStyles } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {Typography} from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  
    search: {
        zIndex:'12',
    },
    input:{
        fontSize:'12px',
        color:'black',
        backgroundColor:'white',
        width:'100%',
    },
    btn:{
        height:'100%',
    }
  }));

export default function SearchBar({keyword,setKeyword,handleSearch,disabled,name}) {
    const classes = useStyles();

    const handleKeyword = (e)=>{
        setKeyword(e.target.value);
    }

    return (
        <div className={classes.search}>
        <GridContainer justify="center">
            <GridItem  xs={12} sm={12} md={12}>
                <Typography variant='h4' color='primary' >{name}</Typography>
            </GridItem>
            <GridItem xs={8} sm={8} md={8} >
        <TextField className={classes.input} variant='outlined' defaultValue={keyword} label='Search...zipCode or City' size='medium'
        disabled={disabled}
        onChange={(e)=>handleKeyword(e)}
        color='secondary'
        />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
            <Button className={classes.btn} color="primary" variant='contained' onClick={()=>handleSearch()}> Search</Button>
            </GridItem>
        </GridContainer>
      </div>
    )
}
