/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {Link} from 'react-router-dom'
// @material-ui/icons

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer({language}) {
  const classes = useStyles();
  const footerClasses = classNames({
    [classes.footer]: true,
  });
 
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://xingtianstudio.com/"
                className={classes.block}
                target="_blank"
              >
                XingTian Studio
              </a>
            </ListItem>

            <ListItem className={classes.inlineBlock}>
              <Link
                to={language.home.footer[0].link}
              
                style={{textDecoration:'none' ,color:'black'}}
              >
                {language.home.footer[0].name}
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link
                to={language.home.footer[1].link}
                style={{textDecoration:'none' ,color:'black'}}
              >
                {language.home.footer[1].name}
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link
                to={language.home.footer[2].link}
                style={{textDecoration:'none' ,color:'black'}}
              >
                {language.home.footer[2].name}
              </Link>
            </ListItem>
            {/* <ListItem className={classes.inlineBlock}>
              <a
                href="http://blog.creative-tim.com/?ref=mkr-footer"
                className={classes.block}
                target="_blank"
              >
                Blog
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://www.creative-tim.com/license?ref=mkr-footer"
                className={classes.block}
                target="_blank"
              >
                Licenses
              </a>
            </ListItem> */}
          </List>
        </div>
        <div className={classes.right}>
          <a href="https://beian.miit.gov.cn/" target="_blank" >闽ICP备2022003840号-1</a>
          CopyRight&copy; {1900 + new Date().getYear()} 
         
          
            全名地产
        
        </div>
      </div>
    </footer>
  );
}


