
const language = [
    {
        navbar:[{name:'经纪团队',link:'/realty'},{name:'卖房',link:'/sell'},{name:'线上贷款',link:'/loan'},{name:'线上过户',link:'/login'}],
        h1:'全民地产',
        home:{
            description:'全景展现，身临其境。随时随地带你同屏看房，提问，解答',
            title:'科技颠覆地产业',
            title2:'开启您的探索之旅',
            description2:'不论是买房，卖房，出租，商业地产出租买卖，土地买卖等等，我们都将为您提供便利的服务',
            category:{
                business:' 商  业 ',
                buy:' 买  房 ',
                rent:' 租  房 ',
                new:'新楼盘',
                law:'法拍屋',
                land:' 土  地 '
            },
            teamTitle:'我们的团队',
            teams:[
               
                {name:'Tony',title:'房产经纪',intro:'资深地产经纪，尤其擅长商业地产领域，包括商业地产买卖出租管理等',avatar:'https://res.cloudinary.com/dlapk94rx/image/upload/v1630532432/xlck01t_urwsya.jpg'},
                {name:'Lily',title:'房产经纪',intro:'Lily拥有多年房产经验，有累计上百套房产的成交经验',avatar:'https://res.cloudinary.com/dlapk94rx/image/upload/v1630532299/WechatIMG2759_yztfld.jpg'},
                {name:'David',title:'市场营销',intro:'David有多年市场营销经验，尤其擅长互联网网络推广',avatar:'https://res.cloudinary.com/dlapk94rx/image/upload/v1602192537/webwxgetmsgimg_10_rwrwt4.png'},
                {name:'Star',title:'美术设计',intro:'Star擅长UI设计，精通各类UI软件',avatar:'https://res.cloudinary.com/dlapk94rx/image/upload/v1602194930/webwxgetmsgimg_8_liksgg.png'},
              
            ],
            footer:[
                {name:'物业管理',link:'/management'},
                {name:'装修',link:'/decoration'},
                {name:'关于我们',link:'/about'},

            ]
            

    },


    },
    {
        navbar:[{name:'Buy',link:'/realty'},{name:'Rent',link:'/rent'},{name:'Sell',link:'/sell'},{name:'Login',link:'/login'}],
        h1:'PRY Realty',
        home:{
            description:'360 degree panoramic online real estate platform, no matter what country or city you are in, we provide high quality online property viewing services, professional real estate agents to take you online to see and talk about the properties. Once you are satisfied with the online viewings, we will arrange offline viewings for you, saving your valuable time.',
            title:'Our Story',
            title2:'Start your journey of discovery',
            description2:'Whether you are looking to buy a home, sell a home, rent a home, buy or sell a commercial property, buy or sell land, etc., we will make it easy for you.',
            category:{
                business:'Commercial',
                buy:'Buy',
                rent:'Rent',
                new:'New',
                law:'Law',
                land:'Land'
            },
            teamTitle:'Here is our team',
            teams:[
                
                {name:'Tony',title:'Real Estate Agent',intro:'Experienced real estate agent, especially in the field of commercial real estate, including commercial real estate sales and leasing management, etc.',avatar:'https://res.cloudinary.com/dlapk94rx/image/upload/v1630532432/xlck01t_urwsya.jpg'},
                {name:'Lily',title:'Real Estate Agent',intro:'Lily has many years of real estate experience and has accumulated hundreds of property closings',avatar:'https://res.cloudinary.com/dlapk94rx/image/upload/v1630532299/WechatIMG2759_yztfld.jpg'},
                {name:'David',title:'Marketing',intro:'David has many years of marketing experience, especially good at Internet network promotion',avatar:'https://res.cloudinary.com/dlapk94rx/image/upload/v1602192537/webwxgetmsgimg_10_rwrwt4.png'},
                {name:'Star',title:'UI Designer',intro:'Star is good at UI design, proficient in all kinds of UI software',avatar:'https://res.cloudinary.com/dlapk94rx/image/upload/v1602194930/webwxgetmsgimg_8_liksgg.png'},
               
            ],
            footer:[
                {name:'PropertyManagement',link:'/management'},
                {name:'Decoration',link:'/decoration'},
                {name:'About Us',link:'/about'},

            ]

    }
    },
    {

    }
]
const getLanguage = () => {

    return language;
}


export default getLanguage;

