import React,{useEffect,useState,useRef} from 'react'
import NavBar from '../../components/NavBar/index'
import Left from '../../components/Left/index'
import Right from '../../components/Right/index'
import getRealty from '../../utils/constant'
import {Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'


const useStyles = makeStyles({
    btn:{
        position: 'fixed',
        top:'88%',
        left:'88%',
        zIndex:'1000'
    },
    icon:{
        width:'40px'
    }
})

export default function RealtyPage({handleChangeLanguage,language}) {
    const classes = useStyles()
    const DATA = getRealty();
    const ICON = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1634743841/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/icons/top_c4ouz0.png';

    const handleTop = ()=>{
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
          });
    }
    const fixed = false;

    return (
        <div >
             <NavBar fixed={fixed} handleChangeLanguage={handleChangeLanguage} language={language}/>
                <div >
                {
                    DATA&&DATA.map((v,i)=>(
  ( i%2===0 ? <Right key={i} index={i} item={v}/> : <Left key={i} index={i} item={v}/>)
                    ))
                    }
                </div>
              

                <Button onClick={()=>handleTop()} className={classes.btn}>
                    <img className={classes.icon} src={ICON} alt="" />
                </Button>

        </div>
    )
}


