import React,{useState,useEffect} from 'react'
import {Link,useHistory} from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import {Typography,TextField,Button,Select,MenuItem,Paper,FormControl,InputLabel,CircularProgress} from '@material-ui/core';
import axios from 'axios'
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import getRealty from '../../utils/sample'
import CheckIcon from '@material-ui/icons/Check'
import DateFnsUtils from '@date-io/date-fns'; // choose your lib

import { DateTimePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import getApiUrl from '../../utils/api'


const useStyles = makeStyles({
    logo:{
        width:'100px'
    },
    wrap:{
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection:'column',
    },
    formContainer:{
        width: '90%',
        backgroundColor:'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius:'20px'
    },
    formWrap:{
        padding:'30px 10px'
    },
    formItem:{
        width:'100%',
        marginTop:'30px',
        borderRadius:'5px',

    },
    icon:{
        borderRadius:'50%',
        backgroundColor:'#4CAF50',
        width:'30px',
        height:'30px',
        marginBottom:'40px'

    },
    btn:{
        marginBottom:'40px'
    },
    warning:{
        border: '1px solid red',
        width:'100%',
        marginTop:'30px',
        borderRadius:'5px',
    },
    success:{
        padding:'15px'
    }
})


const house = getRealty()


export default function MakeAppointmentPage() {

    const classes = useStyles()
    const url = getApiUrl()

    const history = useHistory()
    const index= history.location.pathname.split('/')[2]-1;
    const item = house[index];
    const page = index+1
    const {address} = item;
    const [name,setName] = useState('')
    const [phone,setPhone] = useState('')
    const [selectedDate, handleDateChange] = useState(new Date());
    const [other,setOther] = useState('')
    const [load,setLoad] = useState(false)
    const [check,setCheck] = useState(false)
    const [checkName,setCheckName] = useState(false)
 
    const [checkPhone,setCheckPhone] = useState(false)

   
    const handleChangeName = (e)=>{
        setName(e.target.value)
        setCheckName(false)
    }
   
    const handleChangePhone = (e)=>{
        setPhone(e.target.value)
        setCheckPhone(false)
    }
    const handleChangeOther = (e)=>{
        setOther(e.target.value)
    }


    const handleSubmit = async()=>{

        if(!name){
            // alert('Missing Name')
            setCheckName(true)
        }
        if(!phone){
            // alert('Missing Phone')
            setCheckPhone(true)

        }
        if(name&&phone){
            setLoad(true)
            const timeToString = selectedDate.toString()
    
            const data = await axios.post(`${url}/appointment/single`,{
                name,phone,time:timeToString,message:other,address:address
            })
            if(data.data.success){
                setLoad(false)
                setCheck(true)
            }
        }
           
        
      }



    return (
        <div className={classes.wrap}>
            <Link to={`/sample/${page}`}>
            <img className={classes.logo} src="https://res.cloudinary.com/dlapk94rx/image/upload/v1639863600/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/logo%E8%8B%B1%E6%96%87_qmvjwj.png" alt="" />
            </Link>

            <Typography className={classes.h3} variant="h3"> 预约看房</Typography>
            <Paper className={classes.formContainer}>

<GridContainer  className={classes.formWrap}>


<GridItem xs={ 12}>
<TextField className={checkName?classes.warning: classes.formItem  } variant='outlined' label='Your Name' value={name}  onChange={handleChangeName} />
</GridItem>

<GridItem xs={ 12}>
<TextField className={checkPhone ? classes.warning: classes.formItem} variant='outlined' label='Phone Number' value={phone} onChange={handleChangePhone} />
</GridItem>



<GridItem  xs={12}>

<MuiPickersUtilsProvider utils={DateFnsUtils}>

<DateTimePicker
className={classes.formItem}
value={selectedDate}
inputVariant="outlined"
disablePast
onChange={handleDateChange}
label="选择一个空闲的时间我们方便联系您" 
showTodayButton
/>
</MuiPickersUtilsProvider>

</GridItem>


<GridItem xs={12}>
<TextField multiline className={classes.formItem} variant='outlined' label='留言：' value={other} onChange={handleChangeOther} />
</GridItem>

</GridContainer>

{ load ? <CircularProgress color='primary'/>
: check ?            <CheckIcon className={classes.icon}/>
:            <Button className={classes.btn} onClick={handleSubmit} variant='contained' color='primary'>
    Submit
</Button>}

{
    check&& <Typography className={classes.success}>
        您已成功预约{selectedDate.toString()}看房，稍后我们的经纪团队会联系您！感谢您的信任！
    </Typography>
}

</Paper>
            
        </div>
    )
}
