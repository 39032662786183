import React from 'react'
import NavBar from '../../components/NavBar/index'

export default function AboutUs({handleChangeLanguage,language}) {
    return (
        <div>
            <NavBar handleChangeLanguage={handleChangeLanguage} language={language}/>
            <h2>I am about page</h2>
        </div>
    )
}
