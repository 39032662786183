import React,{useState,useEffect} from "react";
// nodejs library that concatenates classes
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios'
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {Typography,TextField,Button,Select,MenuItem,Paper,FormControl,InputLabel,CircularProgress} from '@material-ui/core';
import Footer from "components/Footer/Footer.js";
import CheckIcon from '@material-ui/icons/Check'
import DateFnsUtils from '@date-io/date-fns'; // choose your lib

import { DateTimePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import getApiUrl from '../../utils/api'

const useStyles = makeStyles({
    contactContainer:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
    },
    gridItem:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
    },
    img:{
        width: '100%',
    },
    contactGrid:{
        width:'80%'
    },
    h3:{
        margin:'30px 20px'
    },
    formContainer:{
        width: '90%',
        backgroundColor:'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius:'20px'
    },
    formWrap:{
        padding:'30px 10px'
    },
    formItem:{
        width:'100%',
        marginTop:'30px',
        borderRadius:'5px',

    },
    icon:{
        borderRadius:'50%',
        backgroundColor:'#4CAF50',
        width:'30px',
        height:'30px',
        marginBottom:'40px'

    },
    btn:{
        marginBottom:'40px'
    },
    warning:{
        border: '1px solid red',
        width:'100%',
        marginTop:'30px',
        borderRadius:'5px',
    }

});


export default function Contact1({language}) {

    const url = getApiUrl()
    const classes = useStyles();
    const [grid,setGrid] = useState(true)
    const [name,setName] = useState('')
    const [phone,setPhone] = useState('')
    const [address,setAddress] = useState('')
    const [type,setType] = useState('')
    const [selectedDate, handleDateChange] = useState(new Date());
    const [other,setOther] = useState('')
    const [load,setLoad] = useState(false)
    const [check,setCheck] = useState(false)
    const [checkName,setCheckName] = useState(false)
    const [checkAddress,setCheckAddress] = useState(false)
    const [checkPhone,setCheckPhone] = useState(false)

    useEffect(()=>{
        const width = window.innerWidth
      
        if(width>500){
            setGrid(true)
          
        }
        else{
            setGrid(false)
        }
      },[])

      const handleChangeType = (e)=>{
          setType(e.target.value)
      }
      const handleChangeName = (e)=>{
          setName(e.target.value)
          setCheckName(false)
      }
      const handleChangeAddress = (e)=>{
          setAddress(e.target.value)
          setCheckAddress(false)
      }
      const handleChangePhone = (e)=>{
          setPhone(e.target.value)
          setCheckPhone(false)
      }
      const handleChangeOther = (e)=>{
          setOther(e.target.value)
      }

    //   console.log(type,address,phone,name,selectedDate,other);

      const handleSubmit = async()=>{

        if(!name){
            // alert('Missing Name')
            setCheckName(true)
        }
        if(!address){
            // alert('Missing Address')
            setCheckAddress(true)

        }
        if(!phone){
            // alert('Missing Phone')
            setCheckPhone(true)

        }
        if(name&&phone&&address){
            setLoad(true)
            const timeToString = selectedDate.toString()
    
            const data = await axios.post(`${url}/appointment`,{
                name,phone,address,time:timeToString,message:other,type
            })
            if(data.data.success){
                setLoad(false)
                setCheck(true)
            }
        }
           
        
      }

    return (
        <div className={classes.contactContainer}>
            <GridContainer className={classes.contactGrid}>
            
                <GridItem className={classes.gridItem} xs={grid ?6 :12}>
                    <img className={classes.img} src="https://res.cloudinary.com/dlapk94rx/image/upload/v1637942282/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/pexels-rodnae-productions-8293721_oeiles.jpg" alt="" />
                </GridItem>

                <GridItem className={classes.gridItem} xs={grid ?6 :12}>
                    <Typography variant='h4'>降低50%经纪佣金</Typography>
                    <Typography variant='subtitle1'>
                        当前市场美国每成交一套房平均佣金在6%左右，我们承诺我们最后成交的佣金不会超过3%!
                    </Typography>

                </GridItem>

            </GridContainer>

            <GridContainer className={classes.contactGrid}>
            
                

                <GridItem className={classes.gridItem} xs={grid ?6 :12}>
                    <Typography variant='h4'>成交快</Typography>
                    <Typography variant='subtitle1'>
                        当前市场美国房产平均从挂牌到出售的周期在100天左右，而我们公司平均成交周期为50天以内
                    </Typography>

                </GridItem>

                <GridItem className={classes.gridItem} xs={grid ?6 :12}>
                    <img className={classes.img} src="https://res.cloudinary.com/dlapk94rx/image/upload/v1637942282/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/pexels-rodnae-productions-8292776_tuokpt.jpg" alt="" />
                </GridItem>

            </GridContainer>

            <Typography className={classes.h3} variant="h3"> 现在就联系我们挂牌出售</Typography>



    <Paper className={classes.formContainer}>

            <GridContainer  className={classes.formWrap}>

        
<GridItem xs={grid ? 6: 12}>
    <TextField className={checkName?classes.warning: classes.formItem  } variant='outlined' label='Your Name' value={name}  onChange={handleChangeName} />
</GridItem>

<GridItem xs={grid ? 6: 12}>
        <TextField className={checkPhone ? classes.warning: classes.formItem} variant='outlined' label='Phone Number' value={phone} onChange={handleChangePhone} />
</GridItem>

<GridItem xs={12}>
<TextField className={checkAddress? classes.warning: classes.formItem} variant='outlined' label='Your Realty Address' value={address} onChange={handleChangeAddress} />
</GridItem>



<GridItem xs={12}>
<FormControl className={classes.formItem} fullWidth>
<InputLabel id="demo-simple-select-label">Home Type</InputLabel>

<Select
autoWidth
value={type}
onChange={handleChangeType}
>

<MenuItem value={'Co-op'}>Co-op</MenuItem>
<MenuItem value={'Condo'}>Condo</MenuItem>
<MenuItem value={'TownHouse'}>TownHouse</MenuItem>
<MenuItem value={'Single-Family House'}>Single-Family House</MenuItem>
<MenuItem value={'Multi-Family House'}>Multi-Family House</MenuItem>
<MenuItem value={'Other'}>Other</MenuItem>

</Select>
</FormControl>
</GridItem>

<GridItem  xs={12}>

<MuiPickersUtilsProvider utils={DateFnsUtils}>

<DateTimePicker
className={classes.formItem}
  value={selectedDate}
  inputVariant="outlined"
  disablePast
  onChange={handleDateChange}
  label="选择一个空闲的时间我们方便联系您" 
  showTodayButton
/>
    </MuiPickersUtilsProvider>

</GridItem>


<GridItem xs={12}>
<TextField multiline className={classes.formItem} variant='outlined' label='留言：' value={other} onChange={handleChangeOther} />
</GridItem>

</GridContainer>

           { load ? <CircularProgress color='primary'/>
            : check ?            <CheckIcon className={classes.icon}/>
:            <Button className={classes.btn} onClick={handleSubmit} variant='contained' color='primary'>
                Submit
            </Button>}

            </Paper>

        </div>
    )
}
