const DATA =[
    {
     title:'2000尺全新豪华大平层',
     name:'Bristol Meadows',
     No:'tm-1',
     address:'Zephyrhills, Florida 33897'  ,
     phone:'3013269795',
     bedroom:'4',
     bathroom:'3.5',
     area:'1988',
     price:'200000',
     garage:'2 car',
     images:[
        //  'https://res.cloudinary.com/dlapk94rx/image/upload/v1634328385/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/%E7%90%83%E5%89%AF%E6%9C%AC_aiuoai.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911418/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_EXT_01_ar96vz.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911419/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_GR_01_rkp8mv.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911419/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_DR_01_i3kjco.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911419/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_FR_01_vdasda.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911419/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_Kit_01_roe8nt.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911418/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_Bed1_01_qxlqgb.jpg',

         
     ],
     tourUrl:'https://app.cloudpano.com/tours/mNvziJE2i',
     location:'位于佛罗里达西海岸的Tampa市',
     description:'佛罗里达东海岸长期收飓风困扰，很多房屋没几年就要大修一次。而西海岸的Tampa就不会有这种问题,而且近年来越来越多的华人来此地投资买房，潜力巨大！',
     capRate:'投资回报率10%以上',
     floorPlan:[
         'https://res.cloudinary.com/dlapk94rx/image/upload/v1634336696/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/1_tyy2af.jpg',
         'https://res.cloudinary.com/dlapk94rx/image/upload/v1634336708/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/2_ret1qq.jpg'
    
    ],
    tax:0.015,
    insurance:1200,
    hoa:350

    
},
{     title:'近TAMPA市区大平层',
    name:'Lantana Grove',
address:'Plant City, Tampa, Florida 33897'  ,
No:'tm-2',

phone:'3013269795',
bedroom:'4',
bathroom:'2',
area:'1880',
price:'200000',
garage:'2 car',
images:[
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1634918224/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/2-meritage-tampa/MRT_TMP_Lantana_Grove_4L15_B_ezijve.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911419/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_DR_01_i3kjco.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911419/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_FR_01_vdasda.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911419/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_Kit_01_roe8nt.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911418/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_Bed1_01_qxlqgb.jpg',
    
],
tourUrl:'https://app.cloudpano.com/tours/W4HEgfCgX',
location:'位于佛罗里达西海岸的Tampa市',
description:'佛罗里达东海岸长期收飓风困扰，很多房屋没几年就要大修一次。而西海岸的Tampa就不会有这种问题,而且近年来越来越多的华人来此地投资买房，潜力巨大！',
capRate:'投资回报率8%以上',
floorPlan:[
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1634920359/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/3-meritage-tampa/3-1_tkbacy.png',
    
],
tax:0.015,
insurance:1200,
hoa:350

},


{     title:'近TAMPA市区二层大豪宅',
    name:'Lantana Grove',
    No:'tm-3',
address:'Plant City, Tampa, Florida 33897'  ,
phone:'3013269795',
bedroom:'4',
bathroom:'2.5',
area:'2340',
price:'300000',
garage:'2 car',
images:[
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1634920418/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/3-meritage-tampa/MRT_TMP_Lantana_Grove_4L60_A_bspmd4.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911419/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_DR_01_i3kjco.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911419/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_FR_01_vdasda.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911419/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_Kit_01_roe8nt.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911418/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_Bed1_01_qxlqgb.jpg',
    
],
tourUrl:'https://app.cloudpano.com/tours/1azH36oRI',
location:'位于佛罗里达西海岸的Tampa市',
description:'佛罗里达东海岸长期收飓风困扰，很多房屋没几年就要大修一次。而西海岸的Tampa就不会有这种问题,而且近年来越来越多的华人来此地投资买房，潜力巨大！',
capRate:'投资回报率12%以上',
floorPlan:[
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1634919938/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/2-meritage-tampa/1_zofelo.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1634919938/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/2-meritage-tampa/2_svtqsa.png'

],
tax:0.015,
insurance:1200,
hoa:350

},
{     title:'近TAMPA市区二层大豪宅',
    name:'Lantana Grove',
    No:'tm-4',
address:'Plant City, Tampa, Florida 33897'  ,
phone:'3013269795',
bedroom:'4',
bathroom:'2.5',
area:'2340',
price:'300000',
garage:'2 car',
images:[
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1634920960/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/4-meritage-tampa/MRT_TMP_Lantana_Grove_4L60_B_o1qgj2.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911419/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_DR_01_i3kjco.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911419/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_FR_01_vdasda.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911419/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_Kit_01_roe8nt.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1634911418/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/1-meritage-tempa/TAM_BM_Foxglove_Bed1_01_qxlqgb.jpg',
    
],
tourUrl:'https://app.cloudpano.com/tours/yyz6dmlHQ',
location:'位于佛罗里达西海岸的Tampa市',
description:'佛罗里达东海岸长期收飓风困扰，很多房屋没几年就要大修一次。而西海岸的Tampa就不会有这种问题,而且近年来越来越多的华人来此地投资买房，潜力巨大！',
capRate:'投资回报率12%以上',
floorPlan:[
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1634919938/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/2-meritage-tampa/1_zofelo.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1634919938/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/2-meritage-tampa/2_svtqsa.png'

],
tax:0.015,
insurance:1200,
hoa:350

},
{     title:'Orlando 4000尺带游泳池豪宅',
    name:'North Haven',
    No:'DR Waterleigh Emerald',
address:'Winter Garden, Orlando, Florida 33897'  ,
phone:'3013269795',
bedroom:'5',
bathroom:'3.5',
area:'3911',
price:'610990',
garage:'3 car',
images:[
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285890/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/3920_North_Haven_K_q6qygd.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285892/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-008-7-Kitchen-1500x1000-72dpi_sbdtfl.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285892/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-022-26-2nd_Floor_Lounge-1500x1000-72dpi_juqp26.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285892/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-020-24-2nd_Floor_Bedroom-1500x1000-72dpi_wkhpjl.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285892/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-019-11-2nd_Floor_Bedroom-1500x1000-72dpi_kbz1bk.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285892/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-017-15-2nd_Floor_Bedroom-1500x998-72dpi_rkbsic.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285891/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-014-6-Master_Bedroom-1500x1000-72dpi_z86u6j.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285892/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-018-23-2nd_Floor_Bathroom-1500x999-72dpi_h4m4jb.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285892/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-016-12-Master_Bathroom-1500x998-72dpi_phtwwq.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285892/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-005-8-Living_Room-1500x1000-72dpi_p3vr4b.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285891/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-013-13-Master_Bedroom-1500x1000-72dpi_uvtr7v.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285891/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-015-21-Master_Bathroom-1500x1000-72dpi_fzztwc.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285891/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-010-3-Kitchen-1500x1000-72dpi_ecgqvg.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285891/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-012-4-Breakfast_Area-1500x1000-72dpi_xjtnak.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285891/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-011-9-Kitchen-1500x1000-72dpi_g2qg00.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285891/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-009-10-Kitchen-1500x1000-72dpi_ecfkxx.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285890/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-006-5-Living_Room-1500x1000-72dpi_rpldb7.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285890/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-007-25-Dining_Room-1500x1000-72dpi_pc2fam.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285890/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-021-16-2nd_Floor_Bedroom-1500x999-72dpi_biwhv8.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285890/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-023-17-Patio-1500x1000-72dpi_p1meka.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285890/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-024-18-Patio-1500x999-72dpi_xklpp8.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285890/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-026-27-Exterior_Pool-1500x1000-72dpi_lk9nqk.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285891/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-027-19-Exterior_Pool-1500x1000-72dpi_ex1jpd.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635285891/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/North_Haven-large-025-28-Back_Yard-1500x998-72dpi_zdq3dy.jpg',
    
],
tourUrl:'https://app.cloudpano.com/tours/cyO2pQh4V',
location:'10分钟车程到迪士尼乐园，20分钟到环球影城，周边各种大小商城，生活便利',
description:'这个两层楼的豪宅包含了高端的设计，优雅的外部环境和高端的功能。宽敞的全混凝土块结构的房子有5间卧室，3.5间浴室，灵活的空间和一个游戏室。通过可选的升级和定制，创造你的梦想家园。一个美丽的门廊欢迎你，当你进入门厅时，你会发现一个灵活的房间，非常适合工作或玩耍，还有一个化妆间。精心设计的厨房可以俯瞰起居区，第二间餐厅和室外凉台。这个美食家的厨房有一个石英台面，不锈钢惠普电器，宽敞的储藏室和皇冠造型橱柜。在厨房外，你会发现洗衣房，有可选的落地区域从车库进入。卧室一位于住宅的后面，以保护隐私。卧室一浴室给人留下了深刻的印象，有独立的盥洗室，带隐私门的厕所和宽敞的步入式衣橱。定制一号浴室以满足你的梦想家园的目标。当我们上到二楼，迎接我们的是一个游戏区和四间卧室。两间卧室共享楼上的第二间浴室和亚麻布衣柜，以增加储存空间。另外两间卧室共用一个套间浴室，每个房间都有独立的盥洗台。',
capRate:'投资回报率10%以上',
floorPlan:[
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635286134/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/1_piwac6.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635286134/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Waterleigh%20Emerald/2_xazvod.png'

],
tax:0.015,
insurance:1200,
hoa:350

},
{     title:'Orlando 3000尺超温馨豪宅',
    name:'Kallan',
    No:'DR Waterleigh Emerald 2',
address:'Winter Garden, Orlando, Florida 33897'  ,
phone:'3013269795',
bedroom:'4',
bathroom:'3.5',
area:'3911',
price:'591990',
garage:'3 car',
images:[
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287410/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/3408_Kallan_L_gc91fi.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287410/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_6_bkfdei.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_7_mrvv3c.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_5_iregtx.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287410/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_8_le4gwi.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_4_ejw5gz.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_13_crpg9q.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_14_oavvh5.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_9_rfupx3.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_12_koadku.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_10_gyhkdy.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_16_j4rhcq.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_11_unwnsf.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_18_ld8sca.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287413/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_21_zk2czh.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_19_ykyyvy.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_17_il2k6b.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_15_yffrgh.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_22_fjkwvb.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_20_l66xwh.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287413/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_23_vksvfx.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287413/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_24_l6h7a2.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287413/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_25_ha7ydg.jpg',
  
],
tourUrl:'https://app.cloudpano.com/tours/xZ5yc2tWw',
location:'10分钟车程到迪士尼乐园，20分钟到环球影城，周边各种大小商城，生活便利',
description:'这个设计精美的房屋包含了优雅的外观和高端的功能。这个全混凝土块结构的两层楼房有4间卧室和3.5个浴室。通过可选择的升级和定制来创造你的梦想家园。一个美丽的门廊欢迎你，当你进入门厅时，你会发现一个适合工作或玩耍的弹性房间，一个化妆室和正式的餐厅。装备精良的厨房可以俯瞰起居区、第二间餐厅和室外凉台。这个美食家的厨房有一个石英台面，不锈钢惠浦电器，宽敞的储藏室和皇冠造型橱柜。在餐厅旁，这个房子有一个额外的一楼浴室。当我们上到二楼时，迎接我们的是一个阁楼区和所有卧室。卧室一就位于阁楼空间的旁边，卧室一浴室的双碗盥洗台、独立的淋浴和浴缸空间以及宽敞的步入式衣橱给人留下深刻印象。两间卧室共享楼上的第二间浴室和亚麻布衣柜，可供额外储存。另外一间卧室位于阁楼区域，包括独立的浴室和步入式衣柜。你的洗衣房位于二楼，很方便。，Kallan包括一个 "家是连接的 "智能家居技术包，让您在附近或离开时都可以用智能设备控制您的家。',

floorPlan:[
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287856/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/1_nbnxdo.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287856/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/2_aexj6n.png'

],
tax:0.015,
insurance:1200,
hoa:350
},
]

const getRealty = ()=>{
    return DATA;
}

export default getRealty;