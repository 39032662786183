import React,{useState,useEffect} from 'react'
import ReactPannellum from "react-pannellum";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {IconButton,Button, Select} from '@material-ui/core'

const useStyles = makeStyles({
    contain:{
        width: '100%',
    },
    wrap:{
        width: '100%',
        
    },
    left:{
        position:'absolute',
        left:'5%',
        top:'45%'
    },
    right:{
        position:'absolute',
        right:'5%',
        top:'45%'
    },
    middle:{
        position:'absolute',
        left:'40%',
        top:'75%'
    },
    dot:{
        width:'20px'
    }

})



const config = {
    autoRotate: -5,
    autoLoad: true,
    showControls:false,
    uiText:{
      loadButtonLabel: "Click to<br>Load<br>Panorama",
    loadingLabel: "Loading...",
    bylineLabel: "360",
    noPanoramaError: "No panorama image was specified.",
    fileAccessError: "The file %s could not be accessed.",
    malformedURLError: "There is something wrong with the panorama URL.",
    iOS8WebGLError: "Due to iOS 8's broken WebGL implementation, only progressive encoded JPEGs work for your device (this panorama uses standard encoding).",
    genericWebGLError: "Your browser does not have the necessary WebGL support to display this panorama.",
    textureSizeError:  "This panorama is too big for your device! It's %spx wide, but your device only supports images up to %spx wide. Try another device. (If you're the author, try scaling down the image.)",
    unknownError: "Unknown error. Check developer console."
    }
  };
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  
const DOT = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1632336708/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/icons/rec_emhuei.png'

const SELECT ='https://res.cloudinary.com/dlapk94rx/image/upload/v1632336778/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/icons/rec_1_zjvrcw.png'

export default function Carousel({width}) {
    const [index,setIndex] = useState(0)
    const classes = useStyles();
      const STYLE ={width: `${width}px`,height: `${width*0.5}px`,border:'3px solid black',borderRadius:'5px'}

      const content = [
  
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1631893827/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/PIC_20201118_103423_20201118165104_rqeevw.jpg',
        'https://res.cloudinary.com/dlapk94rx/image/upload/v1632324697/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/PIC_20201118_101212_20201118165104_nbv2ul.jpg'
    ]
    const handleNext = ()=>{
        if(index === content.length-1){
            setIndex(0)
        }
        else{
            setIndex(index+1)
        }

    }

    const handleBack=()=>{

        if(index ===0){
            setIndex(content.length-1)
        }
        else{
            setIndex(index-1)
        }

    }

    const handleSelect = (i)=>{
        setIndex(i)
    }


    return (
        <div className={classes.contain}>
            <div className={classes.wrap}>
                {
                    content&&content.map((v,i)=>(
                        index===i&&<ReactPannellum
                        id={`${i+1}`}
                        sceneId="firstScene"
                        imageSource={v}
                        config={config}
                        style={STYLE}   
                        key={i}     
                      /> 
                    ))
                }
           

                <div className={classes.left}>
                    <IconButton onClick={()=>handleBack()}>
                        <ArrowBackIosIcon color='primary'/>
                    </IconButton>
                </div>

                <div className={classes.right}>
                    <IconButton onClick={()=>handleNext()}>
                        <ArrowForwardIosIcon color='primary'/>
                    </IconButton>
                </div>

                <div className={classes.middle}>
                    {
                        content&&content.map((v,i)=>(
                            i===index ?<Button onClick={()=>handleSelect(i)} key={i}>
                                <img className={classes.dot} src={SELECT} alt="" />
                            </Button>
                            :<Button onClick={()=>handleSelect(i)} key={i}>
                            <img className={classes.dot} src={DOT} alt="" />
                        </Button>
                        ))
                    }
                </div>

            </div>


            
        </div>
    )
}
