import React,{useState,useEffect,useRef} from 'react'
import NavBar from '../../components/NavBar/index'
import GridContainer from "components/Grid/GridContainer.js";
import axios from 'axios';
import NumberFormat from 'react-number-format';
import {useHistory} from 'react-router-dom'
 import GridItem from "components/Grid/GridItem.js";
import Carousel from "react-slick";
import AptLoan from '../../components/AptLoan/index'
import {Card,
    Button,
    Typography,
    TablePagination,
    Modal,
    Grid,
    Paper,} from '@material-ui/core'
import { GoogleMap, useJsApiLoader,Marker, } from '@react-google-maps/api';

import SearchBar from 'components/SearchBar/index';
import { makeStyles } from "@material-ui/core/styles";
// import getApiUrl from '../../utils/api';
import getMerchantData from '../../utils/merchant';


const useStyles = makeStyles({
    container:{
        backgroundColor:'#d4ffdc',
       paddingTop: '10px',
       paddingBottom: '10px',
       backgroundColor:'white'
    },
    containerStyle:{
        width:'100%',
        height:'80vh',
      },
     
      right:{
          boxShadow:'-1px 0px 8px 0px #000000',
          zIndex:'12'
      },
      paper:{
          height:'75vh',
          overflowY:'scroll',
          backgroundColor:'white',
          position:'relative',

      },
      card:{
          position:'relative',
        width:'96%',
        height:'34vh',
        margin:'2%',
        boxShadow:'0px 0px 10px 5px rgba(0,0,0,0.25)',
        borderBottom:'5px solid #00acc1',
        "&:hover": {
            border:'3px solid #e91e63',
          },
      },
      card1:{
        position:'relative',

        width:'96%',
        height:'34vh',
        margin:'2%',
        boxShadow:'0px 0px 10px 5px rgba(0,0,0,0.25)',
        border:'3px solid #e91e63',
      },
      cardImg:{
          height:'60%',
          width:'100%',
          objectFit:'cover',
          border:'1px solid rgba(0,0,0,0.5)',
      },
      button:{
          position:'absolute',
          top:'5px',
          right:'0px',
          padding:'0px,0px',
          alignItems: 'center',
      },
      like:{
          width:'40px',
          height:'auto'
      },
      btnMore:{
        position:'absolute',
        top:'58%',
        right:'0px',
        padding:'0px,0px'
      },
      more:{
        width:'20px',
        height:'auto'
      },
      delete:{
          width:'20px',
      },
      keyContainer:{
          alignItems: "center",
          display:'flex',
          justifyContent: "center",
      },
      paper2:{
        width:'50%',
        position:'relative',
        left: '0',
      },
      paper3:{
        width:'100%',
        height:'60vh',
        position:'relative',
        top: '40vh',
        overflowY:'scroll',
        borderTopLeftRadius:'15px',
        borderTopRightRadius:'15px',

      },
      modal:{
          height:'100%',
          overflowY:'scroll'
      },
      car:{
        width:'100%',
          margin:'0',
          padding:'0',
      },
      carPhone:{
        width:'100%',
        margin:'0',
        padding:'0',
        objectFit:'cover',
      },
      modal1:{
          position:'relative',
          width:'80%',
          height:'80%',
          left:'10%',
          top:'5%',
          zIndex:'100',
          backgroundColor:'rgba(255, 255, 255,0)',

      },
      card2:{
        backgroundColor:'rgba(255, 255, 255,0)',
        border:'none'
      },
      fit:{
          objectFit:'contain',
          width:'100%',
      },
      fixPaper:{
        position:'absolute',
        top:'80%',
        width:'70%',
        left:'25%',
        borderRadius:'20px',
        zIndex:'999'
      },
      fixPaperLeft:{
        position:'absolute',
        top:'85%',
        width:'56%',
        left:'-40%',
        borderRadius:'20px',
        zIndex:'1'
      },
      cardImgLeft:{
        height:'60%',
        width:'100%',
        objectFit:'cover',
        border:'1px solid rgba(0,0,0,0.5)',
      },
      fixCardLeft:{
        position:'relative',
        width:'100%',
        height:'14vh',
        boxShadow:'1px 1px 15px 5px #3B3B3B',
      
        "&:hover": {
            border:'3px solid #e91e63',
          },
          borderRadius:'10px',
        
      },
      fixPaperRight:{
        position:'absolute',
        top:'85%',
        width:'56%',
        left:'92%',
        borderRadius:'20px',
      },
      cardImgRight:{
        height:'60%',
        width:'100%',
        objectFit:'cover',
        border:'1px solid rgba(0,0,0,0.5)',
      },
      fixCardRight:{
        position:'absolute',

        width:'100%',
        height:'14vh',
        boxShadow:'1px 1px 15px 5px #3B3B3B',
        "&:hover": {
            border:'3px solid #e91e63',
          },
          borderRadius:'10px',
      },
      cardImg1:{
        height:'60%',
        width:'100%',
        objectFit:'cover',
        border:'1px solid rgba(0,0,0,0.5)',
      },
      fixCard:{
        position:'relative',
        width:'100%',
        height:'24vh',
        boxShadow:'1px 1px 15px 5px #3B3B3B',
        "&:hover": {
            border:'3px solid #e91e63',
          },
          borderRadius:'10px',
      },
      title:{
          paddingTop:'80px'
      },
      opt:{
        position:'absolute',
        backgroundColor:'blue',
        color:'white',
        borderRadius:'10px',
        padding:'2px',
        top:'5%',
        right:'2%'
      }

});

const IMG ='https://res.cloudinary.com/dlapk94rx/image/upload/v1630622395/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/No_Images_ryhijd.jpg'

const HOVER='https://res.cloudinary.com/dlapk94rx/image/upload/v1630704951/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/111_dljzs8.png';

const RED = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1630704951/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/222_bcfesz.png';


const MORE = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1644271589/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/more_ylxhsh.png'

const DELETE = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1631801242/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/icons/remove_1_yulofw.png'

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };

  const title='商业专区'


export default function CommercialPage({handleChangeLanguage,language}) {
    const apiKey = process.env.REACT_APP_MAP_API
    let history = useHistory()
    const classes = useStyles();
    const data = getMerchantData()
    const [keyword,setKeyword] = useState('')
    const [isPhone,setIsPhone] = useState(false);
    const [realty,setRealty] = useState(data);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); 
    const [total,setTotal] = useState(data.length);
    const [center,setCenter] = useState({})
    const [hover,setHover] = useState(0)
    const scroll = useRef(null)

    const [search,setSearch] = useState(false)
    const [open,setOpen] = useState(false);
    const [show,setShow] = useState(false);
    const [disabled,setDisabled] = useState(false);
    const [currentRent,setCurrentRent] = useState({});
    const [isCar,setIsCar] = useState(false);
    const [index,setIndex] = useState(0);
    const [startX,setStartX] = useState();
    const [left,setLeft] = useState(data.length-1)
    const [right,setRight]= useState(1)

    const handleOpenCar =()=>{
        setIsCar(true)
    }

    const handleCloseCar =()=>{
        setIsCar(false)
    }

    const handleOpen = (v)=>{
        setCurrentRent(data[v]);

        setOpen(true);
    }
    const handleClose = ()=>{
        setOpen(false);
    }

    const handleStart =(e)=>{
        setStartX(e.changedTouches[0].pageX);
        console.log(`start x is:${e.changedTouches[0].pageX}`);
    }

    const handleEnd = (e)=>{
        const endX = e.changedTouches[0].pageX;
        console.log(`end x is:${e.changedTouches[0].pageX}`)

        if(index ===0){
            if(startX-endX>50){
                console.log("左滑1");
                setIndex(index+1)
                setCenter({lat:realty[index+1].lat,lng:realty[index+1].lng})
                setHover(index+1)
                setLeft(index)
                setRight(index+2)
                console.log(left,index,right);
            }
            if(endX - startX >50){
                console.log("右滑1");

                setHover(realty.length-1)

                setIndex(realty.length-1)
                setLeft(realty.length-2)
                setRight(0)
                console.log(left,index,right);

                setCenter({lat:realty[realty.length-1].lat,lng:realty[realty.length-1].lng})
            }
        }
        else if(index <realty.length-1){


            if(index===1){
                if(startX-endX>50){
                    console.log("左滑2");
    
                    setHover(index+1)
    
                    setIndex(index+1)
                    setLeft(index)
                    setRight(index+2)
                    console.log(left,index,right);
    
                    setCenter({lat:realty[index+1].lat,lng:realty[index+1].lng})
    
                }
                if(endX - startX >50){
                    console.log("右滑2");
    
                    setIndex(index-1)
                    setHover(index-1)
                    setLeft(realty.length-1)
                    setRight(index)
                    console.log(left,index,right);
    
                    setCenter({lat:realty[index-1].lat,lng:realty[index-1].lng})
    
                }
            }
            else if(index ===realty.length-2){
                if(startX-endX>50){
                    console.log("左滑2");
    
                    setHover(index+1)
    
                    setIndex(index+1)
                    setLeft(index)
                    setRight(0)
                    console.log(left,index,right);
    
                    setCenter({lat:realty[index+1].lat,lng:realty[index+1].lng})
    
                }
                if(endX - startX >50){
                    console.log("右滑2");
    
                    setIndex(index-1)
                    setHover(index-1)
                    setLeft(index-2)
                    setRight(index)
                    console.log(left,index,right);
    
                    setCenter({lat:realty[index-1].lat,lng:realty[index-1].lng})
    
                }
            }
            else{
                if(startX-endX>50){
                    console.log("左滑2");
    
                    setHover(index+1)
    
                    setIndex(index+1)
                    setLeft(index)
                    setRight(index+2)
                    console.log(left,index,right);
    
                    setCenter({lat:realty[index+1].lat,lng:realty[index+1].lng})
    
                }
                if(endX - startX >50){
                    console.log("右滑2");
    
                    setIndex(index-1)
                    setHover(index-1)
                    setLeft(index-2)
                    setRight(index)
                    console.log(left,index,right);
    
                    setCenter({lat:realty[index-1].lat,lng:realty[index-1].lng})
    
                }
            }

            
        }
        else if(index === realty.length-1){
            if(startX-endX>50){
                console.log("左滑3");

               setIndex(0)
               setCenter({lat:realty[0].lat,lng:realty[0].lng})
               setHover(0)
               setLeft(realty.length-1)
               setRight(1)
               console.log(left,index,right);

            }
            if(endX - startX >50){
                console.log("右滑3");

                setIndex(index-1)
                setHover(index-1)
                setLeft(index-2)
                setRight(index)
                setCenter({lat:realty[index-1].lat,lng:realty[index-1].lng})
                console.log(left,index,right);

            }
        }
        else {

        }
           
    }

  
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey:apiKey
      })
      useEffect(()=>{
        const width = window.innerWidth
        if(width>500){
            setIsPhone(false)
        }
        else{
            setIsPhone(true)
        }
        const temp = data[0];
        setCurrentRent(temp)
        setCenter({lat:temp.lat,lng:temp.lng})
      },[])

      const handleSearch =async()=>{
          if(keyword){
            setSearch(true)
            setDisabled(true)
            setShow(true)
        }
    }
   
   
    const fixed = false;

    const handleMouseHover = (i)=>{
        setHover(i)
        setIndex(i)
    }

    const handleMouseOut = ()=>{
        setHover(0)
    }

    const handleCardClick = (i)=>{
        setHover(i)
        setIndex(i)
        setCenter({lat:data[i].lat,lng:data[i].lng})
    }

   const handleMapClick = (i)=>{
        setHover(i)
        setIndex(i)
        setCenter({lat:data[i].lat,lng:data[i].lng})

        if(rowsPerPage<total){
            const height = scroll.current.scrollHeight;
            const item = Math.ceil(rowsPerPage/2);
            const unit = height/item;
            const per = Math.ceil(i/2)-1
            scroll.current.scrollTop=per*unit
        }
        else{
            const height = scroll.current.scrollHeight;
            const item = Math.ceil(total/2);
            const unit = height/item;
            const per = Math.ceil(i/2)-1
            scroll.current.scrollTop=per*unit
        }
       
    }
    const handleMapClick1 = (i)=>{
        setHover(i)
        setIndex(i)
        setCenter({lat:data[i].lat,lng:data[i].lng})

    }

    // console.log(keyword);
    //   console.log(total,rowsPerPage);
    const handleChangePage = (event, newPage) => {
        // console.log(1);
        setPage(newPage);
      
      };
    
      const handleChangeRowsPerPage = (event) => {
        //   console.log(2);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

      const handleLike = (i)=>{
    
            history.push('/login')
        
      }
    //   console.log(realty);

    const handleDeleteKeyword =()=>{
        setKeyword('');
        setShow(false);
        setSearch(false)
        setDisabled(false)

    }

    
    return (
        <div>
            <NavBar fixed={fixed} handleChangeLanguage={handleChangeLanguage} language={language}/>
            
          <GridContainer className={classes.container}>
          <GridItem xs={12}  sm={12} md={12}>
                  <SearchBar name={title} handleSearch={handleSearch} keyword={keyword} setKeyword={setKeyword} disabled={disabled} />
                  </GridItem>

                  {show&&<GridItem className={classes.keyContainer} xs={12} sm={12} md={12}>
                      <Typography color='primary'>
                          Your Search Keyword:
                      </Typography>
                      <Button variant='contained' color='primary' disabled={true} >{keyword}</Button>
                      <Button onClick={()=>handleDeleteKeyword()} ><img className={classes.delete} src={DELETE} alt="" /></Button>

                  </GridItem>}
                  </GridContainer>
         
    

      {
          !isPhone ?<div>
              <Grid container>

     
          <Grid item xs={12} sm={6}>
      {isLoaded&&<GoogleMap
                        mapContainerClassName={classes.containerStyle}
                        center={center}
                        zoom={10}
                       
                        >
                        {
                          realty&&realty.map((m,n)=>(
                            n===hover ? <Marker
                            key={n}
                            icon={HOVER}
                            position={{lat:m.lat,lng:m.lng}}
                            label={(n+1).toString()}
                            onClick={()=>handleMapClick(n)}
                            zIndex={10}
                            />
: 
                            <Marker
                            icon={RED}
                            key={n}
                            position={{lat:m.lat,lng:m.lng}}
                            label={(n+1).toString()}
                            onClick={()=>handleMapClick(n)}
                            zIndex={1}

                            /> 
                          ))
                        }
                        </GoogleMap>}
      </Grid>
      
      <Grid className={classes.right} item xs={12} sm={6}>

         <Paper ref={scroll} className={classes.paper}>
            <Grid container>

               
                {
                    realty&&realty.map((v,i)=>(
                        <Grid   key={i} item xs={12} sm={6}>
                            
                            <Card onClick={()=>{handleCardClick(i); handleOpen(i)}} className={hover===i ? classes.card1:classes.card}> 
                                <img className={classes.cardImg} src={v.images[0]? v.images[0]: IMG} alt="" />
                                {v.opt&& <Typography variant='body1'  className={classes.opt}>
                                        Opportunity Zone
                                    </Typography>}
                                
                                <Button onClick={()=>handleOpen(i)}  className={classes.btnMore}>
                                    <img src={MORE} className={classes.more} alt="" />
                                   
                                </Button>
                                <Grid container>
                                    <Grid item xs={5} sm={5}>
                                        <Typography color='secondary' variant='body1' >
                                        <NumberFormat
                                        value={v.price}
                                        displayType='text'
                                        prefix="$"
                                        thousandSeparator={true}
                                        />
                                        </Typography>
                                    </Grid>


                                    <Grid item xs={5} sm={5}>
                                        <Typography color='secondary' variant='body1' >
                                            capRate:{v.cap} 
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={3} sm={3}>
                                        <Typography color='secondary' variant='body1' >
                                            {v.type} 
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={4} sm={4}>
                                        <Typography variant='body1' >
                                        <NumberFormat
                                        value={v.area}
                                        displayType='text'
                                        thousandSeparator={true} 
                                        />    {v.area ? 'ft': '-- ft'}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={5} sm={5}>
                                        <Typography variant='body1' >
                                        {v.location}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Typography variant='body1' >
                                            {v.address}, {v.zipCode}
                                        </Typography>
                                    </Grid>
                                
                                </Grid>
                               
                            </Card>
                        </Grid> 
                    ))
                }

            </Grid>

        </Paper>    

        <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />   
      </Grid>


      </Grid>
      </div>
      : 
                <div>
                    <Grid container className={classes.containerStyle}>
                    <Grid item xs={12} sm={6}>
      {isLoaded&&<GoogleMap
                        mapContainerClassName={classes.containerStyle}
                        center={center}
                        zoom={10}
                       
                        >
                        {
                          realty&&realty.map((m,n)=>(
                            n===hover ? <Marker
                            key={n}
                            icon={HOVER}
                            position={{lat:m.lat,lng:m.lng}}
                            label={(n+1).toString()}
                            onClick={()=>handleMapClick1(n)}
                            zIndex={10}
                            />
: 
                            <Marker
                            icon={RED}
                            key={n}
                            position={{lat:m.lat,lng:m.lng}}
                            label={(n+1).toString()}
                            onClick={()=>handleMapClick1(n)}
                            zIndex={1}

                            /> 
                          ))
                        }
                        </GoogleMap>}
                    </Grid>

                    </Grid>

                    <Paper className={classes.fixPaper} onTouchStart={(e)=>handleStart(e)} onTouchEnd={(e)=>handleEnd(e)}>

                <Card className={classes.fixCard}  > 
                                <img onClick={()=>{handleCardClick(index); handleOpen(index)}} className={classes.cardImg1} src={realty[index].images[0]? realty[index].images[0]: IMG} alt="" />
                                {realty[index].opt&& <Typography variant='body1'  className={classes.opt}>
                                        Opportunity Zone
                                    </Typography>}
                                
                                <Button onClick={()=>handleOpen(index)}  className={classes.btnMore}>
                                    <img src={MORE} className={classes.more} alt="" />
                                </Button>
                                <Grid container>
                                    <Grid item xs={5} sm={5}>
                                        <Typography color='secondary' variant='body1' >
                                        <NumberFormat
                                        value={data[index].price}
                                        displayType='text'
                                        prefix="$"
                                        thousandSeparator={true}
                                        />
                                        </Typography>
                                    </Grid>


                                    <Grid item xs={5} sm={5}>
                                        <Typography color='secondary' variant='body1' >
                                        capRate:{data[index].cap} 
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={3} sm={3}>
                                        <Typography variant='body1' >
                                            {data[index].type} 
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={4} sm={4}>
                                        <Typography variant='body1' >
                                        <NumberFormat
                                        value={data[index].area}
                                        displayType='text'
                                        thousandSeparator={true} 
                                        />    {data[index].area ? 'ft': '-- ft'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5} sm={5}>
                                        <Typography variant='body1' >
                                            {data[index].location} 
                                        </Typography>
                                    </Grid>


                                    <Grid item xs={12} sm={12}>
                                        <Typography variant='body1' >
                                            {data[index].address}, {data[index].zipCode}
                                        </Typography>
                                    </Grid>
                                
                                </Grid>
                               
                            </Card>

                    </Paper>

                    <Paper className={classes.fixPaperLeft} onTouchStart={(e)=>handleStart(e)} onTouchEnd={(e)=>handleEnd(e)}>

<Card className={classes.fixCardLeft}  > 
                <img className={classes.cardImgLeft} src={realty[left].images[0]? realty[left].images[0]: IMG} alt="" />
                {/* <Button onClick={()=>handleLike(i)} className={classes.button}>
                    <img className={classes.like} src={isLike ? LIKE : UNLIKE} alt="" />
                </Button> */} 
                
                <Button onClick={()=>handleOpen(left)}  className={classes.btnMore}>
                    <img src={MORE} className={classes.more} alt="" />
                </Button>
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <Typography variant='h6' >
                        <NumberFormat
                        value={data[left].price}
                        displayType='text'
                        prefix="$"
                        thousandSeparator={true}
                        />
                        </Typography>
                    </Grid>


                    <Grid item xs={4} sm={4}>
                        <Typography variant='body1' >
                            {data[left].bedroom} bed
                        </Typography>
                    </Grid>

                    <Grid item xs={4} sm={4}>
                        <Typography variant='body1' >
                            {data[left].bathroom} bath
                        </Typography>
                    </Grid>

                    <Grid item xs={4} sm={4}>
                        <Typography variant='body1' >
                        <NumberFormat
                        value={data[left].area}
                        displayType='text'
                        thousandSeparator={true} 
                        />    {data[left].area ? 'ft': '-- ft'}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography variant='body1' >
                            {data[left].address}, {data[left].city}, {data[left].zipCode}
                        </Typography>
                    </Grid>
                
                </Grid>
               
            </Card>

                     </Paper>
                    
                

                    
                </div>
      }


            <Modal 
            open={open} 
            onClose={handleClose}
            className={classes.modal}
            >


                <Paper className={isPhone ? classes.paper3 : classes.paper2}>
                   <GridContainer justify="center">
                   <GridItem xs={12} sm={12} md={12}>
                   <Typography variant='h5'>房屋详情</Typography>

                   </GridItem>

                   <GridItem xs={12} sm={12} md={12}>
                   <Typography >Address:{currentRent.address}, {currentRent.zipCode}, {currentRent.city}</Typography>

                   </GridItem>

                        <GridItem xs={6} sm={6} md={6}>
                        <Typography  color="secondary">
                         Price:
                         <NumberFormat
                         value={currentRent.price}
                         displayType='text'
                         prefix="$"
                         thousandSeparator={true}
                         />
                                        </Typography>
                                
                           
                        </GridItem>

                        <GridItem xs={6} sm={6} md={6}>
                        <Typography  color="secondary">
                         Tax:
                         <NumberFormat
                         value={currentRent.tax}
                         displayType='text'
                         prefix="$"
                         thousandSeparator={true}
                         />
                                        </Typography>
                                
                           
                        </GridItem>

                        <GridItem xs={6} sm={6} md={6}>
                        <Typography  color="secondary">
                         Hoa:
                         <NumberFormat
                         value={currentRent.hoa}
                         displayType='text'
                         prefix="$"
                         thousandSeparator={true}
                         />
                                        </Typography>
                                
                           
                        </GridItem>

                        <GridItem xs={6} sm={6} md={6}>
                        <Typography >
                         Area:
                         <NumberFormat
                         value={currentRent.area}
                         displayType='text'
                         prefix=""
                         thousandSeparator={true}
                         /> ft
                                        </Typography>
                                
                           
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                   <Typography >Description:{currentRent.description||'N/A'}</Typography>

                   </GridItem>

                   <GridItem xs={6} sm={6} md={6}>
                   <Typography >Bedroom:{currentRent.bedroom}</Typography>

                   </GridItem>

                   <GridItem xs={6} sm={6} md={6}>
                   <Typography >Bathroom:{currentRent.bathroom}</Typography>

                   </GridItem>

                   <GridItem xs={12} sm={12} md={12}>
                   <Typography >AgentPhone:{currentRent.phone}</Typography>

                   </GridItem>

                   <GridItem xs={12} sm={12} md={12}>
                   <Typography >Parking:{currentRent.garage||'--'}</Typography>

                   </GridItem>

                   <GridItem xs={12} sm={12} md={12}>
                   <Typography color='secondary'>YearBuild:{currentRent.year || '--'}</Typography>

                   </GridItem>

                   <GridItem xs={12} sm={12} md={12}>
                   <Typography >  
                       MLS No:{currentRent.mls||'--'}
                   </Typography>

                   </GridItem>

                    <GridItem xs={12} sm={12} md={12}>

                   
              <Carousel className={classes.sel} {...settings}>
                {currentRent.images&&currentRent.images.map((v,i)=>(
                    <Button className={classes.card2} onClick={handleOpenCar} key={i}>
                        <img className={classes.carPhone} src={v} alt={`${i}-image`} />
                    </Button>
                ))}
              </Carousel>
              <AptLoan item={currentRent} />
                {
                    currentRent.tourUrl&& 
                    <div>
                        <Typography variant="h4">全景看房</Typography>
                        <iframe src={currentRent.tourUrl} width="100%" height="350px" />
                    </div>

                   
                }
           
                    </GridItem>

                   </GridContainer>
                </Paper>



            </Modal>


            <Modal 
            open={isCar} 
            onClose={handleCloseCar}
            >
                <Paper             className={classes.modal1}
>
                <Carousel {...settings}>
                {currentRent.images&&currentRent.images.map((v,i)=>(
                    <div className={classes.fit} key={i}>
                        <img className={classes.car} src={v} alt={`${i}-image`} />
                    </div>
                ))}
              </Carousel>
              </Paper>
            </Modal>

        </div>
    )
}
