import React,{useState,useEffect,useRef} from "react";
// nodejs library that concatenates classes
import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer.js";
import NavBar from '../../components/NavBar/index';
import { Typography,TextField,Button,Grid} from "@material-ui/core";
import Search from "@material-ui/icons/Search";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Contact2 from '../../components/Contact/Contact2'


const useStyles = makeStyles(styles);

export default function CommercialSellPage({handleChangeLanguage,language}) {

    const classes = useStyles();
    const fixed = true;
    const [grid,setGrid] = useState(true)
    const [keyword,setKeyword] = useState('')

  useEffect(()=>{
    const width = window.innerWidth
  
    if(width>500){
        setGrid(true)
      
    }
    else{
        setGrid(false)
    }
  },[])
  const handleChangeKeyWord = (e)=>{
    setKeyword(e.target.value);
}

    return (
        <div>
             <NavBar fixed={fixed} handleChangeLanguage={handleChangeLanguage} language={language}/>
             <Parallax filter image="https://res.cloudinary.com/dlapk94rx/image/upload/v1637941602/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/pexels-david-mcbee-1546168_k3ginq.jpg">
        <div className={classes.container}>
          <GridContainer>
             { grid&&<GridItem xs={ 1} >
          
              </GridItem>}
            <GridItem xs={grid ? 6: 12} >
              <h2 className={classes.title}>{language.home.title}</h2>
              <Typography variant='subtitle1'>
               {language.home.description}
              </Typography>
              <br />
              <div className={classes.search}>
                <TextField value={keyword} onChange={(e)=>handleChangeKeyWord(e)} className={classes.keyword} variant='filled' label='输入邮编或城市'  />
                <Button endIcon={<Search/>} className={classes.icon}></Button>
              </div>
           
            </GridItem>
          </GridContainer>

        </div>
        </Parallax>


        <section className={classes.topP}>
        <Typography>
          卖商业地产
           <span className={classes.span}>免费估价|</span>
           <span className={classes.span}>免费全景拍照|</span>
           <span className={classes.span}>免费视频制作</span>
        </Typography>
      </section>

      <Contact2 language={language}/>
      <Footer language={language} />

        </div>
    )
}
