const DATA =[

{   title:'Orlando 3000尺超温馨豪宅',
    name:'Kallan',
    No:'DR Waterleigh Emerald 2',
    address:'884 Tilden Oaks Trl ' ,
    city:'Winter Garden',
    state:'FL',
    zipCode:'34787',
    MLS:'33458542',
    phone:'3013269795',
    bedroom:'4',
    bathroom:'3.5',
    type:'Single-Family',
    area:'3911',
    price:'591990',
    garage:'3 car',
    images:[
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287410/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/3408_Kallan_L_gc91fi.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287410/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_6_bkfdei.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_7_mrvv3c.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_5_iregtx.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287410/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_8_le4gwi.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_4_ejw5gz.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_13_crpg9q.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_14_oavvh5.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287411/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_9_rfupx3.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_12_koadku.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_10_gyhkdy.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_16_j4rhcq.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_11_unwnsf.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_18_ld8sca.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287413/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_21_zk2czh.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_19_ykyyvy.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_17_il2k6b.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_15_yffrgh.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_22_fjkwvb.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287412/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_20_l66xwh.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287413/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_23_vksvfx.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287413/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_24_l6h7a2.jpg',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287413/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/Kallan_Waterleigh_25_ha7ydg.jpg',
  
],
tourUrl:'https://app.cloudpano.com/tours/xZ5yc2tWw',
location:'10分钟车程到迪士尼乐园，20分钟到环球影城，周边各种大小商城，生活便利',
description:'这个设计精美的房屋包含了优雅的外观和高端的功能。这个全混凝土块结构的两层楼房有4间卧室和3.5个浴室。通过可选择的升级和定制来创造你的梦想家园。一个美丽的门廊欢迎你，当你进入门厅时，你会发现一个适合工作或玩耍的弹性房间，一个化妆室和正式的餐厅。装备精良的厨房可以俯瞰起居区、第二间餐厅和室外凉台。这个美食家的厨房有一个石英台面，不锈钢惠浦电器，宽敞的储藏室和皇冠造型橱柜。在餐厅旁，这个房子有一个额外的一楼浴室。当我们上到二楼时，迎接我们的是一个阁楼区和所有卧室。卧室一就位于阁楼空间的旁边，卧室一浴室的双碗盥洗台、独立的淋浴和浴缸空间以及宽敞的步入式衣橱给人留下深刻印象。两间卧室共享楼上的第二间浴室和亚麻布衣柜，可供额外储存。另外一间卧室位于阁楼区域，包括独立的浴室和步入式衣柜。你的洗衣房位于二楼，很方便。，Kallan包括一个 "家是连接的 "智能家居技术包，让您在附近或离开时都可以用智能设备控制您的家。',

floorPlan:[
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287856/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/1_nbnxdo.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1635287856/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/waterleigh%20emerald%202/2_aexj6n.png'

],
tax:0.015,
insurance:1200,
hoa:350,
map:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.4844299077354!2d-81.61435030501667!3d28.55521260343634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e784885c3988d9%3A0xc81ebc8fdc6bec3e!2s884%20Tilden%20Oaks%20Trl%2C%20Winter%20Garden%2C%20FL%2034787!5e0!3m2!1szh-CN!2sus!4v1639847353791!5m2!1szh-CN!2sus',
agentName:'Lily Zou',
agentPhone:'347 837 1813',
agentEmail:'lily@360realty.online',
weChat:'https://res.cloudinary.com/dlapk94rx/image/upload/v1639854042/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/WechatIMG4352_nmgvmg.jpg',
agentImg:'https://res.cloudinary.com/dlapk94rx/image/upload/v1639854573/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/%E8%8E%89%E8%8E%89_vhiu7b.png',
style:'',
date:'',
land:'',
basement:'',
buildYear:'',
},
]

const getRealty = ()=>{
    return DATA;
}

export default getRealty;