import React,{useState,useEffect,useRef} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Carousel from "react-slick";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Search from "@material-ui/icons/Search";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";
import NavBar from '../../components/NavBar/index';
import { Typography,TextField,Button,Grid} from "@material-ui/core";
import getHouse from '../../utils/house'
import { TrendingUpRounded } from "@material-ui/icons";
import Category from './Sections/Category'
import Team from './Sections/Team'

const useStyles = makeStyles(styles);

export default function LandingPage({handleChangeLanguage,language}) {
  const classes = useStyles();
  const fixed = true;
  const [grid,setGrid] = useState(true)
  const [keyword,setKeyword] = useState('')
  const [height,setHeight] = useState(400)
  const [iframe,setIframe] = useState(true)
  const [video,setVideo] = useState(false)
  const [img,setImg] =useState(false)
  const [doll,setDoll] = useState(false)
  const HEIGHT = '400PX'
  const {images} = getHouse()

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };

  const handleIframe = ()=>{
    setIframe(true);
    setVideo(false)
    setImg(false)
    setDoll(false)
    console.log(1);
  }

  const handleVideo =()=>{
    setIframe(false);
    setVideo(true)
    setImg(false)
    setDoll(false)
    console.log(2);

  }

  const handleImg = ()=>{
    setIframe(false);
    setVideo(false)
    setImg(true)
    setDoll(false)
    console.log(3);

  }

  const handleDoll = ()=>{
    setIframe(false);
    setVideo(false)
    setImg(false)
    setDoll(true)
    console.log(4);

  }


  const handleChangeKeyWord = (e)=>{
      setKeyword(e.target.value);
  }
  useEffect(()=>{
    const width = window.innerWidth
  
    if(width>500){
        setGrid(true)
      
    }
    else{
        setGrid(false)
    }
  },[])

  const handleImageLoad = (e)=>{
    const temp = e.target.clientHeight
    console.log(temp);
    setHeight(temp)
  }
  useEffect(()=>{
   

    const handleResize =()=>{
      const temp1 = document.getElementById('i1')
      if(temp1)setHeight(temp1.clientHeight)
    }
    window.addEventListener('resize', handleResize)
  })
 
 console.log(height);

 const IMG = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1626980352/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/ezgif.com-gif-maker_rvdidh.webp'

 const IMG2 = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1635871088/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/gif/2112.gif_wh860_gmtqlj.gif'


const IMG3 ='https://res.cloudinary.com/dlapk94rx/image/upload/v1644344254/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/ezgif-1-0f870d1cf0_p0skfx.gif'

const IMG4 ='https://res.cloudinary.com/dlapk94rx/image/upload/v1644344724/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/ezgif-1-6b24390582_ak6q7y.gif'

 const BG= 'https://res.cloudinary.com/dlapk94rx/image/upload/v1636057919/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/%E7%8E%AF2_eiiqrn.png'

 const LOGO = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1644516075/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/2x80_bv8a58.png'

  return (
    <div>
      <NavBar fixed={fixed} handleChangeLanguage={handleChangeLanguage} language={language}/>
    
      <Parallax filter image={IMG}>
        <div className={classes.container}>
          <GridContainer>
             {grid&&<GridItem className={classes.aniWrap} xs={grid ? 3: 12} >

                    <img className={classes.bg} src={BG} alt="" />
                    <img className={classes.logo} src={LOGO} alt="" />
              </GridItem>}
            <GridItem  xs={grid ? 9: 12}>
              <h2 className={classes.title}>{language.home.title}</h2>
              <Typography variant='subtitle1'>
               {language.home.description}
              </Typography>
              <br />
              <div className={classes.search}>
                <TextField value={keyword} onChange={(e)=>handleChangeKeyWord(e)} className={classes.keyword} variant='filled' label='输入邮编或城市'  />
                <Button endIcon={<Search/>} className={classes.icon}></Button>
              </div>

            </GridItem>
          </GridContainer>

        </div>
        </Parallax>

      <section className={classes.topP}>
        <Typography className={classes.pStyle}>
          我们公司有20年地产经验.非常注重细节.通过我们的全景拍照视频讲解身临其境省去大批人到家看房,Open House等浪费彼此时间,弄脏房子.这样也节省了一半经纪费用.我们团队很快在各种平台推广,让你的房子在网上熠熠生辉.这就是我们卖房子的原因,而且卖的非常快.
           <span className={classes.span}>免费估价|</span>
           <span className={classes.span}>免费全景拍照|</span>
           <span className={classes.span}>免费视频制作</span>
        </Typography>
      </section>

      {grid?<section className={classes.midWrap}>
            <Grid container className={classes.gridWrap}>
                <Grid  item className={classes.half} xs={3}>
                      <img id='i1'  className={classes.halfImg} src="https://res.cloudinary.com/dlapk94rx/image/upload/v1637017121/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/%E5%B7%A6_vzlb0w.jpg" alt=""  onLoad={(e)=>handleImageLoad(e)}/>
                </Grid>

                <Grid className={classes.gridWrap} item xs={6}>

                  <div className={classes.halfImg}>
                     { iframe&&<iframe className={classes.iframe} height={`${height}px`} src="https://app.cloudpano.com/tours/mNvziJE2i" frameBorder="0"></iframe>}
                     {
                       video&&<iframe src="https://pryrealty.s3.amazonaws.com/windsor_1.mp4"  className={classes.video}  height={`${height}px`} controls>
                          </iframe>
                     }
                     {
                       img&&<Carousel {...settings}>
                            {images&&images.map((v,i)=>(
                              <img height={`${height}px`} className={classes.car} src={v} key={i} />
                            ))}
                       </Carousel>
                     }
                     {/* {
                       doll&&<iframe className={classes.doll} controlsframeBorder="0" height={height} allowfullscreen mozallowfullscreen="true"  src="https://sketchfab.com/models/8b31e163cccd4bafbdf186b6d26d283a/embed"> </iframe>
                     } */}
                      {
                       doll&&<Carousel {...settings}>
                          <iframe className={classes.doll} frameBorder="0"height={`${height}px`} src="https://sketchfab.com/models/8b31e163cccd4bafbdf186b6d26d283a/embed" frameborder="0"></iframe>
                          <iframe className={classes.doll} frameBorder="0"height={`${height}px`} src="https://sketchfab.com/models/2dc41bc0fa09493caf6c927bd82ad816/embed" frameborder="0"></iframe>
                          <iframe className={classes.doll} frameBorder="0"height={`${height}px`} src="https://sketchfab.com/models/5db0b16760b84bb9bc4d1ec210fac894/embed" frameborder="0"></iframe>
                       </Carousel>
                     }
                  </div>

                  <div className={classes.btnGroup}>

                      <Button onClick={()=>handleIframe()} variant="contained" color="primary" >VR</Button>
                      <Button onClick={()=>handleVideo()} variant="contained" color="primary">视频</Button>
                      <Button onClick={handleImg} variant="contained" color="primary">图片</Button>
                      <Button onClick={handleDoll} variant="contained" color="primary">户型</Button>
                  </div>

                </Grid>

                <Grid item xs={3}>
                <img className={classes.halfImg} src="https://res.cloudinary.com/dlapk94rx/image/upload/v1637017120/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/%E5%8F%B3_m3paf4.jpg" alt="" />

                </Grid>

            </Grid>
      </section>
      
        :
        <section >
          <Grid className={classes.phoneWrap} container>
                <Grid item xs={12}>
                    <img className={classes.phoneI}  src="https://res.cloudinary.com/dlapk94rx/image/upload/v1637017121/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/images/%E4%B8%AD_ut1dqr.webp" alt="" />
                </Grid>
                <Grid item xs={12}>
              

<div className={classes.halfImg1}>
   { iframe&&<iframe className={classes.iframe1} height={HEIGHT} src="https://app.cloudpano.com/tours/mNvziJE2i" frameBorder="0"></iframe>}
   {
     video&&<iframe src="https://pryrealty.s3.amazonaws.com/windsor_1.mp4"  className={classes.iframe1}  height={HEIGHT} controls>
        </iframe>
   }
   {
     img&&<Carousel {...settings}>
          {images&&images.map((v,i)=>(
            <img height={HEIGHT} className={classes.iframe1} src={v} key={i} />
          ))}
     </Carousel>
   }
    {
     doll&&<Carousel {...settings}>
        <iframe className={classes.iframe1} frameBorder="0"height={HEIGHT} src="https://sketchfab.com/models/8b31e163cccd4bafbdf186b6d26d283a/embed" frameborder="0"></iframe>
        <iframe className={classes.iframe1} frameBorder="0"height={HEIGHT} src="https://sketchfab.com/models/2dc41bc0fa09493caf6c927bd82ad816/embed" frameborder="0"></iframe>
        <iframe className={classes.iframe1} frameBorder="0"height={HEIGHT} src="https://sketchfab.com/models/5db0b16760b84bb9bc4d1ec210fac894/embed" frameborder="0"></iframe>
     </Carousel>
   }
</div>

<div className={classes.btnGroup1}>

    <Button className={classes.btn} onClick={()=>handleIframe()} variant="contained" color="primary" >VR</Button>
    <Button className={classes.btn} onClick={()=>handleVideo()} variant="contained" color="primary">视频</Button>
    <Button className={classes.btn} onClick={handleImg} variant="contained" color="primary">图片</Button>
    <Button className={classes.btn} onClick={handleDoll} variant="contained" color="primary">户型</Button>
</div>


                </Grid>
            </Grid>
        </section>
    }


      {/* <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
        
        </div>
      </div> */}


      <Category/>

      {/* <Team/> */}
      <Footer language={language} />
    </div>
  );
}

